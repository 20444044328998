import React from "react";
// Customizable Area Start
import { Typography, Box, Tooltip } from "@mui/material";
import * as Yup from "yup";

import { Formik } from "formik";
import { banner, navLogo, errorIcon } from "./assets";
// Customizable Area End

import ForgotPasswordPageController, {
  Props,
  configJSON,
} from "./ForgotPasswordPageController";
// Customizable Area Start
import Snackbar from "@mui/material/Snackbar";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";

// Customizable Area End
export default class ForgotPasswordPage extends ForgotPasswordPageController {
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area Start
  questionSchema = () => {
    return Yup.object().shape({
      email: Yup.string()
        .email("Invalid email format")
        .required("Mail is required"),
    });
  };
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <Box sx={webStyle.forgotPasswordContainer}>
        <Box sx={webStyle.leftBlock}>
          <Box sx={webStyle.logoContainer}>
            <img src={navLogo} alt="Logo" style={{ height: "40px" }} />
            <Typography sx={webStyle.logoText}>
              {configJSON.logoText}
            </Typography>
          </Box>
          <Box sx={webStyle.forgotPasswordFormContainer}>
            <Box>
              <Typography style={webStyle.formTitle}>
                {configJSON.forgotPasswordText}
              </Typography>
              <Typography style={webStyle.formSubTitle}>
                {configJSON.willSeendYouEmailText}
              </Typography>
            </Box>
            <Formik
              initialValues={{
                email: "",
              }}
              validateOnMount={true}
              validateOnChange={true}
              validationSchema={this.questionSchema}
              onSubmit={() => {
                this.setValidate(true);
                this.forgotPasswordEmailNotification();
              }}
              data-test-id="ResendEmailForm"
            >
              {({ values, errors, setFieldValue, handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                  <Box sx={webStyle.formContainer}>
                    <Box sx={webStyle.fieldControl}>
                      <label style={webStyle.inputLabel} htmlFor={"email"}>
                        {configJSON.emailText}
                      </label>
                      <input
                        style={webStyle.inputElement(!!this.state.emailError || !!(this.state.validate && errors.email))}
                        data-test-id="email"
                        type="email"
                        placeholder={configJSON.emailPlaceholderText}
                        onChange={(event) => {
                          setFieldValue("email", event.target.value);
                          this.setFormDate(event.target.value);
                        }}
                      />
                      {this.state.validate && errors.email && (
                        <Tooltip
                          open={Boolean(errors.email)}
                          title={errors.email}
                          componentsProps={tooltipStyles}
                          arrow
                        >
                          <img
                            src={errorIcon}
                            alt=""
                            style={webStyle.errorIcon}
                            data-test-id="warning-tooltip"
                          />
                        </Tooltip>
                      )}
                    </Box>
                    <button
                      style={webStyle.submitButton}
                      data-test-id="submitBtn"
                      type="submit"
                      onClick={() => {
                        this.setValidate(true);
                        this.setFormDate(values?.email);
                        if (Object.keys(errors).length == 0) {
                          handleSubmit();
                        }
                      }}
                    >
                      {configJSON.getLinkText}
                    </button>
                  </Box>
                  <Box sx={webStyle.resendEmailRedirection}>
                    <Typography style={webStyle.resendEmailRedirectionLabel}>
                      {configJSON.donthaveAnEmailText}
                    </Typography>
                    <button
                      style={{
                        ...webStyle.resendEmailRedirectionButton,
                        color: (this.state.validate && !this.state.resendEmailError && !errors.email && this.state.formSubmitted) ? "#1A7BA4" : "#B0BEC5",
                      }}
                      type="button"
                      disabled={!(this.state.validate && !this.state.resendEmailError && !errors.email && this.state.formSubmitted)}
                      onClick={() => this.resendEmailBtn()}
                      data-test-id="resendButton"
                    >
                      {configJSON.resendEmailText}
                    </button>
                  </Box>
                </form>
              )}
            </Formik>
            
          </Box>
        </Box>
        <Box sx={webStyle.rightBlock}>
          <Box sx={webStyle.rightBlockBackground} />
          {/* Bkock right */}
        </Box>
        <Snackbar
          open={!!this.state.warningMessage || !!this.state.successMessage}
          message={
            <Box sx={webStyle.snackbarContent}>
              {this.state.warningMessage ? (
                <CancelRoundedIcon
                  sx={{
                    ...webStyle.snackbarIcon,
                    ...webStyle.snackbarWarningIcon,
                  }}
                />
              ) : (
                <CheckCircleRoundedIcon
                  sx={{
                    ...webStyle.snackbarIcon,
                    ...webStyle.snackbarSuccessIcon,
                  }}
                />
              )}
              <Typography sx={webStyle.snackbarText}>
                {this.state.successMessage || this.state.warningMessage}
              </Typography>
            </Box>
          }
          autoHideDuration={3500}
          onClose={() =>
            this.setState({ warningMessage: "", successMessage: "" })
          }
          sx={webStyle.snackbar}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        />
      </Box>

      //Merge Engine End DefaultContainer
      // Customizable Area Start
    );
  }
}
// Customizable Area Start
const tooltipStyles = {
  tooltip: {
    sx: {
      color: "#FFFFFF",
      fontFamily: "'Inter', sans-serif",
      fontSize: "13px",
      lineHeight: "18px",
      fontWeight: "400",
      padding: "8px 12px",
      backgroundColor: "#1E293B",
      borderRadius: "8px",
      width: "fit-content",
      maxWidth: "480px",
      "& .MuiTooltip-arrow": {
        color: "#1E293B",
      },
    },
  },
};

const webStyle = {
  forgotPasswordContainer: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#F8FAFC",
    minHeight: '100vh',
    alignItems: 'flex-start',
    "@media (max-width: 1036px)": {
      margin: 'auto 0',
      alignItems: 'center',
    },
  },
  rightBlock: {
    flex: 1,
    display: "flex",
    "@media (max-width: 1036px)": {
      display: "none",
    },
  },
  rightBlockBackground: {
    flex: 1,
    display: "flex",
    margin: "20px",
    backgroundImage: `url(${banner})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPositionX: "50%",
    borderRadius: "24px",
    height: "880px",
  },
  leftBlock: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    height: '920px',
    "@media (max-width: 1036px)": {
      height: 'unset',
    },
  },
  logoContainer: {
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    margin: "40px auto",
    "@media (max-width: 500px)": {
      margin: "40px",
      flexDirection: "column",
    }
  },
  logoText: {
    fontFamily: "Oswald, sans-serif",
    fontWeight: 600,
    fontSize: "26px",
    lineHeight: "24px",
    "@media (max-width: 500px)": {
      marginTop: "16px",
    }
  },
  forgotPasswordFormContainer: {
    margin: "auto",
    maxWidth: "600px",
    width: "80%",
  },
  formContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  formTitle: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: "32px",
    marginBottom: "15px",
    cursor: "pointer",
  },
  formSubTitle: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#475467",
    marginBottom: "20px",
  },
  fieldControl: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    marginTop: "10px",
    marginBottom: "32px",
    position: "relative",
    "& input::placeholder": {
      color: "#94A3B8",
      fontFamily: "'Inter', sans-serif",
      fontSize: '16px',
    }
  },
  inputLabel: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "22px",
    color: "#334155",
    marginBottom: "5px",
    alignItems: "center",
    display: "flex",
  },
  inputElement: (error: boolean = false) => ({
    height: "56px",
    fontSize: '16px',
    padding: error ? "10px 52px 10px 10px" : "10px",
    borderRadius: "8px",
    border: error ? "1px solid #F87171" : "1px solid #CBD5E1",
    fontFamily: "'Inter', sans-serif",
    fontWeight: 400,
    lineHeight: "24px",
  }),
  errorIcon: {
    width: "24px",
    height: "24px",
    position: "absolute" as "absolute",
    top: 43,
    right: 16,
  },
  submitButton: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    width: "100%",
    height: "56px",
    borderRadius: "8px",
    border: "none",
    background: "#1A7BA4",
    color: "#FFFFFF",
    cursor: "pointer",
  },
  resendEmailRedirection: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
    margin: "20px auto",
    width: "max-content",
  },
  resendEmailRedirectionLabel: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "22px",
    marginRight: "8px",
    color: "#0F172A",
    alignItems: "center",
    display: "flex",
  },
  resendEmailRedirectionButton: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "22px",
    border: "none",
    backgroundColor: "#F8FAFC",
    cursor: "pointer",
  },
  snackbar: {
    fontFamily: "'Inter', sans-serif",
    "& .MuiPaper-root": {
      backgroundColor: "#FFFFFF",
      fontFamily: "'Inter', sans-serif !important",
      padding: "8px 16px 8px 8px",
      boxShadow: "0px 6px 15px -3px #00000026",
      borderRadius: "8px",
      minWidth: "auto",
      "& .MuiSnackbarContent-message": {
        padding: 0,
      },
    },
    "@media (min-width: 1037px)": {
      left: '25% !important',
      transform: 'translateX(-50%)',
    },
  },
  snackbarContent: {
    display: "flex",
    gap: "8px",
    alignItems: "center",
  },
  snackbarIcon: {
    width: "26px",
    height: "26px",
  },
  snackbarWarningIcon: {
    "> path": {
      fill: "#F87171",
    },
  },
  snackbarSuccessIcon: {
    "> path": {
      fill: "#34D399",
    },
  },
  snackbarText: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#0F172A",
  },
};
// Customizable Area End
