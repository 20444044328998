// App.js - WEB
import React, { Component } from 'react';
import { View } from 'react-native';
import firebase from 'firebase';
import { connect } from 'react-firebase';
import { BuilderProvider } from '@builder/component-library';
import "../../components/src/Styles.css"
import AOS from 'aos';
import 'aos/dist/aos.css';
import WebRoutesGenerator from '../../components/src/NativeWebRouteWrapper';
import { ModalContainer } from 'react-router-modal';
import HomeScreen from '../../components/src/HomeScreen';
import TopNav from '../../components/src/TopNav';
import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import AlertBlock from '../../blocks/alert/src/AlertBlock';
import Tasks from "../../blocks/tasks/src/Tasks";
import TaskList from "../../blocks/tasks/src/TaskList";
import AppHeader from "../../components/src/AppHeader.web";
import AdminHeader from "../../components/src/AdminHeader.web";
import AdminSideBar from "../../components/src/AdminSideBar.web";
import AppFooter from "../../components/src/AppFooter.web";
import Task from "../../blocks/tasks/src/Task";
import SaveAsPdf from "../../blocks/saveaspdf/src/SaveAsPdf";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import BulkUploading from "../../blocks/bulkuploading/src/BulkUploading";
import LandingPage from "../../blocks/landingpage/src/LandingPage";
import LogInPage from "../../blocks/loginpage/src/LogInPage";
import SuccessfullAddingReadingPage from "../../blocks/successfulladdingreadingpage/src/SuccessfullAddingReadingPage";
import SuccessfullNoteSubmitedPage from "../../blocks/successfullnotesubmitedpage/src/SuccessfullNoteSubmitedPage";
import PropertiesPage from "../../blocks/propertiespage/src/PropertiesPage";
import ReportsPage from "../../blocks/reportspage/src/ReportsPage"
import SettingsPage from "../../blocks/settingspage/src/SettingsPage"
import SupportPage from "../../blocks/supportpage/src/SupportPage"
import DashboardPage from "../../blocks/dashboardpage/src/DashboardPage"
import AnaliticsPage from "../../blocks/analiticspage/src/AnaliticsPage"
import AdminSupportPage from "../../blocks/adminsupportpage/src/AdminSupportPage"
import TenantPage from "../../blocks/tenantpage/src/TenantPage";
import MeterPage from "../../blocks/meterpage/src/MeterPage";
import AddPropertiesPage from "../../blocks/addpropertiespage/src/AddPropertiesPage";
import AddTenantPage from "../../blocks/addtenantpage/src/AddTenantPage";
import AddReadingPage from "../../blocks/addreadingpage/src/AddReadingPage";
import SignUpPage from "../../blocks/signuppage/src/SignUpPage";
import ResetPasswordPage from "../../blocks/resetpasswordpage/src/ResetPasswordPage";
import ForgotPasswordPage from "../../blocks/forgotpasswordpage/src/ForgotPasswordPage";
import EmailResetPasswordPage from "../../blocks/emailresetpasswordpage/src/EmailResetPasswordPage";
import Analytics from "../../blocks/analytics/src/Analytics";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import SocialMediaAccountLogin from "../../blocks/social-media-account/src/SocialMediaAccountLogin";
import SocialMediaAccountRegistration from "../../blocks/social-media-account/src/SocialMediaAccountRegistration";
import EducationalUserProfile from "../../blocks/educational-user-profile/src/EducationalUserProfile";
import AccountGroups from "../../blocks/accountgroups/src/AccountGroups";
import Contactus from "../../blocks/contactus/src/Contactus";
import AddContactus from "../../blocks/contactus/src/AddContactus";
import Filteritems from "../../blocks/filteritems/src/Filteritems";
import Filteroptions from "../../blocks/filteritems/src/Filteroptions";
import AdvancedSearch from "../../blocks/advancedsearch/src/AdvancedSearch";
import UserProfileBasicBlock from "../../blocks/user-profile-basic/src/UserProfileBasicBlock";
import Scheduling from "../../blocks/scheduling/src/Scheduling";
import Search from "../../blocks/search/src/Search";
import NavigationMenu from "../../blocks/navigationmenu/src/NavigationMenu";
import Rolesandpermissions2 from "../../blocks/rolesandpermissions2/src/Rolesandpermissions2";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import Sorting from "../../blocks/sorting/src/Sorting";
import Settings2 from "../../blocks/settings2/src/Settings2";
import AdminSettingsPage from "../../blocks/adminsettingspage/src/AdminSettingsPage";
import DocumentOpener from "../../blocks/documentopener/src/DocumentOpener";
import TermsConditions from "../../blocks/termsconditions/src/TermsConditions";
import TermsConditionsDetail from "../../blocks/termsconditions/src/TermsConditionsDetail";
import TermsConditionsUsers from "../../blocks/termsconditions/src/TermsConditionsUsers";
import Notificationsettings from "../../blocks/notificationsettings/src/Notificationsettings";
import Emailnotifications2 from "../../blocks/emailnotifications2/src/Emailnotifications2";
import AboutUs from "../../components/src/AboutUs.web";
import NotFound from "../../components/src/NotFound.web";
import Testimonials from "../../components/src/Testimonials.web";
import ServicesPage from "../../blocks/servicespage/src/ServicesPage.web";
import fieldReprentatives from "../../blocks/fieldRepresentativesPage/src/fieldRepresentativesPage";
import CustomersPage from "../../blocks/cusotmers/src/CustomersPage.web";
import CustomerDetailsPage from "../../blocks/cusotmers/src/CustomersPageDetails.web";
import AdminConsoleSideBar from "../../components/src/AdminConsoleSideBar.web";

const routeMap = {
LandingPage:{
 component:LandingPage,
path:"/LandingPage"},
Contactus:{
 component:Contactus,
path:"/Contactus"},
  AboutUs: {
    component: AboutUs,
    path: "/Aboutus"
  },
  ServicesPage: {
    component: ServicesPage,
    path: "/Services"
  },
  Testimonials: {
    component: Testimonials,
    path: "/Testimonials"
  },
  Home: {
    component: LandingPage,
    path: '/',
    exact: true,
  },
  Alert: {
    component: AlertBlock,
    path: "*/Alert",
    modal: true
  },
  SignUpPage:{
    component:SignUpPage,
    path:"/SignUpPage"},
  LogInPage:{
    component:LogInPage,
    path:"/LogInPage"},
  ResetPasswordPage:{
    component:ResetPasswordPage,
    path:"/ResetPasswordPage"},
  ForgotPasswordPage:{
    component:ForgotPasswordPage,
    path:"/ForgotPasswordPage"},
  EmailResetPasswordPage:{
    component:EmailResetPasswordPage,
    path:"/EmailResetPasswordPage"}, 
  AddReadingPage:{
    component:AddReadingPage,
    path:"/AddReadingPage"},
  SuccessfullAddingReadingPage:{
    component:SuccessfullAddingReadingPage,
    path:"/SuccessfullAddingReadingPage"},
  SuccessfullNoteSubmitedPage:{
    component:SuccessfullNoteSubmitedPage,
    path:"/SuccessfullNoteSubmitedPage"},   
  PropertiesPage:{
    component:PropertiesPage,
    path:"/PropertiesPage"},
  TenantPage:{
    component:TenantPage,
    path:"/TenantPage"},
  MeterPage:{
    component:MeterPage,
    path:"/MeterPage"},
  ReportsPage:{
    component:ReportsPage,
    path:"/ReportsPage"},
  SettingsPage:{
    component:SettingsPage,
    path:"/SettingsPage"},
  SupportPage:{
    component:SupportPage,
    path:"/SupportPage"},
  AddPropertiesPage:{
    component:AddPropertiesPage,
    path:"/AddPropertiesPage"},
  AddTenantPage: {
    component:AddTenantPage,
    path:"/AddTenantPage"},
  DashboardPage: {
    component: DashboardPage,
    path: "/DashboardPage",
  },
  FieldRepresentativesPage: {
    component: fieldReprentatives,
    path: "/FieldRepresentativesPage",
  },
  CustomersPage: {
    component: CustomersPage,
    path: "/CustomersPage",
  },
  AdminSettingsPage: {
    component: AdminSettingsPage,
    path: "/admin/settings",
  },
  AnaliticsPage: {
    component: AnaliticsPage,
    path: "/admin/analytics",
  },
  AdminSupportPage: {
    component: AdminSupportPage,
    path: "/admin/support",
  },
  CustomerDetailsPage: {
    component: CustomerDetailsPage,
    path: "/CustomerDetailsPage",
  },
};

const routeMapWithoutHeader = {
  SignUpPage:{
    component:SignUpPage,
   path:"/SignUpPage"},
  LogInPage:{
    component:LogInPage,
   path:"/LogInPage"},
  ResetPasswordPage:{
    component:ResetPasswordPage,
   path:"/ResetPasswordPage"},
  ForgotPasswordPage:{
    component:ForgotPasswordPage,
   path:"/ForgotPasswordPage"},
  EmailResetPasswordPage:{
    component:EmailResetPasswordPage,
   path:"/EmailResetPasswordPage"}, 
};

const routeMapAdminPanel = {
  PropertiesPage:{
    component:PropertiesPage,
   path:"/PropertiesPage"},
  TenantPage:{
    component:TenantPage,
   path:"/TenantPage"},
  MeterPage:{
    component:MeterPage,
   path:"/MeterPage"},
  ReportsPage:{
    component:ReportsPage,
    path:"/ReportsPage"},
  SettingsPage:{
    component:SettingsPage,
    path:"/SettingsPage"},
  SupportPage:{
    component:SupportPage,
    path:"/SupportPage"},
  DashboardPage: {
    component: DashboardPage,
    path: "/DashboardPage",
  },
 
};

const routeMapAddOrEditAdminPanel = {
  AddReadingPage:{
    component:AddReadingPage,
    path:"/AddReadingPage"},
  SuccessfullAddingReadingPage:{
    component:SuccessfullAddingReadingPage,
    path:"/SuccessfullAddingReadingPage"},
  SuccessfullNoteSubmitedPage:{
    component:SuccessfullNoteSubmitedPage,
    path:"/SuccessfullNoteSubmitedPage"},
  AddPropertiesPage:{
    component:AddPropertiesPage,
    path:"/AddPropertiesPage"},
  AddTenantPage: {
    component:AddTenantPage,
    path:"/AddTenantPage"},
};

const routeMapAdminConsolePanel = {
  FieldRepresentativesPage: {
    component: fieldReprentatives,
    path: "/FieldRepresentativesPage",
  },
  CustomersPage: {
    component: CustomersPage,
    path: "/CustomersPage",
  },
  CustomerDetailsPage: {
    component: CustomerDetailsPage,
    path: "/CustomerDetailsPage",
  },
  AdminSettingsPage: {
    component: AdminSettingsPage,
    path: "/admin/settings",
  },
  AnaliticsPage: {
    component: AnaliticsPage,
    path: "/admin/analytics",
  },
  AdminSupportPage: {
    component: AdminSupportPage,
    path: "/admin/support",
  },
};


const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3',
});

class App extends Component {
  componentDidMount() {
    AOS.init({
      duration: 1200
    })
  }
  render() {
    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    const allowedPaths = Object.values(routeMapWithoutHeader).map(rM => rM.path);
    const adminPanelPaths = Object.values(routeMapAdminPanel).map(rM => rM.path);
    const adminConsolePanelPaths = Object.values(routeMapAdminConsolePanel).map(rM => rM.path);
    const addOrEEditPanelPaths = Object.values(routeMapAddOrEditAdminPanel).map(rM => rM.path);

    const showSideBar = adminPanelPaths.includes(window.location.pathname);
    const showAdminConsoleSideBar = adminConsolePanelPaths.includes(window.location.pathname);
    const showAdminHeader = addOrEEditPanelPaths.includes(window.location.pathname) || showSideBar || showAdminConsoleSideBar;
    const adminPanel = allowedPaths.includes(window.location.pathname) || showSideBar || showAdminHeader;

    const notfound = !Object.values(routeMap).map(rm => rm.path).includes(window.location.pathname)

    console.log({ showSideBar, showAdminHeader, showAdminConsoleSideBar, adminPanel });
    return (
      <BuilderProvider>
        {adminPanel ? (
          <View style={{ height: '100vh', width: '100%' }}>
            {(showAdminHeader || showAdminConsoleSideBar) && <AdminHeader />}
            <View style={{ display: "flex", flexDirection: "row", minHeight: 'calc(100% - 64px)' }}>
              {showAdminConsoleSideBar ? <AdminConsoleSideBar /> : (showSideBar ? <AdminSideBar /> : null)}
              <View style={{ display: "flex", flex: 1 }}>
                {WebRoutesGenerator({ routeMap })}
              </View>
            </View>
          </View>
        ) : notfound ? (
          <View style={{ height: '100vh', width: '100%' }}>
            <AppHeader />
              <NotFound />
            <AppFooter />
          </View>
        ): (
          <View style={{ height: '100vh', width: '100%' }}>
            <AppHeader />
            {WebRoutesGenerator({ routeMap })}
            <ModalContainer />
            <AppFooter />
          </View>
        )}
      </BuilderProvider>
    );
  }
}

export default App;