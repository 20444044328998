import React from "react";
// Customizable Area Start
import {
  Typography,
  Box,
  Select,
  MenuItem,
  Tabs,
  Tab,
  Modal,
  Button,
  CircularProgress,
  styled,
  Snackbar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Divider,
  DialogActions,
} from "@mui/material";
import { searchIcon, showIcon, deleteIcon, closeIcon, downArrowIcon } from "./assets";
import KeyboardArrowLeftRoundedIcon from "@mui/icons-material/KeyboardArrowLeftRounded";
import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";
const empty = require("../assets/empty.svg");
import { createPortal } from 'react-dom';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import DeleteIcon from "@mui/icons-material/DeleteOutlineOutlined";
// Customizable Area End

import AdminSupportPageController, {
  CustomerAppStatus,
  DateFilter,
  Props,
  configJSON,
} from "./AdminSupportPageController";
import moment from "moment";
// Customizable Area Start

const SupportStatusColorMap = {
    [CustomerAppStatus.PROCESSING]: { color: '#D97706', backgroundColor: '#FEF3C7' },
    [CustomerAppStatus.PROCESSED]: { color: '#059669', backgroundColor: '#D1FAE5' },
}

// Customizable Area End
export default class AdminSupportPage extends AdminSupportPageController {
  constructor(props: Props) {
    super(props);
  }

  customerAppList = () => {
    const currentDate = moment();
    const previousYear = currentDate.year() - 1;
    const twoYearsAgo = currentDate.year() - 2;
    const threeYearsAgo = currentDate.year() - 3;
    if (this.state.customerSupportLoading) {
      return (
        <Box sx={webStyle.loaderWrapper} data-test-id="loaderWrapper">
          <CircularProgress sx={webStyle.loader} />
        </Box>
      );
    }

    if (this.state.customerAppList.length === 0) {
      return (
        <Box sx={webStyle.noReportBlock}>
          <Box sx={webStyle.noReportBlockTitle}>
            <img src={empty} style={webStyle.mainImage} />
            <Typography sx={webStyle.noReportTitle}>
              No Customer Support Requests
            </Typography>
          </Box>
        </Box>
      )
    }
    return (
      <>
        <Box sx={webStyle.customerAppTitle}>
          <Box sx={webStyle.tabContainer}>
            <Box sx={webStyle.searchContainer}>
              <input
                style={webStyle.inputElemnt}
                data-test-id="search"
                type={"text"}
                placeholder={configJSON.searchPlaceholderText}
                onChange={(event) => {
                  this.searchCustomerApp(event.target.value);
                }}
              />
              <img src={searchIcon} alt="" style={webStyle.searchIcon} />
            </Box>
            <Box sx={webStyle.selectContainer}>
              <Typography sx={webStyle.inputLabel}>
                {configJSON.dateText}
              </Typography>
              <Box sx={webStyle.fieldControl}>
                <Select
                  value={this.state.dateFilter}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  data-test-id="date"
                  onChange={(event) => {
                    this.changeDateFilter(
                      event.target.value as DateFilter);
                  }}
                  sx={webStyle.select}
                >
                   <MenuItem
                      value={DateFilter.LAST_12_MONTHS}
                      sx={webStyle.selectOption}
                    >
                      {configJSON.lastYearText}
                    </MenuItem>
                    <MenuItem
                      value={DateFilter.LAST_YEAR}
                      sx={webStyle.selectOption}
                    >
                      {configJSON.previusYearText(previousYear)}
                    </MenuItem>
                    <MenuItem
                      value={DateFilter.TWO_YEARS_AGO}
                      sx={webStyle.selectOption}
                    >
                      {twoYearsAgo}
                    </MenuItem>
                    <MenuItem
                      value={DateFilter.THREE_YEARS_AGO}
                      sx={webStyle.selectOption}
                    >
                      {threeYearsAgo}
                    </MenuItem>
                </Select>
              </Box>
            </Box>
          </Box>
          <Typography sx={webStyle.customerAppCount}>
            {this.state.filteredCustomerAppList.length + " results"}
          </Typography>
        </Box>
        <Box sx={webStyle.customerAppTableWrapper(this.state.customerAppTotalPages)}>
          <Box sx={webStyle.customerAppsTable}>
            <Box sx={webStyle.customerAppsTableHead}>
              <Box sx={webStyle.customerAppsDataTableHead}>
                <Box sx={webStyle.headerCompanyNameTitle}>
                  {configJSON.companyNameText}
                </Box>
                <Box sx={webStyle.headerServicesNeededTitle}>
                  {configJSON.serviceNeededText}
                </Box>
                <Box sx={webStyle.headerCommentTitle}>
                  {configJSON.commentText}
                </Box>
                <Box sx={webStyle.headerCreatedAtTitle}>
                  {configJSON.createdAtText}
                </Box>
                <Box sx={webStyle.headerStatusTitle}>
                  {configJSON.statusText}
                </Box>
              </Box>
              <Box sx={webStyle.headerTitleAction}>
                {configJSON.actionsText}
              </Box>
            </Box>
            {this.state.currentCustomerAppList.map((app, index) => (
              <Box sx={webStyle.customerAppsTableBody} key={app.id}>
                <Box sx={webStyle.cutomerAppsDataTableBody}>
                  <Box sx={webStyle.customerAppCompanyNameItem}>{app.company_name}</Box>
                  <Box sx={webStyle.customerAppServicesNeededItem}>{app.services_needed}</Box>
                  <Box sx={webStyle.customerAppCommentItem}>{app.message}</Box>
                  <Box sx={webStyle.customerAppCreatedAtItem}>{app.created_at}</Box>
                  <Box sx={webStyle.customerAppStatusItem} data-test-id={`customerAppStatus.${app.id}`}>
                    <StyledSelect
                      defaultValue={app.status}
                      sx={webStyle.selectStatus(app.status)}
                      onChange={(event) => this.changeCustomerSupportStatus(app.id, event.target.value as string)}
                      disabled={app.id === this.state.changeStatusId}
                      data-test-id={`reportStatus.${index}`}
                    >
                      {Object.values(CustomerAppStatus).map((value) => {
                        const { color, backgroundColor } = SupportStatusColorMap[value] || {};

                        return (
                          <MenuItem value={value} sx={webStyle.selectOption} key={value}>
                            <Box
                              sx={webStyle.selectOptionText(
                                color,
                                backgroundColor
                              )}
                            >
                              {value}
                            </Box>
                          </MenuItem>
                        );
                      })}
                    </StyledSelect>
                  </Box>
                </Box>
                <Box sx={webStyle.customerAppItemAction}>
                  <Button
                    sx={webStyle.showButton}
                    data-test-id={`showCustomerApp.${app.id}`}
                    onClick={() => {
                      this.openCustomerAppModal(app.id);
                    }}
                  >
                    <img src={showIcon} alt="" style={webStyle.btnIcon} />
                    <Typography sx={webStyle.typographyText}>
                      {configJSON.showText}
                    </Typography>
                  </Button>
                  <Button
                    sx={webStyle.deleteButton}
                    data-test-id={`deleteCustomerApp.${app.id}`}
                    onClick={() => {
                      this.deleteCustomeerApp(app.id);
                    }}
                  >
                    <img src={deleteIcon} alt="" style={webStyle.btnIcon} />
                    <Typography sx={webStyle.typographyText}>
                      {configJSON.deleteText}
                    </Typography>
                  </Button>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
        <Dialog
          open={!!this.state.deleteCustomerSupportDialogId}
          onClose={this.handleCloseDeleteDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          PaperProps={{
            style: {
              width: "633px", 
              height: "autp", 
              padding: "40px 32px 32px 32px",
              borderRadius: "16px",
              fontFamily: "'Inter', sans-serif",
            },
          }}
          data-test-id="deleteModal"
        >
          <DialogTitle
            id="alert-dialog-title"
            sx={webStyle.dialogTitle}
          >
            Are you sure you want to delete this support
          </DialogTitle>
          {/* <DialogContent style={{ marginBottom: "32px", padding: 0 }}> */}
          <DialogContent style={{ padding: "0px 20px"}}>
          <DialogContentText
              id="alert-dialog-description"
              sx={webStyle.dialogContent} 
            >
              This action can’t be undone and all the data stored in this
              account won’t be accessible anymore.
            </DialogContentText>
            <Divider />
          </DialogContent>
          <DialogActions style={webStyle.dialogAction}>
            <Button
              onClick={this.handleCloseDeleteDialog}
              variant="outlined"
              sx={webStyle.cancelButton}
              data-test-id="cancelModalButton"
            >
              Cancel
            </Button>
            <Button
              onClick={() =>
                this.deleteCustomerSupport(
                  this.state.deleteCustomerSupportDialogId
                )
              }
              sx={webStyle.confirmDelete}
              startIcon={<DeleteIcon />}
              data-test-id="deleteModalButton"
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
        {this.state.customerAppTotalPages > 1 && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: "20px",
                marginBottom: "5%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexGrow: 1,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {/* Previous Button */}
                <button
                  onClick={() => this.handlePrevCustomerAppPage()}
                  data-test-id="previous-button"
                  style={{
                    backgroundColor: "inherit",
                    border: "none",
                    cursor: "pointer",
                    padding: 0,
                    marginRight: "4px",
                  }}
                  disabled={this.state.customerAppCurrentPage === 1}
                >
                  <KeyboardArrowLeftRoundedIcon
                    sx={
                      this.state.customerAppCurrentPage === 1
                        ? webStyle.chevronIconDisabled
                        : webStyle.chevronIconActive
                    }
                  />
                </button>

                {this.state.customerAppTotalPages <= 3 ? (
                  [...Array(this.state.customerAppTotalPages).keys()].map((page) => (
                    <button
                      key={page + 1}
                      data-test-id={"page-button"}
                      style={{
                        marginRight: "4px",
                        width:
                          this.state.customerAppCurrentPage === page + 1
                            ? "24px"
                            : "16px",
                        height: "24px",
                        backgroundColor:
                          this.state.customerAppCurrentPage === page + 1
                            ? "#DBF5FF"
                            : "inherit",
                        color:
                          this.state.customerAppCurrentPage === page + 1
                            ? "#1A7BA4"
                            : "#64748B",
                        border: "none",
                        textDecoration:
                          this.state.customerAppCurrentPage !== page + 1
                            ? "underline"
                            : "none",
                        borderRadius: "8px",
                        fontWeight:
                          this.state.customerAppCurrentPage !== page + 1 ? 400 : 700,
                        cursor: "pointer",
                        fontSize: "12px",
                        fontFamily: "'Inter', sans-serif",
                      }}
                      onClick={() => this.handleCustomerAppPageChange(page + 1)}
                    >
                      {page + 1}
                    </button>
                  ))
                ) : (
                  <>
                    {this.state.customerAppCurrentPage !== 1 && (
                      <button
                        data-test-id="ellipsis"
                        style={{
                          marginRight: "4px",
                          width: "16px",
                          height: "24px",
                          backgroundColor: "inherit",
                          color: "#64748B",
                          border: "none",
                          textDecoration: "underline",
                          borderRadius: "8px",
                          cursor: "pointer",
                          fontSize: "12px",
                          fontFamily: "'Inter', sans-serif",
                        }}
                        onClick={() => this.handleCustomerAppPageChange(1)}
                      >
                        1
                      </button>
                    )}

                    {this.state.customerAppCurrentPage > 3 && (
                      <span style={{ marginRight: "4px", color: "#64748B" }}>
                        ...
                      </span>
                    )}

                    {this.state.customerAppCurrentPage > 2 && (
                      <button
                        data-test-id="middle-page"
                        style={{
                          marginRight: "4px",
                          width: "24px",
                          height: "24px",
                          backgroundColor: "inherit",
                          color: "#64748B",
                          border: "none",
                          textDecoration: "underline",
                          borderRadius: "8px",
                          cursor: "pointer",
                          fontSize: "12px",
                          fontFamily: "'Inter', sans-serif",
                        }}
                        onClick={() =>
                          this.handleCustomerAppPageChange(this.state.customerAppCurrentPage - 1)
                        }
                      >
                        {this.state.customerAppCurrentPage - 1}
                      </button>
                    )}
                    <button
                      data-test-id="middle-page2"
                      style={{
                        marginRight: "4px",
                        width: "24px",
                        height: "24px",
                        backgroundColor: "#DBF5FF",
                        color: "#1A7BA4",
                        border: "none",
                        borderRadius: "8px",
                        cursor: "pointer",
                        fontWeight: 700,
                        fontSize: "12px",
                        fontFamily: "'Inter', sans-serif",
                      }}
                      onClick={() =>
                        this.handleCustomerAppPageChange(this.state.customerAppCurrentPage)
                      }
                    >
                      {this.state.customerAppCurrentPage}
                    </button>

                    {this.state.customerAppCurrentPage < this.state.customerAppTotalPages - 1 && (
                      <button
                        data-test-id="middle-page3"
                        style={{
                          marginRight: "4px",
                          width: "16px",
                          height: "24px",
                          color: "#64748B",
                          backgroundColor: "inherit",
                          border: "none",
                          textDecoration: "underline",
                          borderRadius: "8px",
                          cursor: "pointer",
                          fontSize: "12px",
                          fontFamily: "'Inter', sans-serif",
                        }}
                        onClick={() =>
                          this.handleCustomerAppPageChange(this.state.customerAppCurrentPage + 1)
                        }
                      >
                        {this.state.customerAppCurrentPage + 1}
                      </button>
                    )}

                    {this.state.customerAppCurrentPage < this.state.customerAppTotalPages - 2 && (
                      <span style={{ marginRight: "4px", color: "#64748B" }}>
                        ...
                      </span>
                    )}

                    {this.state.customerAppCurrentPage !== this.state.customerAppTotalPages && (
                      <button
                        data-test-id="last-page"
                        style={{
                          marginRight: "4px",
                          width:
                            this.state.customerAppCurrentPage === this.state.customerAppTotalPages
                              ? "24px"
                              : "16px",
                          height: "24px",
                          backgroundColor:
                            this.state.customerAppCurrentPage === this.state.customerAppTotalPages
                              ? "#DBF5FF"
                              : "inherit",
                          color:
                            this.state.customerAppCurrentPage === this.state.customerAppTotalPages
                              ? "#1A7BA4"
                              : "#64748B",
                          border: "none",
                          textDecoration:
                            this.state.customerAppCurrentPage !== this.state.customerAppTotalPages
                              ? "underline"
                              : "none",
                          borderRadius: "8px",
                          cursor: "pointer",
                          fontSize: "12px",
                          fontFamily: "'Inter', sans-serif",
                          fontWeight:
                            this.state.customerAppCurrentPage !== this.state.customerAppTotalPages
                              ? 400
                              : 700,
                        }}
                        onClick={() =>
                          this.handleCustomerAppPageChange(this.state.customerAppTotalPages)
                        }
                      >
                        {this.state.customerAppTotalPages}
                      </button>
                    )}
                  </>
                )}

                <button
                  data-test-id="next-page"
                  onClick={() => this.handleNextCustomerAppPage()}
                  style={{
                    backgroundColor: "inherit",
                    border: "none",
                    cursor: "pointer",
                    padding: 0,
                  }}
                  disabled={this.state.customerAppCurrentPage === this.state.customerAppTotalPages}
                >
                  <KeyboardArrowRightRoundedIcon
                    sx={
                      this.state.customerAppCurrentPage === this.state.customerAppTotalPages
                        ? webStyle.chevronIconDisabled
                        : webStyle.chevronIconActive
                    }
                  />
                </button>
              </Box>

              <Box sx={{ marginRight: "10px", width: "120px" }}>
                <Typography
                  fontFamily={'"Nunito Sans", sans-serif'}
                  fontSize={"12px"}
                  color={"grey"}
                >
                  {this.state.customerAppFirstItemIndex + 1}-{this.state.customerAppLastItemIdex} of{" "}
                  {this.state.filteredCustomerAppList.length} results
                </Typography>
              </Box>
            </Box>
        )}
      </>
    )
  }

  contactFormList = () => {
    if (this.state.contactUsLoading) {
      return (
        <Box sx={webStyle.loaderWrapper} data-test-id="loaderWrapper">
          <CircularProgress sx={webStyle.loader} />
        </Box>
      );
    }

    if (this.state.contactFormList.length === 0) {
      return (
        <Box sx={webStyle.noReportBlock}>
          <Box sx={webStyle.noReportBlockTitle}>
            <img src={empty} style={webStyle.mainImage} />
            <Typography sx={webStyle.noReportTitle}>
              No Contact Forms
            </Typography>
          </Box>
        </Box>
      )
    }

    return (
      <>
        <Box sx={webStyle.contactFormTitle}>
          <Typography sx={webStyle.contactFormCount}>
            {this.state.contactFormList.length + " results"}
          </Typography>
        </Box>
        <Box sx={webStyle.contactFormTableWrapper(this.state.contactFormTotalPages)}>
          <Box sx={webStyle.contactFormsTable}>
            <Box sx={webStyle.contactFormsTableHead}>
              <Box sx={webStyle.contactFormsDataTableHead}>
                <Typography sx={webStyle.headerContactFormNameTitle}>
                  {configJSON.contactFormNameText}
                </Typography>
                <Typography sx={webStyle.headerContactFormEmailTitle}>
                  {configJSON.contactFormEmailText}
                </Typography>
                <Typography sx={webStyle.headerContactFormPhoneTitle}>
                  {configJSON.contactFormPhoneText}
                </Typography>
                <Typography sx={webStyle.headerContactFormServicesNeededTitle}>
                  {configJSON.contactFormServiceNeededText}
                </Typography>
                <Typography sx={webStyle.headerContactFormCommentTitle}>
                  {configJSON.contactFormCommentText}
                </Typography>
                <Typography sx={webStyle.headerContactFormCreatedAtTitle}>
                  {configJSON.contactFormCreatedAtText}
                </Typography>
              </Box>
              <Typography sx={webStyle.headerContactFormTitleAction}>
                {configJSON.contactFormActionsText}
              </Typography>
            </Box>
            {this.state.currentContactFormList.map((form) => (
              <Box sx={webStyle.contactFormsTableBody} key={form.id}>
                <Box sx={webStyle.contactFormsDataTableBody}>
                  <Box sx={webStyle.contactFormNameItem}>{form.name}</Box>
                  <Box sx={webStyle.contactFormEmailItem}>{form.email}</Box>
                  <Box sx={webStyle.contactFormPhoneItem}>{form.phone_number}</Box>
                  <Box sx={webStyle.contactFormServicesNeededItem}>{form.services_needed}</Box>
                  <Box sx={webStyle.contactFormCommentItem}>{form.message}</Box>
                  <Box sx={webStyle.contactFormCreatedAtItem}>{form.created_at}</Box>
                </Box>
                <Box sx={webStyle.contactFormItemAction}>
                  <Button
                    sx={webStyle.showButton}
                    data-test-id={`showDetails.${form.id}`}
                    onClick={() => {
                      this.openContactFormModal(form.id);
                    }}
                  >
                    <img src={showIcon} alt="" style={webStyle.btnIcon} />
                    <Typography sx={webStyle.typographyText}>
                      {configJSON.viewDetailsText}
                    </Typography>
                  </Button>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
        {this.state.contactFormTotalPages > 1 && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: "20px",
                marginBottom: "5%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexGrow: 1,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {/* Previous Button */}
                <button
                  onClick={() => this.handlePrevContactFormPage()}
                  data-test-id="previous-button"
                  style={{
                    backgroundColor: "inherit",
                    border: "none",
                    cursor: "pointer",
                    padding: 0,
                    marginRight: "4px",
                  }}
                  disabled={this.state.contactFormCurrentPage === 1}
                >
                  <KeyboardArrowLeftRoundedIcon
                    sx={
                      this.state.contactFormCurrentPage === 1
                        ? webStyle.chevronIconDisabled
                        : webStyle.chevronIconActive
                    }
                  />
                </button>

                {this.state.contactFormTotalPages <= 3 ? (
                  [...Array(this.state.contactFormTotalPages).keys()].map((page) => (
                    <button
                      key={page + 1}
                      data-test-id={"page-button"}
                      style={{
                        marginRight: "4px",
                        width:
                          this.state.contactFormCurrentPage === page + 1
                            ? "24px"
                            : "16px",
                        height: "24px",
                        backgroundColor:
                          this.state.contactFormCurrentPage === page + 1
                            ? "#DBF5FF"
                            : "inherit",
                        color:
                          this.state.contactFormCurrentPage === page + 1
                            ? "#1A7BA4"
                            : "#64748B",
                        border: "none",
                        textDecoration:
                          this.state.contactFormCurrentPage !== page + 1
                            ? "underline"
                            : "none",
                        borderRadius: "8px",
                        fontWeight:
                          this.state.contactFormCurrentPage !== page + 1 ? 400 : 700,
                        cursor: "pointer",
                        fontSize: "12px",
                        fontFamily: "'Inter', sans-serif",
                      }}
                      onClick={() => this.handleContactFormPageChange(page + 1)}
                    >
                      {page + 1}
                    </button>
                  ))
                ) : (
                  <>
                    {this.state.contactFormCurrentPage !== 1 && (
                      <button
                        data-test-id="ellipsis"
                        style={{
                          marginRight: "4px",
                          width: "16px",
                          height: "24px",
                          backgroundColor: "inherit",
                          color: "#64748B",
                          border: "none",
                          textDecoration: "underline",
                          borderRadius: "8px",
                          cursor: "pointer",
                          fontSize: "12px",
                          fontFamily: "'Inter', sans-serif",
                        }}
                        onClick={() => this.handleContactFormPageChange(1)}
                      >
                        1
                      </button>
                    )}

                    {this.state.contactFormCurrentPage > 3 && (
                      <span style={{ marginRight: "4px", color: "#64748B" }}>
                        ...
                      </span>
                    )}

                    {this.state.contactFormCurrentPage > 2 && (
                      <button
                        data-test-id="middle-page"
                        style={{
                          marginRight: "4px",
                          width: "24px",
                          height: "24px",
                          backgroundColor: "inherit",
                          color: "#64748B",
                          border: "none",
                          textDecoration: "underline",
                          borderRadius: "8px",
                          cursor: "pointer",
                          fontSize: "12px",
                          fontFamily: "'Inter', sans-serif",
                        }}
                        onClick={() =>
                          this.handleContactFormPageChange(this.state.contactFormCurrentPage - 1)
                        }
                      >
                        {this.state.contactFormCurrentPage - 1}
                      </button>
                    )}
                    <button
                      data-test-id="middle-page2"
                      style={{
                        marginRight: "4px",
                        width: "24px",
                        height: "24px",
                        backgroundColor: "#DBF5FF",
                        color: "#1A7BA4",
                        border: "none",
                        borderRadius: "8px",
                        cursor: "pointer",
                        fontWeight: 700,
                        fontSize: "12px",
                        fontFamily: "'Inter', sans-serif",
                      }}
                      onClick={() =>
                        this.handleContactFormPageChange(this.state.contactFormCurrentPage)
                      }
                    >
                      {this.state.contactFormCurrentPage}
                    </button>

                    {this.state.contactFormCurrentPage < this.state.contactFormTotalPages - 1 && (
                      <button
                        data-test-id="middle-page3"
                        style={{
                          marginRight: "4px",
                          width: "16px",
                          height: "24px",
                          color: "#64748B",
                          backgroundColor: "inherit",
                          border: "none",
                          textDecoration: "underline",
                          borderRadius: "8px",
                          cursor: "pointer",
                          fontSize: "12px",
                          fontFamily: "'Inter', sans-serif",
                        }}
                        onClick={() =>
                          this.handleContactFormPageChange(this.state.contactFormCurrentPage + 1)
                        }
                      >
                        {this.state.contactFormCurrentPage + 1}
                      </button>
                    )}

                    {this.state.contactFormCurrentPage < this.state.contactFormTotalPages - 2 && (
                      <span style={{ marginRight: "4px", color: "#64748B" }}>
                        ...
                      </span>
                    )}

                    {this.state.contactFormCurrentPage !== this.state.contactFormTotalPages && (
                      <button
                        data-test-id="last-page"
                        style={{
                          marginRight: "4px",
                          width:
                            this.state.contactFormCurrentPage === this.state.contactFormTotalPages
                              ? "24px"
                              : "16px",
                          height: "24px",
                          backgroundColor:
                            this.state.contactFormCurrentPage === this.state.contactFormTotalPages
                              ? "#DBF5FF"
                              : "inherit",
                          color:
                            this.state.contactFormCurrentPage === this.state.contactFormTotalPages
                              ? "#1A7BA4"
                              : "#64748B",
                          border: "none",
                          textDecoration:
                            this.state.contactFormCurrentPage !== this.state.contactFormTotalPages
                              ? "underline"
                              : "none",
                          borderRadius: "8px",
                          cursor: "pointer",
                          fontSize: "12px",
                          fontFamily: "'Inter', sans-serif",
                          fontWeight:
                            this.state.contactFormCurrentPage !== this.state.contactFormTotalPages
                              ? 400
                              : 700,
                        }}
                        onClick={() =>
                          this.handleContactFormPageChange(this.state.contactFormTotalPages)
                        }
                      >
                        {this.state.contactFormTotalPages}
                      </button>
                    )}
                  </>
                )}

                <button
                  data-test-id="next-page"
                  onClick={() => this.handleNextContactFormPage()}
                  style={{
                    backgroundColor: "inherit",
                    border: "none",
                    cursor: "pointer",
                    padding: 0,
                  }}
                  disabled={this.state.contactFormCurrentPage === this.state.contactFormTotalPages}
                >
                  <KeyboardArrowRightRoundedIcon
                    sx={
                      this.state.contactFormCurrentPage === this.state.contactFormTotalPages
                        ? webStyle.chevronIconDisabled
                        : webStyle.chevronIconActive
                    }
                  />
                </button>
              </Box>

              <Box sx={{ marginRight: "10px", width: "120px" }}>
                <Typography
                  fontFamily={'"Nunito Sans", sans-serif'}
                  fontSize={"12px"}
                  color={"grey"}
                >
                  {this.state.contactFormFirstItemIndex + 1}-{this.state.contactFormLastItemIdex} of{" "}
                  {this.state.contactFormList.length} results
                </Typography>
              </Box>
            </Box>
        )}
      </>
    )
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    const displayContactForm = !!this.state.showContactFormModal ?
      this.state.contactFormList.find(c => c.id === this.state.showContactFormModal) : null;
    const displayCustomerApp = this.state.showCustomerAppModal ?
      this.state.customerAppList.find(c => c.id === this.state.showCustomerAppModal) : null;
    return (
      // Customizable Area Start
      <Box sx={webStyle.supportContainer}>
        <Modal
          open={!!this.state.showContactFormModal || !!this.state.showCustomerAppModal}
          onClose={() => {
            this.closeModals();
          }}
          data-test-id={"modalWindow"}
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
        >
          <Box sx={webStyle.modalBox}>
            <Box sx={webStyle.modalTitleBlock}>
              <Typography sx={webStyle.modalTitle}>
                {!!this.state.showCustomerAppModal 
                  ? displayCustomerApp?.company_name
                  : "Contact"
                }
              </Typography>
              <button
                style={webStyle.modalCloseButton}
                onClick={() => this.closeModals()}
              >
                <img src={closeIcon} alt="Logo" style={{ height: "24px" }} />
              </button>
            </Box>
            <Box
              component="div"
              sx={webStyle.modalScrollBox}
            >
              {!!this.state.showContactFormModal && (
                <>
                  <Box sx={webStyle.modalBlock}>
                    <Typography sx={webStyle.modalBlockLabel}>
                      {configJSON.contactFormNameText}
                    </Typography>
                    <Typography sx={webStyle.modalBlockValue}>
                      {displayContactForm?.name}
                    </Typography>
                  </Box>
                  <Box sx={webStyle.modalDoubleBlock}>
                    <Box sx={{...webStyle.modalBlock, flex: "auto"}}>
                      <Typography sx={webStyle.modalBlockLabel}>
                        {configJSON.contactFormEmailText}
                      </Typography>
                      <Typography sx={webStyle.modalBlockValue}>
                        {displayContactForm?.email}
                      </Typography>
                    </Box>
                    <Box sx={{...webStyle.modalBlock, flex: "auto"}}>
                      <Typography sx={webStyle.modalBlockLabel}>
                        {configJSON.contactFormPhoneText}
                      </Typography>
                      <Typography sx={webStyle.modalBlockValue}>
                        {displayContactForm?.phone_number}
                      </Typography>
                    </Box>
                  </Box>
                </>
              )}
              <Box sx={webStyle.modalBlock}>
                <Typography sx={webStyle.modalBlockLabel}>
                  {configJSON.contactFormServiceNeededText}
                </Typography>
                <Typography sx={webStyle.modalBlockValue}>
                  {!!this.state.showContactFormModal 
                    && displayContactForm?.services_needed
                  }
                  {!!this.state.showCustomerAppModal 
                    && displayCustomerApp?.services_needed
                  }
                </Typography>
              </Box>
              <Box sx={webStyle.modalBlock}>
                <Typography sx={webStyle.modalBlockLabel}>
                  {configJSON.contactFormCommentText}
                </Typography>
                <Typography sx={webStyle.modalBlockValue}>
                  {!!this.state.showContactFormModal 
                    && displayContactForm?.message
                  }
                  {!!this.state.showCustomerAppModal 
                    && displayCustomerApp?.message
                  }
                </Typography>
              </Box>
            </Box>
          </Box>
        </Modal>

        <Box sx={webStyle.supportTableHeader}>
          <Typography sx={webStyle.supportTitle}>
            {configJSON.supportText}
          </Typography>
        </Box>
        <Tabs value={this.state.step} onChange={(_, newValue) => this.setStep(newValue)} sx={webStyle.tabsContainer}>
          <Tab
            value={1}
            label="Customer app"
            data-test-id="firstStep"
            sx={webStyle.tabButton(this.state.step === 1)}
          />
          <Tab
            value={2}
            label="Contact form"
            data-test-id="secondStep"
            sx={webStyle.tabButton(this.state.step === 2)}
          />
        </Tabs>

        {this.state.step === 1 && this.customerAppList()}
        {this.state.step === 2 && this.contactFormList()}
        {createPortal(
          <Snackbar
            open={this.state.changeStatusMsg !== null || this.state.snackbarProps.isOpen}
            message={(
              <Box sx={webStyle.snackbarContent}>
                {
                  this.state.changeStatusMsg === "Change Status Failed" || this.state.snackbarProps.type === "Error" ? (
                    <CancelRoundedIcon sx={webStyle.snackbarIcon} />
                  ) : (
                    <CheckCircleRoundedIcon
                      sx={{
                        ...webStyle.snackbarIcon,
                        ...webStyle.snackbarSuccessIcon,
                      }}
                    />
                  )
                }
                <Typography sx={webStyle.snackbarText}>
                  {this.state.changeStatusMsg || this.state.snackbarProps.message}
                </Typography>
              </Box>
            )}
            autoHideDuration={3500}
            onClose={() => this.setState({changeStatusMsg: null, snackbarProps: {isOpen: false, message: "", type: "Success"}})}
            sx={webStyle.snackbar}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          />,
          document.body
      )}
      </Box>
      //Merge Engine End DefaultContainer
      // Customizable Area Start
    );
  }
}
const StyledSelect = styled(Select)(() => ({
  borderRadius: "100px",
  height: "22px",
  fontSize: "12px",
  fontWeight: 700,
  "& .MuiSelect-icon": {
    borderRadius: "0 100px 100px 0",
    borderLeft: "1px solid #FFFFFF",
    height: "22px",
    right: 0,
    top: 0,
    backgroundColor: "#F1F5F9",
  },
  "& .MuiSelect-iconOpen": {
    borderLeft: 'none',
    borderRadius: '100px 0 0 100px',
    borderRight: '1px solid #FFFFFF',
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: 'none',
  },
}));
// Customizable Area Start
const webStyle = {
  selectStatus: (supportStatus: CustomerAppStatus) => {
    const { color, backgroundColor } = SupportStatusColorMap[supportStatus] || {};

    return {
      "& .MuiSelect-select": {
        padding: "0 24px 0 0 !important",
      },
      fontFamily: "'Inter', sans-serif",
      backgroundColor,
      color,
    };
  },
  selectOptionText: (color: string, backgroundColor: string) => ({
    padding: "2px 8px",
    fontSize: "12px",
    fontWeight: 700,
    borderRadius: "200px",
    color,
    backgroundColor,
    textTransform: "uppercase" as const,
  }),
  noReportBlock: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    maxWidth: "600px",
    width: "80%",
  },
  noReportBlockTitle: {
    margin: "80px 10px",
    textAlign: "center",
  },
  noReportTitle: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: "32px",
    marginBottom: "15px",
    marginTop: "50px",
  },
  mainImage: {
    width: "163px",
    height: "154px",
  },
  loaderWrapper: {
    margin: '200px auto 0',
    width: 'fit-content',
   },
   loader: {
    color: '#1A7BA4',
    height: '60px',
    width: '60px',
   },
  supportContainer: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "rgb(248, 250, 252)",
    padding: "0px 30px",
    minHeight: "100%",
    fontFamily: "'Inter', sans-serif",
  },
  supportTableHeader: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    justifyContent: "space-between",
    margin: "20px 0px",
  },
  customerAppTitle: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    justifyContent: "space-between",
    margin: "10px 0px 20px",
  },
  tabContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    "@media (max-width: 767px)": {
      flexDirection: "column",
    }
  },
  searchContainer: {
    display: "flex",
    flexDirection: "column",
    width: "340px",
    position: "relative",
    "@media (max-width: 767px)": {
      width: "100%",
    }
  },
  selectContainer: {
    display: "flex",
    flexDirection: "row",
    "@media (max-width: 767px)": {
      flexDirection: "column",
      width: "100%",
      margin: "10px 0px",
    }
  },
  customerAppsTable: {
    border: "1px solid #CBD5E1",
    borderRadius: "12px",
    minWidth: "812px",
    "@media (max-width: 969px)": {
      minWidth: "880px",
    }
  },
  customerAppTableWrapper: (totalPages: number) => ({
    minHeight: totalPages > 1 ? '680px' : 'unset',
    marginBottom: "30px",
    "@media (max-width: 1330px)": {
      // "@media (max-width: 767px)": {
      overflow: "auto",
    },
  }),
  contactFormTableWrapper: (totalPages: number) => ({
    minHeight: totalPages > 1 ? '680px' : 'unset',
    marginBottom: "30px",
    "@media (max-width: 1330px)": {
      // "@media (max-width: 767px)": {
      overflow: "auto",
    },
  }),
  customerAppsTableHead: {
    display: "flex",
    flexDirection: "row",
    padding: "0px 10px",
  },
  customerAppsDataTableHead: {
    width: "calc(100% - 214px)",
    display: "flex",
    flexDirection: "row",
    padding: "0px 10px",
    "@media (max-width: 969px)": {
      width: "calc(100% - 90px)",
    }
  },
  customerAppsTableBody: {
    display: "flex",
    flexDirection: "row",
    padding: "0px 10px",
    borderTop: "1px solid #CBD5E1",
    backgroundColor: "#FFFFFF",
    "&:last-of-type": {
      borderRadius: "0 0 12px 12px",
    },
  },
  fieldControl: {
    display: "flex",
    flexDirection: "row",
    margin: "0px 10px",
    position: "relative",
    width: "240px",
    backgroundColor: "#FFFFFF",
    "@media (max-width: 767px)": {
      margin: "0px",
      width: "100%",
    }
  },
  inputLabel: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "22px",
    color: "#334155",
    margin: "0px",
    alignItems: "center",
    display: "flex",
  },
  inputElemnt: {
    height: "44px",
    padding: "10px 40px 10px 10px",
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
  },
  searchIcon: {
    width: "24px",
    height: "24px",
    position: "absolute" as "absolute",
    top: 10,
    right: 10,
  },
  btnIcon: {
    width: "24px",
    height: "24px",
    marginRight: 5,
    "@media (max-width: 969px)": {
      marginRight: 0,
    },
  },
  headerCompanyNameTitle: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "18px",
    textTransform: "uppercase" as const,
    color: "#64748B",
    width: "17%",
    overflow: "hidden",
    margin: "12px 4px",
  },
  headerServicesNeededTitle: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "18px",
    textTransform: "uppercase" as const,
    color: "#64748B",
    width: "19%",
    overflow: "hidden",
    margin: "12px 4px",
  },
  headerCommentTitle: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "18px",
    textTransform: "uppercase" as const,
    color: "#64748B",
    width: "32%",
    overflow: "hidden",
    margin: "auto 10px auto 4px",
    // margin: "12px 4px",
  },
  headerCreatedAtTitle: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "18px",
    textTransform: "uppercase" as const,
    color: "#64748B",
    width: "15%",
    overflow: "hidden",
    margin: "12px 4px",
  },
  headerStatusTitle: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "18px",
    textTransform: "uppercase" as const,
    color: "#64748B",
    width: "17%",
    minWidth: "120px",
    overflow: "hidden",
    margin: "12px 4px",
  },
  headerTitleAction: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "18px",
    textTransform: "uppercase" as const,
    color: "#64748B",
    width: "186px",
    overflow: "hidden",
    margin: "12px 4px",
    "@media (max-width: 969px)": {
      width: "90px",
    },
  },
  customerAppCompanyNameItem: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    color: "#0F172A",
    width: "17%",
    overflow: "hidden",
    margin: "13px 4px",
    alignItems: "center",
    display: "flex",
  },
  customerAppServicesNeededItem: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    color: "#0F172A",
    width: "19%",
    overflow: "hidden",
    margin: "13px 4px",
    alignItems: "center",
    display: "flex",
  },
  customerAppCommentItem: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    color: "#0F172A",
    width: "32%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    margin: "auto 10px auto 4px",
    alignItems: "center",
    display: "inline",
    whiteSpace: "nowrap",
  },
  customerAppCreatedAtItem: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    color: "#0F172A",
    width: "15%",
    overflow: "hidden",
    margin: "13px 4px",
    alignItems: "center",
    display: "flex",
  },
  customerAppStatusItem: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    color: "#0F172A",
    minWidth: "120px",
    width: "17%",
    overflow: "hidden",
    margin: "13px 4px",
    alignItems: "center",
    display: "flex",
  },
  customerAppItemAction: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    color: "#0F172A",
    width: "214px",
    display: "flex",
    flexDirection: "row",
    overflow: "hidden",
    margin: "13px 4px",
    alignItems: "center",
    justifyContent: "space-between",
    "@media (max-width: 969px)": {
      width: "90px",
    },
  },
  cutomerAppsDataTableBody: {
    width: "calc(100% - 214px)",
    display: "flex",
    flexDirection: "row",
    padding: "0px 10px",
    "@media (max-width: 969px)": {
      width: "calc(100% - 114px)",
    }
  },
  typographyText: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "22px",
    textTransform: "none",
    "@media (max-width: 969px)": {
      display: "none"
    },
  },
  processingStyle: {
    textTransform: "uppercase" as const,
    backgroundColor: "#D1FAE5",
    color: "#059669",
    padding: "3px 8px",
    borderRadius: "40px 0px 0px 40px",
    border: "none",
    fontFamily: "'Inter', sans-serif",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "18px",
  },
  processedStyle: {
    textTransform: "uppercase" as const,
    backgroundColor: "#FEF3C7",
    color: "#D97706",
    padding: "3px 8px",
    borderRadius: "40px 0px 0px 40px",
    border: "none",
    fontFamily: "'Inter', sans-serif",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "18px",
  },
  processingItemStyle: {
    textTransform: "uppercase" as const,
    backgroundColor: "#D1FAE5",
    color: "#059669",
    padding: "3px 8px",
    borderRadius: "40px",
    border: "none",
    fontFamily: "'Inter', sans-serif",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "18px",
    margin: "10px",
    cursor: "pointer",
  },
  processedItemStyle: {
    textTransform: "uppercase" as const,
    backgroundColor: "#FEF3C7",
    color: "#D97706",
    padding: "3px 8px",
    borderRadius: "40px",
    border: "none",
    fontFamily: "'Inter', sans-serif",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "18px",
    margin: "10px",
    cursor: "pointer",
  },
  downArrowBlock: {
    backgroundColor: "#F1F5F9",
    borderRadius: "0px 40px 40px 00px",
    border: "none",
  },
  supportTitle: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "24px",
    fontWeight: 600,
    lineHeight: "30px",
    color: "#0F172A",
    alignItems: "center",
    display: "flex",
    marginTop: "20px",
  },
  customerAppCount: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fonWeight: 500,
    lineHeight: "22px",
    color: "#334155",
    marginTop: "12px"
  },
  select: {
    width: "100%",
    fontFamily: "'Inter', sans-serif",
    height: "44px",
    fontSize: "16px",
    borderRadius: "8px",
    "& .MuiSelect-select": {
      fontFamily: "'Inter', sans-serif",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#CBD5E1",
    },
  },
  selectOption: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    color: "#0F172A",
    padding: "8px 12px",
    margin: "0 8px 12px",
    borderRadius: "8px",
    "&:last-of-type": {
      margin: "0 8px",
    },
    "&.Mui-selected": {
      backgroundColor: "#E8FBFF",
      color: "#1A7BA4",
      fontWeight: "600",
    },
  },
  tabButton: (isActive: boolean) => ({
    fontWeight: isActive ? 700 : 400,
    color: isActive ? '#1A7BA4' : '#64748B',
    textTransform: 'none',
    padding: '8px 12px',
    fontFamily: "'Inter', sans-serif",
    fontSize: '16px',
    marginBottom: isActive ? "0px" : "2px",
    "@media (max-width: 500px)": {
      width: "50%",
      fontSize: '14px',
  },
  }),
  tabsContainer: {
    borderBottom: '1px solid #E2E8F0',
    margin: '0 0 10px',
    fontSize:"16px",
    fontFamily: "'Inter', sans-serif",
    '& .Mui-selected': {
      color: '#1A7BA4 !important',
    },
    '& .MuiTabs-indicator': {
      backgroundColor: '#1A7BA4 !important',
    },
    "@media (max-width: 500px)": {
      width: "100%",
    },
  },
  showButton: {
    backgroundColor: "#FFFFFF",
    color: "#1A7BA4",
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "22px",
    padding: "5px",
    borderRadius: "7px",
    border: "1px solid #1A7BA4",
    display: "flex",
    flexDirection: "row" as const,
    height: "36px",
    alignItems: "center",
    "@media (max-width: 969px)": {
      minWidth: "32px",
    }
  },
  deleteButton: {
    backgroundColor: "#FFFFFF",
    color: "#DC2626",
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "22px",
    padding: "5px",
    borderRadius: "7px",
    border: "1px solid #DC2626",
    display: "flex",
    flexDirection: "row" as const,
    height: "36px",
    alignItems: "center",
    "@media (max-width: 969px)": {
      minWidth: "32px",
    }
  },
  contactFormTitle: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    justifyContent: "space-between",
    margin: "10px 0px 20px",
  },
  contactFormCount: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fonWeight: 500,
    lineHeight: "22px",
    color: "#334155",
  },
  contactFormsTable: {
    border: "1px solid #CBD5E1",
    borderRadius: "12px",
    minWidth: "812px",
    "@media (max-width: 969px)": {
      minWidth: "600px",
    }
  },
  contactFormsTableHead: {
    display: "flex",
    flexDirection: "row",
    padding: "0px 10px",
  },
  contactFormsDataTableHead: {
    width: "calc(100% - 168px)",
    display: "flex",
    flexDirection: "row",
    padding: "0px 10px",
    "@media (max-width: 969px)": {
      width: "calc(100% - 70px)",
    }
    // "@media (max-width: 969px)": {

  },
  contactFormsDataTableBody: {
    width: "calc(100% - 168px)",
    display: "flex",
    flexDirection: "row",
    padding: "0px 10px",
    "@media (max-width: 969px)": {
      width: "calc(100% - 94px)",
    }
  },
  headerContactFormNameTitle: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "18px",
    textTransform: "uppercase" as const,
    color: "#64748B",
    width: "14%",
    overflow: "hidden",
    margin: "12px 4px",
  },
  headerContactFormServicesNeededTitle: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "18px",
    textTransform: "uppercase" as const,
    color: "#64748B",
    width: "18%",
    overflow: "hidden",
    margin: "12px 4px",
  },
  headerContactFormCommentTitle: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "18px",
    textTransform: "uppercase" as const,
    color: "#64748B",
    width: "18%",
    overflow: "hidden",
    margin: "12px 4px",
  },
  headerContactFormCreatedAtTitle: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "18px",
    textTransform: "uppercase" as const,
    color: "#64748B",
    width: "14%",
    overflow: "hidden",
    margin: "12px 4px",
  },
  headerContactFormEmailTitle: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "18px",
    textTransform: "uppercase" as const,
    color: "#64748B",
    width: "18%",
    overflow: "hidden",
    margin: "12px 4px",
  },
  headerContactFormPhoneTitle: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "18px",
    textTransform: "uppercase" as const,
    color: "#64748B",
    width: "18%",
    overflow: "hidden",
    margin: "12px 4px",
  },
  headerContactFormTitleAction: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "18px",
    textTransform: "uppercase" as const,
    color: "#64748B",
    width: "140px",
    overflow: "hidden",
    margin: "12px 4px",
    "@media (max-width: 969px)": {
      width: "70px",
    }
  },
  contactFormsTableBody: {
    display: "flex",
    flexDirection: "row",
    padding: "0px 10px",
    borderTop: "1px solid #CBD5E1",
    backgroundColor: "#FFFFFF",
    "&:last-of-type": {
      borderRadius: "0 0 12px 12px",
    },
  },
  confirmDelete: {
    padding: "6px 12px",
    borderRadius: "5px",
    textTransform: "none",
    backgroundColor: "white",
    color: "#DC2626",
    border: "1px solid #DC2626",
    fontFamily: "'Inter', sans-serif",
    fontWeight: "600",
    height: "44px",
    marginLeft: "16px !important",
    // textTransform: "none",
    "@media (max-width: 767px)": {
      width: "100%",
      margin: "10px 0px",
    }
  },
  cancelButton: {
    color: "#1A7BA4",
    border: "1px solid #1A7BA4",
    padding: "6px 12px",
    borderRadius: "5px",
    textTransform: "none",
    fontWeight: "700",
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    height: "44px",
    "@media (max-width: 767px)": {
      width: "100%",
      margin: "10px 0px",
    }
  },
  dialogTitleText: {
    fontWeight: "700",
    fontSize: "24px",
    fontFamily: "'Inter', sans-serif",
    // whiteSpace:"nowrap"
    "@media (max-width: 767px)": {
      fontSize:'17px',
    }
  },
  dialogTitle: { 
    // fontWeight: "600", 
    // paddingBottom: "8px",
    // fontFamily:"'Inter', sans-serif", 
    // fontSize:'20px',
    fontWeight: "600",
    padding: "0 0 24px",
    fontFamily: "'Inter', sans-serif",
    fontSize: "24px",
    letterSpacing: "-1px",
    // whiteSpace:"nowrap"
    "@media (max-width: 767px)": {
      fontSize:'17px',
    }
  },
  dialogContent: { 
    // marginBottom: "32px", 
    // padding: 0,
    color: "#64748B", 
    paddingBottom: "16px",
    fontFamily:"'Inter', sans-serif",
    fontSize:"18px",
    "@media (max-width: 767px)": {
      fontSize:"16px",
    }
  },
  dialogAction: {
     padding: "16px",
     "@media (max-width: 767px)": {
      flexDirection: "column",
    },
  },
  contactFormNameItem: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    color: "#0F172A",
    width: "14%",
    overflow: "hidden",
    margin: "13px 4px",
    alignItems: "center",
    display: "flex",
  },
  contactFormServicesNeededItem: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    color: "#0F172A",
    width: "18%",
    overflow: "hidden",
    margin: "13px 4px",
    alignItems: "center",
    display: "flex",
  },
  contactFormCommentItem: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    color: "#0F172A",
    width: "18%",
    // width: "calc(18% - 36px)",
    overflow: "hidden",
    textOverflow: "ellipsis",
    margin: "auto 10px auto 4px",
    alignItems: "center",
    display: "inline",
    whiteSpace: "nowrap",
  },
  contactFormCreatedAtItem: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    color: "#0F172A",
    width: "14%",
    overflow: "hidden",
    margin: "13px 4px",
    alignItems: "center",
    display: "flex",
  },
  contactFormEmailItem: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    color: "#0F172A",
    width: "18%",
    overflow: "hidden",
    margin: "13px 4px",
    alignItems: "center",
    display: "flex",
  },
  contactFormPhoneItem: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    color: "#0F172A",
    width: "18%",
    overflow: "hidden",
    margin: "13px 4px",
    alignItems: "center",
    display: "flex",
  },
  contactFormItemAction: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    color: "#0F172A",
    display: "flex",
    flexDirection: "row",
    overflow: "hidden",
    margin: "13px 4px",
    alignItems: "center",
    justifyContent: "space-between",
  },
  modalBox: {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50vw",
    height: "auto",
    maxHeight: "80vh",
    backgroundColor: "#FFFFFF",
    border: "none",
    borderRadius: "18px",
    boxShadow: 24,
    p: 4,
    display: "flex",
    flexDirection: "column",
    "@media (max-width: 767px)": {
      width: "80vw",
    }
  },
  modalScrollBox: {
    mb: 2,
    display: "flex",
    flexDirection: "column",
    overflow: "hidden auto",
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "32px",
    textAlign: "left",
    flex: 1,
    color: "0F172A",
    marginTop: "20px",
  },
  modalTitleBlock: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: "20px",
    marginTop: "-12px",
    borderBottom: "1px solid #CBD5E1",
  },
  modalTitle: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "24px",
    fontWeight: 600,
    lineHeight: "30px",
    color: "#0F172A",
    "@media (max-width: 767px)": {
      fontSize: "20px",
    }
  },
  modalCloseButton: {
    color: "#64748B",
    border: "none",
    backgroundColor: "#F8FAFC",
    borderRadius: '100%',
    width: '56px',
    height: '56px',
    cursor: 'pointer',
  },
  modalDoubleBlock: {
    display: "flex",
    flexDirection: "row",
    "@media (max-width: 969px)": {
      flexDirection: "column",
    },
  },
  modalBlock: {
    display: "flex",
    margin: "8px 0px",
    flexDirection: "column",
  },
  modalBlockLabel: {
    margin: "0px 0px 10px",
    textTransform: "uppercase" as const,
    fontFamily: "'Inter', sans-serif",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "18px",
    color: "#64748B",
    "@media (max-width: 767px)": {
      fontSize: "10px",
      lineHeight: "16px",
    }
  },
  modalBlockValue: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "30px",
    color: "#0F172A",
    margin: "4px 0px",
    "@media (max-width: 767px)": {
      fontSize: "14px",
      lineHeight: "24px",
    }
  },
  statusComponent: {
    display: "flex",
    flexDirection: "row",
    borderRadius: "40px",
    cursor: "pointer",
  },
  shadowComponent: {
    display: "flex",
    flexDirection: "row",
    borderRadius: "40px",
    boxShadow: "0px 4px 4px 0px #00000040",
    cursor: "pointer",
  },
  statusModal: {
    borderRadius: "12px",
    backgroundColor: "#FFFFFF",
    border: "1px solid #CBD5E1",
    boxShadow: "0px 25px 50px 0px #00000017",
    position: "absolute",
    marginTop: "120px",
    marginLeft: "-10px",
  },
  chevronIconActive: {
    "> path": {
      fill: "#475569",
    },
  },
  chevronIconDisabled: {
    "> path": {
      fill: "#64748B",
    },
  },
  statusModalBackground: {
    position: "fixed",
    top: "0px",
    width: "100%",
    height: "100%",
    left: "0px",
  },
  snackbar: {
    fontFamily: "'Inter', sans-serif",
    "& .MuiPaper-root": {
      backgroundColor: '#FFFFFF',
      fontFamily: "'Inter', sans-serif !important",
      padding: '8px 16px 8px 8px',
      boxShadow: '0px 6px 15px -3px #00000026',
      borderRadius: '8px',
      minWidth: 'auto',
      "& .MuiSnackbarContent-message": {
        padding: 0,
      }
    },
    "@media (min-width: 1037px)": {
      left: '50% !important',
      transform: 'translateX(-50%)',
    },
  },
  snackbarContent: {
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
  },
  snackbarIcon: {
    width: '26px',
    height: '26px',
    '> path': {
      fill: '#F87171',
    }
  },
  snackbarSuccessIcon: {
    "> path": {
      fill: "#34D399",
    },
  },
  snackbarText: {
    fontFamily: "'Inter', sans-serif",
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    color: '#0F172A',
  }
};
// Customizable Area End
