import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface Reports {
  report_id: string;
  property_name: string;
  property_id: string;
  generated_at: string;
  meter_type: "Water" | "Electric";
  water_sub_type: 'Cold' | 'Hot' | null;
  payment_status: ReportPaymentStatus;
  company_detail_id: number;
  report_sent?: boolean;
  invoice_sent?: boolean;
}

export enum ReportPaymentStatus {
  PAID = 'paid',
  PENDING = 'pending',
  NO_INVOICE = 'no_invoice',
  PAST_DUE = 'past_due',
  PAST_DUE_30 = 'past_due_30',
  PAST_DUE_60 = 'past_due_60',
  PAST_DUE_90 = 'past_due_90',
  PAST_DUE_120 = 'past_due_120',
}

interface S {
  reportList: Reports[];
  // Customizable Area Start
  token: string,
  reportsListLoading: boolean,
  searchReportText: string,
  currentPage: number,
  itemsPerPage: number,
  filteredReportList: Reports[],
  currentReports: Reports[],
  totalPages: number,
  firstItemIndex: number,
  lastItemIdex: number,
  pdfReportId: string | null,
  pdfInvoiceId: string | null
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ReportsPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  apiGetReportListApiCallId: string;
  apiGetCompanyDetailApiCallId: string;
  apiGetPdfCallId: string;
  apiGetInvoicePdfCallId: string;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.apiGetReportListApiCallId = "";
    this.apiGetCompanyDetailApiCallId = "";
    this.apiGetPdfCallId = "";
    this.apiGetInvoicePdfCallId = "";

    this.state = {
      token: "",
      reportList: [],
      reportsListLoading: false,
      searchReportText: "",
      currentPage: 1,
      itemsPerPage: 10,
      filteredReportList: [],
      totalPages: 0,
      firstItemIndex: 1,
      lastItemIdex: 10,
      currentReports: [],
      pdfReportId: null,
      pdfInvoiceId: null
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId != null) {
        if (apiRequestCallId === this.apiGetCompanyDetailApiCallId) {
          if (responseJson && responseJson.data) {
            this.getReportList(responseJson.data.id);
          } else if (responseJson && responseJson.errors) {
            const errorsMsg = responseJson.errors.map(
              (error: any) => Object.values(error)[0]
            );
            if (errorsMsg.includes("Invalid token")) {
              this.goToLogInPage();
            }
          } else{
            //Check Error Response
            this.parseApiErrorResponse(responseJson);
          }
 
          this.parseApiCatchErrorResponse(errorReponse);
        }
        if (apiRequestCallId === this.apiGetReportListApiCallId) {
          if (responseJson && responseJson.data) {
            this.setState({ reportList: responseJson.data.map(({ attributes }: any) => attributes), reportsListLoading: false });
            await Promise.all([this.updatePagination()]);
          } else if (responseJson && responseJson.errors) {
            const errorsMsg = responseJson.errors.map(
              (error: any) => Object.values(error)[0]
            );
            if (errorsMsg.includes("Invalid token")) {
              this.goToLogInPage();
            }
          } else{
            //Check Error Response
            this.parseApiErrorResponse(responseJson);
          }
 
          this.parseApiCatchErrorResponse(errorReponse);
        }
        if (apiRequestCallId === this.apiGetPdfCallId) {
          if (responseJson && responseJson?.pdf_url) {
            this.setState({ pdfReportId: null});
            window.open(responseJson.pdf_url, "_blank");
          } else if (responseJson && responseJson.errors) {
            const errorsMsg = responseJson.errors.map(
              (error: any) => Object.values(error)[0]
            );
            if (errorsMsg.includes("Invalid token")) {
              this.goToLogInPage();
            }
          } else{
            //Check Error Response
            this.parseApiErrorResponse(responseJson);
          }
 
          this.parseApiCatchErrorResponse(errorReponse);
        }
        if (apiRequestCallId === this.apiGetInvoicePdfCallId) {
          if (responseJson && responseJson?.pdf_url) {
            this.setState({ pdfInvoiceId: null });
            window.open(responseJson.pdf_url, "_blank");
          } else if (responseJson && responseJson.errors) {
            const errorsMsg = responseJson.errors.map(
              (error: any) => Object.values(error)[0]
            );
            if (errorsMsg.includes("Invalid token")) {
              this.goToLogInPage();
            }
          } else{
            //Check Error Response
            this.parseApiErrorResponse(responseJson);
          }
 
          this.parseApiCatchErrorResponse(errorReponse);
        }
      }
    }
    // Customizable Area End
  }

  handleNextPage = () => {
    if (this.state.currentPage < Math.ceil(this.state.reportList.length / this.state.itemsPerPage)) {
      this.setState({ currentPage: this.state.currentPage + 1 });
    }
  };

  handlePrevPage = () => {
    if (this.state.currentPage > 1) {
      this.setState({ currentPage: this.state.currentPage - 1 });
    }
  };

  handlePageChange = (pageNumber: number) => {
    this.setState({ currentPage: pageNumber });
  };

  goToLogInPage = () => {
    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    message.addData(getName(MessageEnum.NavigationTargetMessage), "LogInPage");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };

  getReportList = async (companyDetailId: string) => {
    const header = {
      "Content-Type": configJSON.reportsApiContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetReportListApiCallId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.reportsAPiEndPoint + "?company_detail_id=" + companyDetailId
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.reportsAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getPdfUrl = (reportId: string) => {
    const header = {
      "Content-Type": configJSON.reportsApiContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.setState({ pdfReportId: reportId });
    
    this.apiGetPdfCallId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.reportsAPiEndPoint + `/${reportId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.reportsAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getInvoicePdfUrl = (invoiceId: string) => {
    const header = {
      "Content-Type": configJSON.invoicesApiContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.setState({ pdfInvoiceId: invoiceId });
    
    this.apiGetInvoicePdfCallId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.invoicesAPiEndPoint + `/${invoiceId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.invoicesAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getCompanyDetail = async () => {
    this.setState({reportsListLoading: true})
    const header = {
      "Content-Type": configJSON.companyDetailApiContentType,
      token: this.state.token,
    };

    console.log("GetCompanyDetail");

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetCompanyDetailApiCallId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.companyDetailAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.companyDetailAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  searchReports = (search: string) => {
    this.setState({ searchReportText: search, currentPage: 1 });
  };

  downloadReport = (id: string) => {
    console.log({ id });
  };

  downloadInvoice = (id: string) => {
    console.log({ id });
  };

  // Customizable Area Start
  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  getToken = async () => {
    let token = await getStorageData("token");
    this.setState({ token: token });
  };

  async componentDidUpdate(prevProps: Props, prevState: S): Promise < void> {
      if (    
        prevState.currentPage !== this.state.currentPage ||
        prevState.itemsPerPage !== this.state.itemsPerPage ||
        prevState.reportList !== this.state.reportList ||
        prevState.searchReportText !== this.state.searchReportText
        ) {
        await Promise.all([ this.updatePagination()]);
      }
    }

    updatePagination = () => {
      const { currentPage, itemsPerPage, reportList, searchReportText } = this.state;
      let indexOfLastItem = currentPage * itemsPerPage;
      const indexOfFirstItem = indexOfLastItem - itemsPerPage;
      const filteredReportList = searchReportText ? reportList.filter((report) => report.report_id?.toLowerCase().includes(searchReportText.toLowerCase())) : reportList;
      const currentReports = filteredReportList.slice(indexOfFirstItem, indexOfLastItem);
      const totalPages = Math.ceil(filteredReportList.length / itemsPerPage);
  
      if (indexOfLastItem > filteredReportList.length ) {
          indexOfLastItem = filteredReportList.length;
      }
  
      this.setState({
        currentReports,
        filteredReportList: filteredReportList,
        totalPages,
        firstItemIndex:indexOfFirstItem,
        lastItemIdex:indexOfLastItem,
      });
    };

  componentDidMount = async () => {
    const userRole = await getStorageData('role');
    const userCompletedSetup = await getStorageData('completed_setup');

    await this.getToken();
    await this.getCompanyDetail();

    if (userRole !== 'user' || userCompletedSetup === 'false') {
      const message: Message = new Message(getName(MessageEnum.NavigationMessage));
      message.addData(
        getName(MessageEnum.NavigationTargetMessage),
        userRole !== 'user' ? 'LogInPage' : 'SettingsPage'
      );
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(message);
    }
  }
  // Customizable Area End
}
