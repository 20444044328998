import React from 'react';
import { Typography, Box, Modal, IconButton, Badge } from '@mui/material';
import AccountController, { Notification, Props } from './AccountController';
import moment from 'moment';
export const configJSON = require("../../blocks/landingpage/src/config");
export const notificatiionIcon = require("./notificatiion.svg");
export const reeadingIcon = require("./reeading.svg");
export const noNotificationIcon = require("./empty.svg");

export default class NotificationBlock extends AccountController {

  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <>
        <Box sx={styles.notificationBlock}>
          <IconButton
            sx={styles.notificationButton}
            onClick={() => {
              this.setNotificationVisible(!this.state.showNotification)
            }}
          >
            <Badge badgeContent={this.state.unreadNotificationCount} color="error">
              <img src={notificatiionIcon} alt="Logo" style={{ height: '24px' }}/>
            </Badge>
          </IconButton>
        </Box>
        <Modal
          open={!!this.state.showNotification}
          onClose={() => {
            this.setNotificationVisible(false);
          }}
          sx={styles.modalBlock}
        >
          {
            this.state.notificationList.length === 0 ? (
              <Box sx={styles.notificationConatiner}>
                <Box sx={styles.noNotificationBlockTitle}>
                  <img src={noNotificationIcon} style={styles.mainImage} />
                  <Typography sx={styles.noNotificationTitle}>
                    No notifications
                  </Typography>
                </Box>
              </Box>
            ): (
              <Box sx={styles.notificationConatiner}>
                {this.state.notificationList.map((notification: Notification, index: number) => (
                  <Box 
                    onClick={() => {
                      this.readNotification(notification.id)
                    } } 
                    sx={index === 0 ? styles.firstNotificationMessage : styles.notificationMessage} 
                    key={index}
                  >
                    <Box sx={styles.notificationMessageTitle}>
                      <Box sx={styles.notificationMessageTitleBlock}>
                        <Typography sx={styles.notificationMessageTitleText}>{notification.headings}</Typography>
                        {!notification.is_read && (
                          <img src={reeadingIcon} alt="reading" style={styles.notificationReadingIcon} />
                        )}
                      </Box>
                      <Typography sx={styles.notificationMessageDescription}>{notification.contents}</Typography>
                    </Box>
                    <Box sx={styles.notificationMessageDate}>
                      <Typography sx={styles.notificationMessageDateTitle}>{moment(notification.created_at).format("M/D/YYYY, h:mm A")}</Typography>
                    </Box>
                  </Box>
                ))}
              </Box>
            )
          }
          
        </Modal>
      </>
    );
  }
}
const styles = {
  noNotificationBlockTitle: {
    margin: "80px 10px",
    textAlign: "center",
  },
  noNotificationTitle: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: "32px",
    marginBottom: "15px",
    marginTop: "50px",
  },
  mainImage: {
    width: "163px",
    height: "154px",
  },
  notificationBlock: {
    // position: "relative",
    height: "32px",
    width: "32px",
    margin: "16px",
  },
  notificationButton: {
    height: "32px",
    width: "32px",
  },
  modalBlock: {
    "& .MuiBackdrop-root": {
      opacity: "0 !important",
    },
  }, 
  notificationConatiner: {
    boxShadow: "0px 2px 8px 0px #00000014",
    zIndex: '10',
    borderRadius: "12px",
    border: "1px solid #CBD5E1",
    backgroundColor: "#FFFFFF",
    width: "340px",
    height: "552px",
    overflowX: "auto",
    right: { xs: "auto", sm: "100px" },
    left: { xs: "50%", sm: "auto" },
    transform: { xs: "translateX(-50%)", sm: "none" },
    top: "60px",
    position: "absolute",
    padding: 0,
  },
  notificationMessage: {
    padding: "12px 16px",
    borderTop: "1px solid #CBD5E1",
    display: "flex",
    flexDirection: "column",
  },
  firstNotificationMessage: {
    padding: "12px 16px",
    display: "flex",
    flexDirection: "column",
  },
  notificationMessageTitle: {
    marginBottom: "8px",
    display: "flex",
    flexDirection: "column",
  },
  notificationMessageTitleBlock: {
    marginBottom: "2px",
    display: "flex",
    flexDirection: "row",
    height: "24px",
  },
  notificationMessageTitleText: {
    textAlign: "left",
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    color: "#0F172A",
  },
  notificationReadingIcon: {
    height: '8px', 
    margin: "8px",
  },
  notificationMessageDescription: {
    textAlign: "left",
    display: "flex",
    flexDirection: "row",
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#64748B",
    wordBreak: "break-word",
  },
  notificationMessageDate: {
    display: "flex",
    flexDirection: "row-reverse",
  },
  notificationMessageDateTitle: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    color: "#64748B",
  },
};
