import React, {useEffect, useState } from 'react';
import { 
  Box, 
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Divider,
  TextField, 
  IconButton, 
  InputAdornment,
  Tooltip,
  CircularProgress,
  InputBase,
  Typography,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Switch, { SwitchProps } from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/DeleteOutlineOutlined";
import {
  searchIcon,
  addIcon,
  editIcon,
  deleteIcon,
  noPropertieisIcon,
  errorIcon,
  removeIcon
} from "./assets";
import { styled } from "@mui/material/styles";
import * as Yup from "yup";
import { Formik } from "formik";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import KeyboardArrowLeftRoundedIcon from "@mui/icons-material/KeyboardArrowLeftRounded";
import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import CloseRoundedIcon from '@mui/icons-material/Close';
import { Property } from './CustomersPageController';
import { CustomerEditableTextField } from './CustomerEditableTextField.web';

  export interface Props {
    companyId: string;
    getPropertiesList: (companyId: string) => void;
    propertiesList: Property[];
    propertiesLoading: boolean;
    addTenant: (property: Property) => void;
    addEditCompanyProperty: (property: Property) => void;
    addEditProperyDialog: PropertyDialogProps | null;
    setAddEditPropertyDialog: (dialogProperties: PropertyDialogProps | null) => void;
    deleteCompanyProperty: (propertyId: string) => void;
    deleteCompanyPropertyIdDialog: string | null;
    setDeleteCompanyPropertyIdDialog: (propertyId: string | null) => void;
    assignBookNumberToProperty: (propertyId: string, bookNumber: number | null) => void;
    isTest?: boolean;
  }

  interface PropertyDialogProps {
    isAdd?: boolean;
    initialProperty?: Property;
  }

  const itemsPerPage = 10;

  export const trimSpaces = (value: string) => value.trim().replace(/\s+/g, ' ');

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];

  export const CustomerProperties = ({getPropertiesList, companyId, propertiesList, propertiesLoading, addEditCompanyProperty, addTenant, addEditProperyDialog, setAddEditPropertyDialog, deleteCompanyProperty, deleteCompanyPropertyIdDialog, setDeleteCompanyPropertyIdDialog, assignBookNumberToProperty, isTest}:Props) => {
    const [validate , setValidate ] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchText, setSearchText] = useState('');
    const [getPropertiesListRequestFired, setPropertiesListRequestFired] = useState(false);

    const filteredPropertiesList = searchText ? propertiesList.filter((property) => property.property_name?.toLowerCase().includes(searchText.toLowerCase())) : propertiesList;
    const totalPages = Math.ceil(filteredPropertiesList.length / itemsPerPage) || 1;
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const displayedProperties = filteredPropertiesList.slice(indexOfFirstItem, indexOfLastItem);

    useEffect(() => {
      if (companyId) {
        getPropertiesList(companyId);
        setPropertiesListRequestFired(true);
      }
    }, [companyId]);

    useEffect(() => {
      if (displayedProperties.length === 0 && currentPage !== 1) {
        setCurrentPage(1);
      }
    }, [filteredPropertiesList, currentPage]);

    const handleNextPage = () => {
      if (currentPage < Math.ceil(filteredPropertiesList.length / itemsPerPage)) {
        setCurrentPage(currentPage + 1);
      }
    };

    const handlePrevPage = () => {
      if (currentPage > 1) {
        setCurrentPage(currentPage - 1);
      }
    };

    const handlePageChange = (page: number) => {
      setCurrentPage(page);
    }

   const questionSchema = () => {
      return Yup.object().shape({
        property_name: Yup.string().transform((value: string) => trimSpaces(value)).required("Property name is required"),
        service_address: Yup.string().transform((value: string) => trimSpaces(value)).required("Service address is required"),
        meter_location: Yup.string().transform((value: string) => trimSpaces(value)).required("Meter location is required"),
        building_manager: Yup.string().transform((value: string) => trimSpaces(value)).required("Building manager is required"),
        phone_number: Yup.string()
          .matches(/^[0-9]+$/, "Must be only digits")
          .min(8, "Must be more then 8 digits")
          .max(12, "Must be less then 12 digits")
          .required("Phone Number is required"),
        email: Yup.string()
          .email("Invalid email format")
          .required("Email is required"),
        of_meaters_to_read: Yup.number().required("# of meters to read is required"),
      });
    };

    const closeAddEditDialog = () => {
      setValidate(false);
      setAddEditPropertyDialog(null);
    };

    if (propertiesLoading || (!getPropertiesListRequestFired && !isTest)) {
      return (
        <Box sx={webStyle.loaderWrapper} data-test-id="loaderWrapper">
          <CircularProgress sx={webStyle.loader} />
        </Box>
      );
    }

    return (
      <Box sx={webStyle.propertiesContainer}>
      {propertiesList.length === 0 ? (
        <Box sx={webStyle.noPropertyBlock}>
          <Box sx={webStyle.noPropertyBlockTitle}>
            <img src={noPropertieisIcon} style={webStyle.mainImage} />
            <Typography sx={webStyle.noPropertyTitle}>No properties</Typography>
            <Typography sx={webStyle.noPropertySubTitle}>
            Click on the button below to start adding your properties
            </Typography>
            <Box sx={webStyle.buttonBlock}>
              <Button
                sx={webStyle.addNewButton}
                data-test-id="addButton"
                // type="button"
                onClick={() => setAddEditPropertyDialog({ isAdd: true })}
              >
                <Typography sx={webStyle.buttonText}>Add property</Typography>
              </Button>
            </Box>
          </Box>
        </Box>
      ) : ( 
        <>
        <Box sx={webStyle.propertiesTableHeader}>
          <Box sx={webStyle.searchContainer}>
            <Box sx={webStyle.fieldControl}>
              <StyledSearchInput
                data-test-id="search"
                type={"text"}
                placeholder= "Search"
                onChange={(event) => {
                  setSearchText(event.target.value);
                  currentPage !== 1 && setCurrentPage(1);
                }}
              />
              <img
                src={searchIcon}
                alt=""
                style={webStyle.searchIcon}
                />
            </Box>
          </Box>
          <Box sx={webStyle.actionContainer}>
            <Button sx={webStyle.addPropertiesButton}
              onClick={() => setAddEditPropertyDialog({ isAdd: true })} data-test-id="addButton"
             >
              <img
                src={addIcon}
                alt=""
                style={webStyle.btnIcon}
              />
              <Typography sx={webStyle.buttonText}>Add property</Typography>
            </Button>
          </Box>
        </Box>
        <Box>
        <Typography fontFamily={"'Inter', sans-serif"} fontSize={"16px"} color={"#334155"} fontWeight={500} marginBottom={"16px"}>{filteredPropertiesList.length} results</Typography>
        </Box>
        {filteredPropertiesList.length > 0 && (
          <Box sx={webStyle.tableWrapper(totalPages)}>
            <Box sx={webStyle.propertisTable}>
              <Box sx={webStyle.propertisTableHead}>
                <Box sx={webStyle.propertisDataTableHead}>
                  <Typography sx={webStyle.headerTitle}>Property Name</Typography>
                  <Typography sx={webStyle.headerShortTitle}>Book #</Typography>
                  <Typography sx={webStyle.headerTitle}>Address</Typography>
                  <Typography sx={webStyle.headerShortTitle}># of Tenants</Typography>
                  <Typography sx={webStyle.headerShortTitle}># of Meters</Typography>
                </Box>
                <Typography sx={webStyle.headerTitleAction}>Actions</Typography>
              </Box>
              {displayedProperties.map((property: Property) => (
                <Box sx={webStyle.propertisTableBody} key={property.id}>
                  <Box sx={webStyle.propertisDataTableBody}>
                    <Box sx={webStyle.propertyItem}>{property.property_name}</Box>
                    <Box sx={webStyle.propertyShortItem}>
                      <CustomerEditableTextField noRevert initialValue={property.book_number?.toString() || ''} onChange={(value) => assignBookNumberToProperty(property.id, value === '' ? null : Number(value))} allowWholeNumericOnly emptyValue='N/A' />
                    </Box>
                    <Box sx={webStyle.propertyItem}>{property.service_address}</Box>
                    <Box sx={webStyle.propertyShortItem}>{property.of_tenants}</Box>
                    <Box sx={webStyle.propertyShortItem}>{property.of_meters}</Box>
                  </Box>
                  <Box sx={webStyle.propertyItemAction}>
                    <Button data-test-id="addTenant" style={{flex: 2}} sx={webStyle.addTenantButton} 
                      onClick={() => addTenant(property) }
                    >
                      <VisibilityOutlinedIcon sx={{ ...webStyle.actionButton, color: '#FFFFFF' }} />
                      <Typography sx={webStyle.typographyText}>View details</Typography>
                    </Button>
                    <Button style={{flex: 1}} sx={webStyle.editButton}
                      onClick={() => setAddEditPropertyDialog({ initialProperty: property })}
                    >
                      <img
                        src={editIcon}
                        alt=""
                        style={webStyle.actionButton}
                      />
                      <Typography sx={webStyle.typographyText}>Edit</Typography>
                    </Button>
                    <Button sx={webStyle.deleteButton} 
                      onClick={() => setDeleteCompanyPropertyIdDialog(property.id)} data-test-id="deleteButton"
                    >
                      <img
                        src={deleteIcon}
                        alt=""
                        style={webStyle.actionButton}
                      />
                      <Typography sx={webStyle.typographyText}>Delete</Typography>
                    </Button>
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        )}
        {totalPages > 1 && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: "20px",
                  marginBottom: "5%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexGrow: 1,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {/* Previous Button */}
                  <button
                    onClick={handlePrevPage}
                    data-test-id="previous-button"
                    style={{
                      backgroundColor: "inherit",
                      border: "none",
                      cursor: "pointer",
                      padding: 0,
                      marginRight: "4px",
                    }}
                    disabled={currentPage === 1}
                  >
                    <KeyboardArrowLeftRoundedIcon
                      sx={
                        currentPage === 1
                          ? webStyle.chevronIconDisabled
                          : webStyle.chevronIconActive
                      }
                    />
                  </button>

                  {totalPages <= 3 ? (
                    [...Array(totalPages).keys()].map((page) => (
                      <button
                        key={page + 1}
                        data-test-id={"page-button"}
                        style={{
                          marginRight: "4px",
                          width:
                            currentPage === page + 1
                              ? "24px"
                              : "16px",
                          height: "24px",
                          backgroundColor:
                            currentPage === page + 1
                              ? "#DBF5FF"
                              : "inherit",
                          color:
                            currentPage === page + 1
                              ? "#1A7BA4"
                              : "#64748B",
                          border: "none",
                          textDecoration:
                            currentPage !== page + 1
                              ? "underline"
                              : "none",
                          borderRadius: "8px",
                          fontWeight:
                            currentPage !== page + 1 ? 400 : 700,
                          cursor: "pointer",
                          fontSize: "12px",
                          fontFamily: "'Inter', sans-serif",
                        }}
                        onClick={() => handlePageChange(page + 1)}
                      >
                        {page + 1}
                      </button>
                    ))
                  ) : (
                    <>
                      {currentPage !== 1 && (
                        <button
                          data-test-id="ellipsis"
                          style={{
                            marginRight: "4px",
                            width: "16px",
                            height: "24px",
                            backgroundColor: "inherit",
                            color: "#64748B",
                            border: "none",
                            textDecoration: "underline",
                            borderRadius: "8px",
                            cursor: "pointer",
                            fontSize: "12px",
                            fontFamily: "'Inter', sans-serif",
                          }}
                          onClick={() => handlePageChange(1)}
                        >
                          1
                        </button>
                      )}

                      {currentPage > 3 && (
                        <span style={{ marginRight: "4px", color: "#64748B" }}>
                          ...
                        </span>
                      )}

                      {currentPage > 2 && (
                        <button
                          data-test-id="middle-page"
                          style={{
                            marginRight: "4px",
                            width: "24px",
                            height: "24px",
                            backgroundColor: "inherit",
                            color: "#64748B",
                            border: "none",
                            textDecoration: "underline",
                            borderRadius: "8px",
                            cursor: "pointer",
                            fontSize: "12px",
                            fontFamily: "'Inter', sans-serif",
                          }}
                          onClick={() =>
                            handlePageChange(currentPage - 1)
                          }
                        >
                          {currentPage - 1}
                        </button>
                      )}
                      <button
                        data-test-id="middle-page2"
                        style={{
                          marginRight: "4px",
                          width: "24px",
                          height: "24px",
                          backgroundColor: "#DBF5FF",
                          color: "#1A7BA4",
                          border: "none",
                          borderRadius: "8px",
                          cursor: "pointer",
                          fontWeight: 700,
                          fontSize: "12px",
                          fontFamily: "'Inter', sans-serif",
                        }}
                        onClick={() =>
                          handlePageChange(currentPage)
                        }
                      >
                        {currentPage}
                      </button>

                      {currentPage < totalPages - 1 && (
                        <button
                          data-test-id="middle-page3"
                          style={{
                            marginRight: "4px",
                            width: "16px",
                            height: "24px",
                            color: "#64748B",
                            backgroundColor: "inherit",
                            border: "none",
                            textDecoration: "underline",
                            borderRadius: "8px",
                            cursor: "pointer",
                            fontSize: "12px",
                            fontFamily: "'Inter', sans-serif",
                          }}
                          onClick={() =>
                            handlePageChange(currentPage + 1)
                          }
                        >
                          {currentPage + 1}
                        </button>
                      )}

                      {currentPage < totalPages - 2 && (
                        <span style={{ marginRight: "4px", color: "#64748B" }}>
                          ...
                        </span>
                      )}

                      {currentPage !== totalPages && (
                        <button
                          data-test-id="last-page"
                          style={{
                            marginRight: "4px",
                            width:
                              currentPage === totalPages
                                ? "24px"
                                : "16px",
                            height: "24px",
                            backgroundColor:
                              currentPage === totalPages
                                ? "#DBF5FF"
                                : "inherit",
                            color:
                              currentPage === totalPages
                                ? "#1A7BA4"
                                : "#64748B",
                            border: "none",
                            textDecoration:
                              currentPage !== totalPages
                                ? "underline"
                                : "none",
                            borderRadius: "8px",
                            cursor: "pointer",
                            fontSize: "12px",
                            fontFamily: "'Inter', sans-serif",
                            fontWeight:
                              currentPage !== totalPages
                                ? 400
                                : 700,
                          }}
                          onClick={() =>
                            handlePageChange(totalPages)
                          }
                        >
                          {totalPages}
                        </button>
                      )}
                    </>
                  )}

                  <button
                    data-test-id="next-page"
                    onClick={() => handleNextPage()}
                    style={{
                      backgroundColor: "inherit",
                      border: "none",
                      cursor: "pointer",
                      padding: 0,
                    }}
                    disabled={currentPage === totalPages}
                  >
                    <KeyboardArrowRightRoundedIcon
                      sx={
                        currentPage === totalPages
                          ? webStyle.chevronIconDisabled
                          : webStyle.chevronIconActive
                      }
                    />
                  </button>
                </Box>

                <Box sx={{ marginRight: "10px" }}>
                  <Typography
                    fontFamily={'"Nunito Sans", sans-serif'}
                    fontSize={"12px"}
                    color={"grey"}
                  >
                    {indexOfFirstItem + 1}-{indexOfLastItem} of{" "}
                    {filteredPropertiesList.length} results
                  </Typography>
                </Box>
              </Box>
            )}
      </>
      )}
      <Dialog
        open={!!addEditProperyDialog}
        onClose={closeAddEditDialog}
        maxWidth="sm" 
        fullWidth 
        PaperProps={{
          style: {
            borderRadius: '8px',
            padding: '32px',
            maxHeight: 'calc(100% - 120px)',
          },
        }}
      >
        <DialogTitle style={{ padding: '0 0 32px', position: 'relative' }}>
          <Typography variant="h6" sx={webStyle.dialogTitleText}>
            {`${addEditProperyDialog?.isAdd ? 'Add' : 'Edit'} property`}
          </Typography>
          <IconButton
            onClick={closeAddEditDialog}
            style={{ position: 'absolute', right: '0', top: '-8px', width: '56px', height: '56px', backgroundColor: '#F8FAFC', borderRadius: '52px' }}
          >
            <CloseRoundedIcon sx={{ width: '24px', height: '24px', color: '#64748B' }} />
          </IconButton>
        </DialogTitle>
        <Divider sx={{ marginBottom: '22px' }} />
        <DialogContent sx={{ padding: 0, overflowY: 'unset' }}>
        <Box sx={webStyle.addPropertiesContainer}>
        <Box sx={webStyle.addPropertiesBlock}>
          <Box sx={webStyle.addPropertyFormContainer}>
            <Formik
              initialValues={{
                id: addEditProperyDialog?.initialProperty?.id || "",
                property_name: addEditProperyDialog?.initialProperty?.property_name || "",
                service_address: addEditProperyDialog?.initialProperty?.service_address || "",
                meter_location: addEditProperyDialog?.initialProperty?.meter_location || "",
                building_manager: addEditProperyDialog?.initialProperty?.building_manager || "",
                phone_number: addEditProperyDialog?.initialProperty?.phone_number || "",
                email: addEditProperyDialog?.initialProperty?.email || "",
                of_meaters_to_read: addEditProperyDialog?.initialProperty?.of_meaters_to_read || 1,
                reading_period:  addEditProperyDialog?.initialProperty?.reading_period || 'monthly',
                start_reading_from:  addEditProperyDialog?.initialProperty?.start_reading_from || months[new Date().getMonth()],
                reportTo: "",
                getInvoice: true,
                addServiceCharge: true,
              }}
              validateOnChange={true}
              validationSchema={questionSchema}
              onSubmit={(values: Property) => {
                setValidate(false);
                addEditCompanyProperty({
                  ...values,
                  of_meaters_to_read: Number(values.of_meaters_to_read),
                  property_name: trimSpaces(values?.property_name || ''),
                  service_address: trimSpaces(values?.service_address || ''),
                  meter_location: trimSpaces(values?.meter_location || ''),
                  building_manager: trimSpaces(values?.building_manager || ''),
                });
              }}
              data-test-id="addPropertyForm"
            >
              {({ errors, values, setFieldValue, handleSubmit }) => (
                <form style={{ margin: 0 }}>
                  <Box sx={webStyle.formContainer}>
                    <Box sx={webStyle.fieldControl1}>
                      <Typography sx={webStyle.inputLabel}>
                        Property name
                      </Typography>
                      <input
                        style={{...webStyle.inputElemnt1, ...(validate && errors.property_name ? webStyle.inputElementError : {})}}
                        data-test-id="property_name"
                        type="text"
                        value={values?.property_name || ''}
                        onChange={(event) => {
                          setFieldValue("property_name", event.target.value);
                        }}
                      />
                      {validate && errors.property_name && (
                        <Tooltip
                          open={Boolean(errors.property_name)}
                          title={errors.property_name}
                          componentsProps={tooltipStyles}
                          arrow
                        >
                          <img
                            src={errorIcon}
                            alt=""
                            style={webStyle.errorIcon}
                          />
                        </Tooltip>
                      )}
                    </Box>
                    <Box sx={webStyle.fieldControl1}>
                      <Typography sx={webStyle.inputLabel}>
                        Service address
                      </Typography>
                      <input
                        style={{...webStyle.inputElemnt1, ...(validate && errors.service_address ? webStyle.inputElementError : {})}}
                        data-test-id="service_address"
                        type="text"
                        value={values?.service_address || ''}
                        onChange={(event) => {
                          setFieldValue("service_address", event.target.value);
                        }}
                      />
                      {validate && errors.service_address && (
                        <Tooltip
                          open={Boolean(errors.service_address)}
                          title={errors.service_address}
                          componentsProps={tooltipStyles}
                          arrow
                        >
                          <img
                           src={errorIcon}
                            alt=""
                            style={webStyle.errorIcon}
                          />
                        </Tooltip>
                      )}
                    </Box>
                    <Box sx={webStyle.fieldControl1}>
                      <Typography sx={webStyle.inputLabel}>
                        Meter location
                      </Typography>
                      <input
                        style={{...webStyle.inputElemnt1, ...(validate && errors.meter_location ? webStyle.inputElementError : {})}}
                        data-test-id="meter_location"
                        type="text"
                        onChange={(event) => {
                          setFieldValue("meter_location", event.target.value);
                        }}
                        value={values.meter_location || ''}
                      />
                      {validate && errors.meter_location && (
                        <Tooltip
                          open={Boolean(errors.meter_location)}
                          title={errors.meter_location}
                          componentsProps={tooltipStyles}
                          arrow
                        >
                          <img
                           src={errorIcon}
                            alt=""
                            style={webStyle.errorIcon}
                          />
                        </Tooltip>
                      )}
                    </Box>
                    <Box sx={webStyle.fieldControl1}>
                      <Typography sx={webStyle.inputLabel}>
                        Building manager / Supt's name
                      </Typography>
                      <input
                        style={{...webStyle.inputElemnt1, ...(validate && errors.building_manager ? webStyle.inputElementError : {})}}
                        data-test-id="building_manager"
                        type={"text"}
                        onChange={(event) => {
                          setFieldValue("building_manager", event.target.value);
                        }}
                        value={values.building_manager || ''}
                      />
                      {validate && errors.building_manager && (
                        <Tooltip
                          open={Boolean(errors.building_manager)}
                          title={errors.building_manager}
                          componentsProps={tooltipStyles}
                          arrow
                        >
                          <img
                           src={errorIcon}
                            alt=""
                            style={webStyle.errorIcon}
                          />
                        </Tooltip>
                      )}
                    </Box>
                    <Box sx={webStyle.fieldControlGroup}>
                      <Box sx={webStyle.fieldControl1}>
                        <Typography sx={webStyle.inputLabel}>
                          Phone number
                        </Typography>
                        <input
                          style={{...webStyle.inputElemnt1, ...(validate && errors.phone_number ? webStyle.inputElementError : {})}}
                          data-test-id="phone_number"
                          type={"text"}
                          onChange={(event) => {
                            setFieldValue(
                              "phone_number",
                              event.target.value.replace(/\D/g, "")
                            );
                          }}
                          value={values.phone_number || ''}
                        />
                        {validate && errors.phone_number && (
                          <Tooltip
                            open={Boolean(errors.phone_number)}
                            title={errors.phone_number}
                            componentsProps={tooltipStyles}
                            arrow
                          >
                            <img
                             src={errorIcon}
                              alt=""
                              style={webStyle.errorIcon}
                            />
                          </Tooltip>
                        )}
                      </Box>
                      <Box sx={webStyle.fieldControl1}>
                        <Typography sx={webStyle.inputLabel}>
                         Email
                        </Typography>
                        <input
                        style={{...webStyle.inputElemnt1, ...(validate && errors.email ? webStyle.inputElementError : {})}}
                          data-test-id="email"
                          type={"email"}
                          onChange={(event) => {
                            setFieldValue("email", event.target.value);
                          }}
                          value={values.email || ''}
                        />
                        {validate && errors.email && (
                          <Tooltip
                            open={Boolean(errors.email)}
                            title={errors.email}
                            componentsProps={tooltipStyles}
                            arrow
                          >
                            <img
                             src={errorIcon}
                              alt=""
                              style={webStyle.errorIcon}
                            />
                          </Tooltip>
                        )}
                      </Box>
                    </Box>
                    <Box sx={webStyle.fieldControlGroup}>
                      <Box sx={webStyle.fieldControl1}>
                        <Typography sx={webStyle.inputLabel}>
                          # of meters to read
                        </Typography>
                        <Box sx={webStyle.meterToReadBlock}>
                          <button
                            style={{...webStyle.numberFieldControlButton, ...webStyle.numberFieldControlButtonLeft}}
                            type="button"
                            data-test-id="meterToReadMinus"
                            onClick={() => {
                              values?.of_meaters_to_read && values.of_meaters_to_read > 1 && setFieldValue(
                                "of_meaters_to_read",
                                (Number(values?.of_meaters_to_read) - 1).toString()
                              );
                            }}
                          >
                            <img
                              src={removeIcon}
                              style={webStyle.numberFieldControlIcon}
                            />
                          </button>
                          <input
                            style={webStyle.numberInputElemnt}
                            data-test-id="meterToRead"
                            type="text"
                            min={0}
                            value={values?.of_meaters_to_read || 1}
                          />
                          <button
                            style={{...webStyle.numberFieldControlButton, ...webStyle.numberFieldControlButtonRight}}
                            type="button"
                            data-test-id="meterToReadPlus"
                            onClick={() => {
                              setFieldValue(
                                "of_meaters_to_read",
                                (Number(values?.of_meaters_to_read) + 1).toString()
                              );
                            }}
                          >
                            <AddRoundedIcon sx={webStyle.numberFieldControlIcon} />
                          </button>
                        </Box>
                      </Box>
                      <Box sx={webStyle.fieldControl1}>
                        <Typography sx={webStyle.inputLabel}>
                          Reading period
                        </Typography>
                        <Select
                          value={values.reading_period}
                          displayEmpty
                          inputProps={{ "aria-label": "Without label" }}
                          data-test-id="readingPeriod"
                          sx={webStyle.selectInput}
                          onChange={(event) => setFieldValue('reading_period', event.target.value)}
                        >
                          <MenuItem sx={webStyle.selectItem} value={'monthly'}>Monthly</MenuItem>
                          <MenuItem sx={webStyle.selectItem} value={'two_months'}>2 months</MenuItem>
                          <MenuItem sx={webStyle.selectItem} value={'quarterly'}>Quarterly</MenuItem>
                          <MenuItem sx={webStyle.selectItem} value={'four_months'}>4 months</MenuItem>
                          <MenuItem sx={webStyle.selectItem} value={'six_months'}>6 months</MenuItem>
                          <MenuItem sx={webStyle.selectItem} value={'yearly'}>Yearly</MenuItem>
                        </Select>
                      </Box>
                      <Box sx={webStyle.fieldControl1}>
                        <Typography sx={webStyle.inputLabel}>
                          Start reading from 
                        </Typography>
                        <Select
                          value={values.start_reading_from}
                          displayEmpty
                          inputProps={{ "aria-label": "Without label" }}
                          data-test-id="startReadingFrom"
                          sx={webStyle.selectInput}
                          onChange={(event) => setFieldValue('start_reading_from', event.target.value)}
                        >
                          {months.map((month) => <MenuItem key={month} sx={webStyle.selectItem} value={month}>{month}</MenuItem>)}
                        </Select>
                      </Box>
                    </Box>
                    <Box sx={webStyle.fieldControl1}>
                      <Typography sx={webStyle.inputLabel}>
                       Receive report by
                      </Typography>
                      <Select
                        value={0}
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                        data-test-id="reportTo"
                        sx={webStyle.selectInput}
                      >
                        <MenuItem sx={webStyle.selectItem} value={0}>Email</MenuItem>
                      </Select>
                    </Box>
                    <Box sx={webStyle.fieldCheckboxControl}>
                      <Typography sx={webStyle.switchTitle}>
                      Get invoice for my tenants
                      </Typography>
                      <IOSSwitch
                        sx={{ m: 1 }}
                        defaultChecked
                        data-test-id="getInvoice"
                      />
                    </Box>
                    <Box sx={webStyle.fieldCheckboxControl}>
                      <Typography sx={webStyle.switchTitle}>
                      Add service charge to invoice
                      </Typography>
                      <IOSSwitch
                        sx={{ m: 1 }}
                        defaultChecked
                        data-test-id="addServiceCharge"
                      />
                    </Box>
                    <Box sx={webStyle.fieldControlGroup}>
                      <button
                        style={webStyle.cancelButton}
                        data-test-id="cancelBtn"
                        type="button"
                        onClick={closeAddEditDialog}
                      >
                        Cancel
                      </button>
                      <button
                        style={webStyle.submitButton}
                        data-test-id="submitBtn"
                        type="button"
                        onClick={() => {
                          // setFormDate(values);
                          setValidate(true);
                          if (Object.keys(errors).length == 0) {
                            handleSubmit();
                          }
                        }}
                      >
                       Save
                      </button>
                    </Box>
                  </Box>
                </form>
              )}
            </Formik>
          </Box>
        </Box>
      </Box>
        </DialogContent>
      </Dialog>
      <Dialog
        open={!!deleteCompanyPropertyIdDialog}
        onClose={() => setDeleteCompanyPropertyIdDialog(null)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        data-test-id="deleteDialog"
        PaperProps={{
          style: {
            width: "633px", 
            padding: "40px 32px 32px 32px",
            borderRadius: "16px", 
            fontFamily:"'Inter', sans-serif",
          },
        }}
      >
      <DialogTitle
        id="alert-dialog-title"
        sx={webStyle.dialogTitle}
      >
        Are you sure you want to delete this property?
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          sx={webStyle.dialogContent}
          id="alert-dialog-description"
        >
          This action can’t be undone, and all the data associated with this property will be permanently deleted and no longer accessible.
        </DialogContentText>
        <Divider />
      </DialogContent>
      <DialogActions sx={webStyle.dialogAction}>
        <Button
          onClick={() => setDeleteCompanyPropertyIdDialog(null)}
          variant="outlined"
          sx={webStyle.dialogCancelButton}
        >
          Cancel
        </Button>
        <Button
          onClick={() => deleteCompanyProperty(deleteCompanyPropertyIdDialog || '')}
          sx={webStyle.confirmDelete}
          startIcon={<DeleteIcon />} 
          data-test-id="deleteDialogButton"
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
      </Box>
    );
  };

  const StyledSearchInput = styled(InputBase)(() => ({
    height: "44px",
    padding: "12px 44px 12px 12px",
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
    backgroundColor: '#FFFFFF',
    fontFamily: "'Inter', sans-serif",

    '& .MuiInputBase-input': {
      fontSize: '16px',

      '&::placeholder': {
        fontFamily: "'Inter', sans-serif",
        color: '#94A3B8',
        fontSize: '16px',
        opacity: 1, // Firefox requires this
      },
    },
  }));

  const IOSSwitch = styled((props: SwitchProps) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor: "#1A7BA4",
          opacity: 1,
          border: 0,
          ...theme.applyStyles("dark", {
            backgroundColor: "#2ECA45",
          }),
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color: theme.palette.grey[100],
        ...theme.applyStyles("dark", {
          color: theme.palette.grey[600],
        }),
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.7,
        ...theme.applyStyles("dark", {
          opacity: 0.3,
        }),
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: "#E9E9EA",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
      ...theme.applyStyles("dark", {
        backgroundColor: "#39393D",
      }),
    },
  }));

const StyledSelect = styled(Select)(() => ({
  borderRadius: "100px",
  height: "22px",
  fontSize: "12px",
  "& .MuiSelect-select": {
        padding: "0 24px 0 0 !important",
    },
  "& .MuiSelect-icon": {
    borderRadius: "0 100px 100px 0",
    borderLeft: "1px solid #E2E8F0",
    height: "22px",
    right: 0,
    top: 0,
    backgroundColor: "#F1F5F9",
  },
  "& .MuiSelect-iconOpen": {
    borderLeft: 'none',
    borderRadius: '100px 0 0 100px',
    borderRight: '1px solid #E2E8F0',
  },
}));

  const tooltipStyles = {
    tooltip: {
      sx: {
        color: '#FFFFFF',
        fontFamily: "'Inter', sans-serif",
        fontSize: '13px',
        lineHeight: '18px',
        fontWeight: '400',
        padding: '8px 12px',
        backgroundColor: '#1E293B',
        borderRadius: '8px',
        width: 'fit-content',
        maxWidth: '480px',
        '& .MuiTooltip-arrow': {
          color: '#1E293B',
        },
      }
    }
  };

  const webStyle = {
    propertiesContainer: {
      display: "flex",
      flexDirection: "column",
      backgroundColor: "#F8FAFC",
    },
    propertiesTableHeader: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      justifyContent: "space-between",
      margin: "20px 0px",
      "@media (max-width: 767px)": {
        flexDirection: "column",
      }
    },
    searchContainer: {
      display: "flex",
      flexDirection: "row",
      width: "340px",
      "@media (max-width: 767px)": {
        width: "100%",
      }
    },
    actionContainer: {
      display: "flex",
      flexDirection: "row",
      "@media (max-width: 767px)": {
        width: "100%",
      }
    },
    tableWrapper: (totalPages: number) => ({
      minHeight: totalPages > 1 ? '674px' : 'unset',
      marginBottom: "30px",
      "@media (max-width: 1330px)": {
        // "@media (max-width: 767px)": {
        overflow: "auto",
      },
    }),
    propertisTable: {
      border: "1px solid #CBD5E1",
      borderRadius: "12px",
      minWidth: "880px",
      "@media (max-width: 969px)": {
        minWidth: "720px",
      },
    },
    propertisTableHead: {
      display: "flex",
      flexDirection: "row",
      padding: "0px 16px",
      backgroundColor: '#F8FAFC',
      borderRadius: '12px 12px 0 0',
    },
    propertisDataTableHead: {
      width: "calc(100% - 360px)",
      display: "flex",
      flexDirection: "row",
      padding: "0px 10px",
      "@media (max-width: 969px)": {
        width: "calc(100% - 90px)",
      }
    },
    propertisTableBody: {
      display: "flex",
      flexDirection: "row",
      padding: "0px 16px",
      borderTop: "1px solid #CBD5E1",
      backgroundColor: '#FFFFFF',
      '&:last-of-type': {
        borderRadius: '0 0 12px 12px',
      }
    },
    propertisDataTableBody: {
      width: "calc(100% - 360px)",
      display: "flex",
      flexDirection: "row",
      padding: "0px 10px",
      "@media (max-width: 969px)": {
        width: "calc(100% - 120px)",
      }
    },
    fieldControl: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
      marginTop: "10px",
      marginBottom: "10px",
      position: "relative",
    },
    inputElemnt: {
      height: "44px",
      padding: "10px",
      borderRadius: "8px",
      border: "1px solid #CBD5E1",
       fontFamily: "'Inter', sans-serif",
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "24px",
    },
    searchIcon: {
      width: "24px",
      height: "24px",
      position: "absolute" as "absolute",
      top: 10,
      right: 16,
    },
    btnIcon: {
      width: "24px",
      height: "24px",
    },
    filterButton: {
       fontFamily: "'Inter', sans-serif",
      fontSize: "16px",
      fontWeight: 700,
      lineHeight: "24px",
      color: "#1A7BA4",
      border: "none",
      backgroundColor: "#F8FAFC",
      display: "flex",
      flexDirection: "row" as const,
      height: "44px",
      alignItems: "center",
      margin: "10px 20px",
      padding: "6px 10px",
    },
    addPropertiesButton: {
      fontFamily: "'Inter', sans-serif",
      fontSize: "16px",
      fontWeight: 700,
      lineHeight: "22px",
      color: "#FFFFFF",
      border: "none",
      borderRadius: "8px",
      backgroundColor: "#1A7BA4",
      display: "flex",
      flexDirection: "row" as const,
      height: "44px",
      alignItems: "center",
      margin: "10px 0px",
      padding: "6px 16px",
      gap: '8px',
      "@media (max-width: 767px)": {
        width: "100%",
      },
      "&:hover": {
      backgroundColor: "#1A7BA4",
    },
    },
    headerTitle: {
      fontFamily: "'Inter', sans-serif",
      fontSize: "12px",
      fontWeight: 700,
      lineHeight: "18px",
      textTransform: "uppercase" as const,
      color: "#64748B",
      width: "25%",
      margin: "12px 4px",
    },
    headerShortTitle: {
      fontFamily: "'Inter', sans-serif",
      fontSize: "12px",
      fontWeight: 700,
      lineHeight: "18px",
      textTransform: "uppercase" as const,
      color: "#64748B",
      width: "16.66%",
      margin: "12px 4px",
    },
    headerTitleAction: {
      fontFamily: "'Inter', sans-serif",
      fontSize: "12px",
      fontWeight: 700,
      lineHeight: "18px",
      textTransform: "uppercase" as const,
      color: "#64748B",
      width: "360px",
      margin: "12px 4px",
      // width: "330px",
      // margin: "12px 0px",
      "@media (max-width: 969px)": {
        width: "120px",
      },
    },
    addTenantButton: {
      backgroundColor: "#1A7BA4",
      color: "#FFFFFF",
      fontFamily: "'Inter', sans-serif",
      fontSize: "14px",
      fontWeight: 600,
      lineHeight: "22px",
      padding: "6px 8px",
      borderRadius: "7px",
      border: "none",
      display: "flex",
      flexDirection: "row" as const,
      height: "36px",
      alignItems: "center",
      textTransform: "none",
      "@media (max-width: 969px)": {
        minWidth: "32px",
        width: "32px",
      },
      "&:hover": {
        backgroundColor: "#1A7BA4",
      },
      gap: '6px'
    },
    editButton: {
      backgroundColor: "#FFFFFF",
      color: "#1A7BA4",
       fontFamily: "'Inter', sans-serif",
      fontSize: "14px",
      fontWeight: 600,
      lineHeight: "22px",
      padding: "6px 8px",
      borderRadius: "7px",
      border: "1px solid #1A7BA4",
      display: "flex",
      flexDirection: "row" as const,
      height: "36px",
      alignItems: "center",
      textTransform: "none",
      "@media (max-width: 969px)": {
        minWidth: "32px",
        width: "32px",
      },
      gap: '6px'
    },
    deleteButton: {
      backgroundColor: "#FFFFFF",
      color: "#DC2626",
       fontFamily: "'Inter', sans-serif",
      fontSize: "14px",
      fontWeight: 600,
      lineHeight: "22px",
      padding: "6px 8px",
      borderRadius: "7px",
      border: "1px solid #DC2626",
      display: "flex",
      flexDirection: "row" as const,
      height: "36px",
      alignItems: "center",
      textTransform: "none",
      "@media (max-width: 969px)": {
        minWidth: "32px",
        width: "32px",
      },
      gap: '6px'
    },
    propertyItem: {
       fontFamily: "'Inter', sans-serif",
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "22px",
      color: "#0F172A",
      width: "25%",
      margin: "13px 4px",
      alignItems: "center",
      display: "flex",
      wordBreak: 'break-word',
    },
    propertyShortItem: {
       fontFamily: "'Inter', sans-serif",
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "22px",
      color: "#0F172A",
      width: "16.66%",
      margin: "13px 4px",
      alignItems: "center",
      display: "flex",
      wordBreak: 'break-word',
    },
    propertyItemAction: {
      fontFamily: "'Inter', sans-serif",
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "22px",
      color: "#0F172A",
      width: "360px",
      display: "flex",
      flexDirection: "row",
      margin: "13px 4px",
      alignItems: "center",
      gap: "8px",
      // width: "330px",
      "@media (max-width: 969px)": {
        width: "120px",
      }
    },
    noPropertyBlock: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      margin: "auto",
      maxWidth: "600px",
      width: "80%",
    },
    noPropertyBlockTitle: {
      margin: "80px 10px",
      textAlign: "center",
      "@media (max-width: 500px)": {
        margin: "10px",
      }
    },
    noPropertyTitle: {
       fontFamily: "'Inter', sans-serif",
      fontSize: "24px",
      fontWeight: 700,
      lineHeight: "32px",
      marginBottom: "15px",
      marginTop: "50px",
      "@media (max-width: 500px)": {
        fontSize: "20px",
        lineHeight: "24px",
      }
    },
    noPropertySubTitle: {
       fontFamily: "'Inter', sans-serif",
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "24px",
      color: "#475467",
      marginBottom: "20px",
      "@media (max-width: 500px)": {
        fontSize: "14px",
        lineHeight: "20px",
      }
    },
    addNewButton: {
      fontFamily: "'Inter', sans-serif",
      fontSize: "16px",
      fontWeight: 700,
      lineHeight: "24px",
      margin: "15px auto",
      height: "56px",
      borderRadius: "8px",
      border: "none",
      background: "#1A7BA4",
      color: "#FFFFFF",
      padding: "0px 20px",
      flexDirection: "row" as const,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "430px",
      textTransform: "capitalize",
      "@media (max-width: 500px)": {
        fontSize: "14px",
        width: "100%"
      },
      "&:hover": {
        backgroundColor: "#1A7BA4",
      },
    },
    buttonText: {
      fontFamily: "'Inter', sans-serif",
      fontSize: "16px",
      fontWeight: 700,
      lineHeight: "24px",
      textTransform: "none",
      // "@media (max-width: 969px)": {
      //   display: "none"
      // },
    },
    mainImage: {
      width: "163px",
      height: "154px",    
    },
    buttonBlock: {
      display: "flex",
      flexDirection: "row",
    },
    typographyText: {    
      fontFamily: "'Inter', sans-serif",
      fontSize: "14px",
      fontWeight: 600,
      lineHeight: "22px",
      textTransform: "none",
      "@media (max-width: 969px)": {
        display: "none"
      },
    },

    addPropertiesContainer: {
      display: "flex",
      flexDirection: "row",
      fontFamily: "'Inter', sans-serif",
    },
    addPropertiesBlock: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      width: "100%",
    },
    addPropertiesBlockTitle: {
      margin: "10px",
    },
    addPropertyFormContainer: {
      maxWidth: "700px",
      width: '100%',
    },
    formContainer: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
    },
    formTitle: {
      fontFamily: "'Inter', sans-serif",
      fontSize: "24px",
      fontWeight: 700,
      lineHeight: "32px",
      marginBottom: "6px",
    },
    formSubTitle: {
      fontFamily: "'Inter', sans-serif",
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "24px",
      color: "#475467",
      marginBottom: "20px",
    },

    select: {
      fontFamily: "'Inter', sans-serif",
      '& .MuiSelect-select': {
        fontFamily: "'Inter', sans-serif",
      },
    },
    selectItem: {
      fontFamily: "'Inter', sans-serif",
      fontSize: "14px",
      color: '#0F172A',
      padding: '8px 12px',
      margin: '0 8px 12px',
      borderRadius: '8px',
      '&:last-of-type': {
        margin: '0 8px',
      },
      '&.Mui-selected': {
        backgroundColor: '#E8FBFF',
        color: '#1A7BA4',
        fontWeight: '600',
      }
    },
    selectInput: {
      fontFamily: "'Inter', sans-serif",
      backgroundColor: "#FFFFFF",
      "& fieldset": {
        border: "1px solid #CBD5E1",
      },
      "& fieldset:hover": {
        border: "1px solid #CBD5E1 !important",
      },
    },
    fieldControlGroup: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
      gap: '16px',
      "@media (max-width: 767px)": {
        flexDirection: "column",
      }
    },
    fieldCheckboxControl: {
      display: "flex",
      flex: 1,
      flexDirection: "row",
      margin: "10px 0",
      position: "relative",
    },
    switchTitle: {
      fontFamily: "'Inter', sans-serif",
      fontSize: "14px",
      fontWeight: 700,
      lineHeight: "22px",
      color: "#334155",
      alignItems: "center",
      display: "flex",
    },
    inputLabel: {
      fontFamily: "'Inter', sans-serif",
      fontSize: "14px",
      fontWeight: 700,
      lineHeight: "22px",
      color: "#334155",
      marginBottom: "5px",
      alignItems: "center",
      display: "flex",
      "@media (max-width: 767px)": {
        fontSize: "12px",
        // fontWeight: 700,
        lineHeight: "20px",
      }
    },
    numberInputElemnt: {
      height: "56px",
      padding: "10px",
      border: "none",
      "-moz-appearance": "textfield",
      "& ::-webkit-outer-spin-button": {
        "-webkit-appearance": "none",
        margin: 0,
      },
      "& ::-webkit-inner-spin-button": {
        "-webkit-appearance": "none",
        margin: 0,
      },
      width: "34%",
      textAlign: "center" as const,
      fontSize: '16px',
    },
    inputElemnt1: {
      height: "56px",
      padding: "10px 12px",
      borderRadius: "8px",
      border: "1px solid #CBD5E1",
      fontFamily: "'Inter', sans-serif",
      fontSize: '16px',
    },
    inputElementError: {
      border: "1px solid #F87171",
      padding: '10px 52px 10px 10px'
    },
    errorIcon: {
      width: "24px",
      height: "24px",
      position: "absolute" as "absolute",
      top: 43,
      right: 16,
    },
    numberFieldControlIcon: {
      width: "24px",
      height: "24px",
      color: '#1A7BA4',
    },
    numberFieldControlButton: {
      backgroundColor: "rgba(26, 123, 164, 0.1)",
      border: "none",
      width: "33%",
      '&:hover': {
        backgroundColor: "rgba(26, 123, 164, 0.2)",
      }
    },
    numberFieldControlButtonLeft: {
      borderRadius: '8px 0 0 8px',
    },
    numberFieldControlButtonRight: {
      borderRadius: '0 8px 8px 0',
    },
    cancelButton: {
      fontFamily: "'Inter', sans-serif",
      fontSize: "16px",
      fontWeight: 700,
      lineHeight: "24px",
      marginTop: "10px",
      height: "44px",
      borderRadius: "8px",
      border: "1px solid #1A7BA4",
      background: "#FFFFFF",
      color: "#1A7BA4",
      padding: "0px 20px",
    },
    submitButton: {
      fontFamily: "'Inter', sans-serif",
      fontSize: "16px",
      fontWeight: 700,
      lineHeight: "24px",
      marginTop: "10px",
      height: "44px",
      borderRadius: "8px",
      border: "none",
      background: "#1A7BA4",
      color: "#FFFFFF",
      padding: "0px 20px",
    },
    meterToReadBlock: {
      border: "1px solid #CBD5E1",
      borderRadius: "8px",
      display: "flex",
      flexDirection: "row",
    },
    fieldControl1: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
      margin: "10px 0",
      position: "relative",
    },
    confirmDelete:{
      padding: "6px 12px",
      borderRadius: "5px",
      textTransform: "none",
      backgroundColor:"white",
      color:"#DC2626",
      border:"1px solid #DC2626",
      fontFamily:"'Inter', sans-serif",
      fontWeight:"600",
      height: '44px',
      marginLeft: '16px !important',
      "@media (max-width: 767px)": {
        width: "100%",
        marginLeft: '0px !important',
        marginTop: '16px !important',

        // margin: "10px 0px",
      }
    },
   dialogCancelButton:{
    color: "#1A7BA4",
    border: "1px solid #1A7BA4",
    padding: "6px 12px",
    borderRadius: "5px",
    textTransform: "none",
    fontWeight:"700",
    fontFamily:"'Inter', sans-serif",
    fontSize: '16px',
    height: '44px',
    "@media (max-width: 767px)": {
      width: "100%",
      margin: "10px 0px",
    }
   },
   actionButton: {
    width: '20px',
    height: '20px',
    // marginRight: '6px'
   },
   dialogTitleText: {
    fontWeight: '700',
    fontSize: '24px',
    fontFamily:"'Inter', sans-serif",
   },
   chevronIconActive: {
     "> path": {
       fill: "#475569",
     },
   },
   chevronIconDisabled: {
     "> path": {
       fill: "#64748B",
     },
   },
   selectBookIdText: {
     fontFamily: "'Inter', sans-serif",
     padding: '2px 8px',
   },
   selectBookIdOption: {
     fontFamily: "'Inter', sans-serif",
     fontSize: "14px",
     color: "#0F172A",
     padding: "8px 12px",
     margin: "0 8px 12px",
     borderRadius: "8px",
     "&:last-of-type": {
       margin: "0 8px",
     },
     "&.Mui-selected": {
       backgroundColor: "#E8FBFF",
       color: "#1A7BA4",
       fontWeight: "600",
     },
   },
   loaderWrapper: {
    margin: '200px auto 0',
    width: 'fit-content',
   },
   loader: {
    color: '#1A7BA4',
    height: '60px',
    width: '60px',
   },
   dialogTitle: { 
    // fontWeight: "600", 
    // paddingBottom: "8px",
    // fontFamily:"'Inter', sans-serif", 
    // fontSize:'20px',
    fontWeight: "600",
    // padding: "0 0 24px",
    padding: "10px 20px",
    fontFamily: "'Inter', sans-serif",
    fontSize: "24px",
    letterSpacing: "-1px",
    // whiteSpace:"nowrap"
    "@media (max-width: 767px)": {
      fontSize:'17px',
    }
  },
  dialogContent: { 
    // marginBottom: "32px", 
    // padding: 0,
    color: "#64748B", 
    // padding: "0px 20px/",
    paddingBottom: "16px",
    fontFamily:"'Inter', sans-serif",
    fontSize:"18px",
    "@media (max-width: 767px)": {
      fontSize:"16px",
    }
  },
  dialogAction: {
     padding: "0px 20px",
     "@media (max-width: 767px)": {
      flexDirection: "column",
    },
  },
  };