import React from "react";
// Customizable Area Start
import {
  Typography,
  Box,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  Radio,
  Tooltip,
  ButtonBase,
} from "@mui/material";
import { downloadIcon } from "./assets";
import moment from "moment";
import KeyboardArrowLeftRoundedIcon from "@mui/icons-material/KeyboardArrowLeftRounded";
import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";
// Customizable Area End

import UnCheckedCheckboxIcon from "../assets/UnCheckedCheckboxIcon";
import CheckedCheckboxIcon from "../assets/CheckedCheckboxIcon";

import UnCheckedRadioButtonIcon from "../assets/UnCheckedRadioButtonIcon";
import CheckedRadioButtonIcon from "../assets/CheckedRadioButtonIcon";

import AnaliticsPageController, {
  DateFilter,
  MeterTypeFilter,
  Props,
  configJSON,
} from "./AnaliticsPageController";
import { CustomChart } from "../../../components/src/CustomChart.web";
// import { DashboardChart } from "./DashboardChart.web";
// Customizable Area Start

const currentDate = moment();
const previousYear = currentDate.year() - 1;
const twoYearsAgo = currentDate.year() - 2;
const threeYearsAgo = currentDate.year() - 3;

// Customizable Area End
export default class AnaliticsPage extends AnaliticsPageController {
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
      PaperProps: {
        style: {
          maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
          width: 250,
        },
      },
    };

    return (
      // Customizable Area Start
      <Box sx={webStyle.reportsContainer} ref={this.wrapperRef}>
        <Box sx={webStyle.reportsTableHeader}>
          <Typography sx={webStyle.reportTitle}>
            {configJSON.dashboardText}
          </Typography>
          <Box sx={webStyle.dashboardControl}>
            <Box sx={webStyle.searchContainer}>
              <label style={webStyle.inputLabel} htmlFor={"dateFilterSelect"}>
                Customer
              </label>
              <Box sx={{...webStyle.fieldControl, "@media (max-width: 1330px)": {
                  width: "auto",
                },}}>
                <Select
                  value={this.state.customerIdFilter}
                  data-test-id="customerSelect"
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  onChange={(event) => {
                    this.changeCustomerFilter(event.target.value);
                  }}
                  sx={{...webStyle.select, ...webStyle.selectInHeader}}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        maxHeight: "400px",
                        overflowY: 'auto',
                        width: '200px',
                        "& .MuiMenu-list": {
                          padding: "2px 0",
                        },
                        "@media (max-width: 870px)": {
                          width: '100%',
                        }
                      }
                    },
                  }}
                  renderValue={(select) => {
                    if (!select) {
                      return 'Select';
                    }

                    return this.state.customersList?.find((customer) => customer.id === select)?.company_name;
                  }}
                >
                  {this.state.customersList.map((customer) => (
                    <MenuItem
                      value={customer.id}
                      sx={webStyle.selectOption}
                      key={customer.id}
                    >
                      {customer.company_name}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
              <label style={webStyle.inputLabel} htmlFor={"meterNumber"}>
                Year
              </label>
              <Box sx={webStyle.fieldControl}>
                <Select
                  value={this.state.dateFilter}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  data-test-id="dateFilterSelect"
                  onChange={(event) => {
                    this.changeDateFilter(event.target.value as DateFilter);
                  }}
                  sx={{...webStyle.select, ...webStyle.selectInHeader}}
                >
                  <MenuItem
                    value={DateFilter.LAST_12_MONTHS}
                    sx={webStyle.selectOption}
                  >
                    {configJSON.lastYearText}
                  </MenuItem>
                  <MenuItem
                    value={DateFilter.LAST_YEAR}
                    sx={webStyle.selectOption}
                  >
                    {configJSON.previusYearText(previousYear)}
                  </MenuItem>
                  <MenuItem
                    value={DateFilter.TWO_YEARS_AGO}
                    sx={webStyle.selectOption}
                  >
                    {twoYearsAgo}
                  </MenuItem>
                  <MenuItem
                    value={DateFilter.THREE_YEARS_AGO}
                    sx={webStyle.selectOption}
                  >
                    {threeYearsAgo}
                  </MenuItem>
                </Select>
              </Box>
            </Box>
            <Tooltip
              open={this.state.pdfButtonTooltip.isOpen}
              title={this.state.pdfButtonTooltip.message}
              componentsProps={tooltipStyles}
              placement="top"
              arrow
            >
              <ButtonBase
                sx={webStyle.downloadButton}
                onClick={this.downloadAsPDF}
                data-test-id="downloadAsPdfButton"
              >
                <img src={downloadIcon} alt="" style={webStyle.btnIcon} />
                <Typography style={webStyle.downloadButtonText}>
                  Save as pdf
                </Typography>
              </ButtonBase>
            </Tooltip>
          </Box>
        </Box>
        <Box sx={webStyle.dashboardBody}>
          <Typography style={webStyle.cunsumptionTitle}>Consumption</Typography>
          <Box sx={webStyle.filterBar}>
            <Box sx={webStyle.fieldControl}>
              <Typography sx={webStyle.inputLabel}>
                Property name
              </Typography>
              <Select
                value={this.state.selectedPropertyId}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                data-test-id="propertyNameSelect"
                style={
                  this.state.selectedPropertyId
                    ? webStyle.selectOtherActive
                    : undefined
                }
                sx={{ ...webStyle.select, ...webStyle.selectProperty }}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      maxHeight: "400px",
                      overflowY: 'auto',
                      width: '200px',
                      "& .MuiMenu-list": {
                        padding: "2px 0",
                      },
                      "@media (max-width: 870px)": {
                        width: '100%',
                      }
                    }
                  },
                }}
                renderValue={(select: number) =>
                  this.state.propertiesList.find(
                    (property) => property.id === select
                  )?.property_name || "Select"
                }
                placeholder={"Select"}
                onChange={(event) => {
                  this.changeSelectedPropertyId(event.target.value as number);
                }}
                disabled={this.state.propertiesLoading || !this.state.customerIdFilter}
              >
                {this.state.propertiesList.length > 0 ? (
                  this.state.propertiesList.map((property) => (
                    <MenuItem
                      value={property.id}
                      sx={webStyle.selectOption}
                      key={property.id}
                    >
                      {property.property_name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem sx={webStyle.selectOption} disabled>
                    No properties
                  </MenuItem>
                )}
              </Select>
            </Box>
            <Box sx={webStyle.fieldControl}>
              <Typography sx={webStyle.inputLabel}>
                Tenant name
              </Typography>
              <Select
                value={this.state.selectedTenantIds}
                multiple
                renderValue={(selected) =>
                  selected.length ? `${selected.length} selected` : "Select"
                }
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                data-test-id="tenantNameSelect"
                sx={{
                  ...webStyle.selectOther,
                  ...(this.state.selectedTenantIds.length
                    ? webStyle.selectOtherActive
                    : {}),
                }}
                placeholder={"Select"}
                onChange={(event) => {
                  this.changeSelectedTenantIds(event.target.value as number[]);
                }}
                MenuProps={MenuProps}
                disabled={
                  this.state.tenantsLoading || !this.state.selectedPropertyId
                }
              >
                {this.state.tenantsList.length === 0 && (
                  <MenuItem sx={webStyle.selectOption} disabled>
                    No tenants
                  </MenuItem>
                )}
                {this.state.tenantsList.filter((tenant) =>
                  this.state.selectedTenantIds.includes(tenant.id)
                ).length > 0 ? (
                  <Box sx={webStyle.dividerContainer}>
                    <Box sx={webStyle.dividerText}>
                      {`${
                        this.state.tenantsList.filter((tenant) =>
                          this.state.selectedTenantIds.includes(tenant.id)
                        ).length
                      } selected`}
                    </Box>
                    <Box sx={webStyle.dividerSpace} />
                  </Box>
                ) : (
                  ""
                )}
                {this.state.tenantsList
                  .filter((tenant) =>
                    this.state.selectedTenantIds.includes(tenant.id)
                  )
                  .map((tenant) => (
                    <MenuItem
                      value={tenant.id}
                      sx={webStyle.selectOtherOption}
                      key={tenant.id}
                    >
                      <Checkbox
                        icon={<UnCheckedCheckboxIcon />}
                        checkedIcon={<CheckedCheckboxIcon />}
                        checked={this.state.selectedTenantIds.includes(
                          tenant.id
                        )}
                        sx={webStyle.checkbox}
                      />
                      <ListItemText
                        primary={tenant.tenant_name}
                        sx={webStyle.checkboxLabel}
                      />
                    </MenuItem>
                  ))}

                {this.state.tenantsList.filter(
                  (tenant) => !this.state.selectedTenantIds.includes(tenant.id)
                ).length > 0 ? (
                  <Box sx={webStyle.dividerContainer}>
                    <Box sx={webStyle.dividerText}>
                      {`${
                        this.state.tenantsList.filter(
                          (tenant) =>
                            !this.state.selectedTenantIds.includes(tenant.id)
                        ).length
                      } remaining`}
                    </Box>
                    <Box sx={webStyle.dividerSpace} />
                  </Box>
                ) : (
                  ""
                )}
                {this.state.tenantsList
                  .filter(
                    (tenant) =>
                      !this.state.selectedTenantIds.includes(tenant.id)
                  )
                  .map((tenant) => (
                    <MenuItem
                      value={tenant.id}
                      sx={webStyle.selectOtherOption}
                      key={tenant.id}
                      disabled={this.state.selectedTenantIds.length > 9}
                    >
                      <Checkbox
                        icon={<UnCheckedCheckboxIcon />}
                        checkedIcon={<CheckedCheckboxIcon />}
                        checked={this.state.selectedTenantIds.includes(
                          tenant.id
                        )}
                        sx={webStyle.checkbox}
                      />
                      <ListItemText
                        primary={tenant.tenant_name}
                        sx={webStyle.checkboxLabel}
                      />
                    </MenuItem>
                  ))}
              </Select>
            </Box>
            <Box sx={webStyle.fieldControl}>
              <Typography sx={webStyle.inputLabel}>
                {configJSON.meterTypeText}
              </Typography>
              <Select
                value={this.state.selectedMeterType}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                data-test-id="meterTypeSelect"
                sx={{
                  ...webStyle.selectOther,
                  ...(this.state.selectedMeterType
                    ? webStyle.selectOtherActive
                    : {}),
                }}
                placeholder={"Select"}
                renderValue={() => this.state.selectedMeterType || "Select"}
                MenuProps={MenuProps}
                disabled={!this.state.selectedTenantIds.length}
                onChange={(event) =>
                  this.changeSelectedMeterType(
                    event.target.value as MeterTypeFilter
                  )
                }
              >
                <MenuItem
                  value={MeterTypeFilter.WATER_COLD}
                  sx={webStyle.selectOtherOption}
                  onClick={() => this.changeSelectedMeterType(MeterTypeFilter.WATER_COLD)}
                >
                  <Box sx={webStyle.radioWrapper}>
                    <Radio
                      checked={this.state.selectedMeterType === MeterTypeFilter.WATER_COLD}
                      sx={webStyle.radioButton}
                      data-test-id="meters.type.waterCold"
                      name="WaterCold"
                      icon={<UnCheckedRadioButtonIcon />}
                      checkedIcon={<CheckedRadioButtonIcon />}
                    />
                    <label style={webStyle.radioButtonLabel} htmlFor="Water">
                      {MeterTypeFilter.WATER_COLD}
                    </label>
                  </Box>
                </MenuItem>
                <MenuItem
                  value={MeterTypeFilter.WATER_HOT}
                  sx={webStyle.selectOtherOption}
                  onClick={() => this.changeSelectedMeterType(MeterTypeFilter.WATER_HOT)}
                >
                  <Box sx={webStyle.radioWrapper}>
                    <Radio
                      checked={this.state.selectedMeterType === MeterTypeFilter.WATER_HOT}
                      sx={webStyle.radioButton}
                      data-test-id="meters.type.waterHot"
                      name="WaterHot"
                      icon={<UnCheckedRadioButtonIcon />}
                      checkedIcon={<CheckedRadioButtonIcon />}
                    />
                    <label style={webStyle.radioButtonLabel} htmlFor="Water">
                      {MeterTypeFilter.WATER_HOT}
                    </label>
                  </Box>
                </MenuItem>
                <MenuItem
                  value={MeterTypeFilter.ELECTRIC}
                  sx={webStyle.selectOtherOption}
                  onClick={() => this.changeSelectedMeterType(MeterTypeFilter.ELECTRIC)}
                >
                  <Box sx={webStyle.radioWrapper}>
                    <Radio
                      checked={this.state.selectedMeterType === MeterTypeFilter.ELECTRIC}
                      sx={webStyle.radioButton}
                      data-test-id="meters.type.electric"
                      name="Electric"
                      icon={<UnCheckedRadioButtonIcon />}
                      checkedIcon={<CheckedRadioButtonIcon />}
                    />
                    <label style={webStyle.radioButtonLabel} htmlFor="Electric">
                      {MeterTypeFilter.ELECTRIC}
                    </label>
                  </Box>
                </MenuItem>
              </Select>
            </Box>
            <Box sx={webStyle.fieldControl}>
              <Typography sx={webStyle.inputLabel}>
                Meter #
              </Typography>
              <Select
                value={this.state.selectedMeterIds}
                displayEmpty
                multiple
                renderValue={(selected) =>
                  selected.length ? `${selected.length} selected` : "Select"
                }
                inputProps={{ "aria-label": "Without label" }}
                data-test-id="meterNumberSelect"
                sx={{
                  ...webStyle.selectOther,
                  ...(this.state.selectedMeterIds.length
                    ? webStyle.selectOtherActive
                    : {}),
                }}
                placeholder={"Select"}
                onChange={(event) => {
                  this.changeSelectedMeterIds(event.target.value as number[]);
                }}
                MenuProps={MenuProps}
                disabled={
                  this.state.selectedTenantIds.length === 0 ||
                  !this.state.selectedMeterType
                }
              >
                {this.state.filteredMetersList.length === 0 && (
                  <MenuItem sx={webStyle.selectOption} disabled>
                    No meters
                  </MenuItem>
                )}
                {this.state.filteredMetersList.filter((meter) =>
                  this.state.selectedMeterIds.includes(meter.id)
                ).length > 0 ? (
                  <Box sx={webStyle.dividerContainer}>
                    <Box sx={webStyle.dividerText}>
                      {`${
                        this.state.filteredMetersList.filter((meter) =>
                          this.state.selectedMeterIds.includes(meter.id)
                        ).length
                      } selected`}
                    </Box>
                    <Box sx={webStyle.dividerSpace} />
                  </Box>
                ) : (
                  ""
                )}
                {this.state.filteredMetersList
                  .filter((meter) =>
                    this.state.selectedMeterIds.includes(meter.id)
                  )
                  .map((meter) => (
                    <MenuItem
                      key={meter.id}
                      value={meter.id}
                      sx={webStyle.selectOtherOption}
                    >
                      <Checkbox
                        icon={<UnCheckedCheckboxIcon />}
                        checkedIcon={<CheckedCheckboxIcon />}
                        checked={this.state.selectedMeterIds.includes(meter.id)}
                        sx={webStyle.checkbox}
                      />
                      <ListItemText
                        primary={meter.meter_number}
                        sx={webStyle.checkboxLabel}
                      />
                    </MenuItem>
                  ))}
                {this.state.filteredMetersList.filter(
                  (meter) => !this.state.selectedMeterIds.includes(meter.id)
                ).length > 0 ? (
                  <Box sx={webStyle.dividerContainer}>
                    <Box sx={webStyle.dividerText}>
                      {`${
                        this.state.filteredMetersList.filter(
                          (meter) =>
                            !this.state.selectedMeterIds.includes(meter.id)
                        ).length
                      } remaining`}
                    </Box>
                    <Box sx={webStyle.dividerSpace} />
                  </Box>
                ) : (
                  ""
                )}
                {this.state.filteredMetersList
                  .filter(
                    (meter) => !this.state.selectedMeterIds.includes(meter.id)
                  )
                  .map((meter) => (
                    <MenuItem
                      key={meter.id}
                      value={meter.id}
                      sx={webStyle.selectOtherOption}
                      disabled={this.state.selectedMeterIds.length > 9}
                    >
                      <Checkbox
                        icon={<UnCheckedCheckboxIcon />}
                        checkedIcon={<CheckedCheckboxIcon />}
                        checked={this.state.selectedMeterIds.includes(meter.id)}
                        sx={webStyle.checkbox}
                      />
                      <ListItemText
                        primary={meter.meter_number}
                        sx={webStyle.checkboxLabel}
                      />
                    </MenuItem>
                  ))}
              </Select>
            </Box>
          </Box>
          <Box sx={webStyle.chartContainer}>
            <Box sx={webStyle.chartSelectorContainer}>
              <button
                style={
                  webStyle.chartSelector(this.state.chartType === 'line')
                }
                onClick={() => {
                  this.changeChartType("line");
                }}
                data-test-id="lineChartTypeButton"
              >
                <Typography
                  style={
                    webStyle.chartSelectorTypographyText(this.state.chartType === 'line')
                  }
                >
                  Line chart
                </Typography>
              </button>
              <button
                style={
                  webStyle.chartSelector(this.state.chartType === 'bar')
                }
                onClick={() => {
                  this.changeChartType("bar");
                }}
                data-test-id="barChartTypeButton"
              >
                <Typography
                  style={
                    webStyle.chartSelectorTypographyText(this.state.chartType === 'bar')
                  }
                >
                  Bar chart
                </Typography>
              </button>
            </Box>
            <CustomChart
              chartType={this.state.chartType}
              data={this.state.consumptionsForChart}
              loading={this.state.consumptionsLoading}
            />
          </Box>
          {this.state.consumptionsForTable.length > 0 && (
            <Box
              sx={webStyle.tableWrapper(this.state.totalPages)}
            >
              <Box sx={webStyle.reportsTable}>
                <Box sx={webStyle.reportsTableHead}>
                  <Typography style={webStyle.headerTitle}>Tenant</Typography>
                  <Typography style={webStyle.headerTitle}>Meter#</Typography>
                  <Typography style={webStyle.headerTitle}>
                    Consumption
                  </Typography>
                  <Typography style={webStyle.headerTitle}>Date</Typography>
                </Box>
                {this.state.displayedConsumptionTableItems.map(
                  (consumption, index) => (
                    <Box sx={webStyle.reportsTableBody} key={index}>
                      <Box sx={webStyle.reportItem}>
                        {consumption.tenant_name}
                      </Box>
                      <Box sx={webStyle.reportItem}>
                        {consumption.meter_number}
                      </Box>
                      <Box sx={webStyle.reportItem}>
                        {consumption.consumption}
                      </Box>
                      <Box sx={webStyle.reportItem}>{consumption.date}</Box>
                    </Box>
                  )
                )}
              </Box>
            </Box>
          )}
          {this.state.totalPages > 1 && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: "20px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexGrow: 1,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {/* Previous Button */}
                <button
                  onClick={() => this.handlePrevPage()}
                  data-test-id="previous-button"
                  style={{
                    backgroundColor: "inherit",
                    border: "none",
                    cursor: "pointer",
                    padding: 0,
                    marginRight: "4px",
                  }}
                  disabled={this.state.currentPage === 1}
                >
                  <KeyboardArrowLeftRoundedIcon
                    sx={
                      this.state.currentPage === 1
                        ? webStyle.chevronIconDisabled
                        : webStyle.chevronIconActive
                    }
                  />
                </button>

                {this.state.totalPages <= 3 ? (
                  [...Array(this.state.totalPages).keys()].map((page) => (
                    <button
                      key={page + 1}
                      data-test-id={"page-button"}
                      style={{
                        marginRight: "4px",
                        width:
                          this.state.currentPage === page + 1 ? "24px" : "16px",
                        height: "24px",
                        backgroundColor:
                          this.state.currentPage === page + 1
                            ? "#DBF5FF"
                            : "inherit",
                        color:
                          this.state.currentPage === page + 1
                            ? "#1A7BA4"
                            : "#64748B",
                        border: "none",
                        textDecoration:
                          this.state.currentPage !== page + 1
                            ? "underline"
                            : "none",
                        borderRadius: "8px",
                        fontWeight:
                          this.state.currentPage !== page + 1 ? 400 : 700,
                        cursor: "pointer",
                        fontSize: "12px",
                        fontFamily: "'Inter', sans-serif",
                      }}
                      onClick={() => this.handlePageChange(page + 1)}
                    >
                      {page + 1}
                    </button>
                  ))
                ) : (
                  <>
                    {this.state.currentPage !== 1 && (
                      <button
                        data-test-id="ellipsis"
                        style={{
                          marginRight: "4px",
                          width: "16px",
                          height: "24px",
                          backgroundColor: "inherit",
                          color: "#64748B",
                          border: "none",
                          textDecoration: "underline",
                          borderRadius: "8px",
                          cursor: "pointer",
                          fontSize: "12px",
                          fontFamily: "'Inter', sans-serif",
                        }}
                        onClick={() => this.handlePageChange(1)}
                      >
                        1
                      </button>
                    )}

                    {this.state.currentPage > 3 && (
                      <span style={{ marginRight: "4px", color: "#64748B" }}>
                        ...
                      </span>
                    )}

                    {this.state.currentPage > 2 && (
                      <button
                        data-test-id="middle-page"
                        style={{
                          marginRight: "4px",
                          width: "24px",
                          height: "24px",
                          backgroundColor: "inherit",
                          color: "#64748B",
                          border: "none",
                          textDecoration: "underline",
                          borderRadius: "8px",
                          cursor: "pointer",
                          fontSize: "12px",
                          fontFamily: "'Inter', sans-serif",
                        }}
                        onClick={() =>
                          this.handlePageChange(this.state.currentPage - 1)
                        }
                      >
                        {this.state.currentPage - 1}
                      </button>
                    )}
                    <button
                      data-test-id="middle-page2"
                      style={{
                        marginRight: "4px",
                        width: "24px",
                        height: "24px",
                        backgroundColor: "#DBF5FF",
                        color: "#1A7BA4",
                        border: "none",
                        borderRadius: "8px",
                        cursor: "pointer",
                        fontWeight: 700,
                        fontSize: "12px",
                        fontFamily: "'Inter', sans-serif",
                      }}
                      onClick={() =>
                        this.handlePageChange(this.state.currentPage)
                      }
                    >
                      {this.state.currentPage}
                    </button>

                    {this.state.currentPage < this.state.totalPages - 1 && (
                      <button
                        data-test-id="middle-page3"
                        style={{
                          marginRight: "4px",
                          width: "16px",
                          height: "24px",
                          color: "#64748B",
                          backgroundColor: "inherit",
                          border: "none",
                          textDecoration: "underline",
                          borderRadius: "8px",
                          cursor: "pointer",
                          fontSize: "12px",
                          fontFamily: "'Inter', sans-serif",
                        }}
                        onClick={() =>
                          this.handlePageChange(this.state.currentPage + 1)
                        }
                      >
                        {this.state.currentPage + 1}
                      </button>
                    )}

                    {this.state.currentPage < this.state.totalPages - 2 && (
                      <span style={{ marginRight: "4px", color: "#64748B" }}>
                        ...
                      </span>
                    )}

                    {this.state.currentPage !== this.state.totalPages && (
                      <button
                        data-test-id="last-page"
                        style={{
                          marginRight: "4px",
                          width:
                            this.state.currentPage === this.state.totalPages
                              ? "24px"
                              : "16px",
                          height: "24px",
                          backgroundColor:
                            this.state.currentPage === this.state.totalPages
                              ? "#DBF5FF"
                              : "inherit",
                          color:
                            this.state.currentPage === this.state.totalPages
                              ? "#1A7BA4"
                              : "#64748B",
                          border: "none",
                          textDecoration:
                            this.state.currentPage !== this.state.totalPages
                              ? "underline"
                              : "none",
                          borderRadius: "8px",
                          cursor: "pointer",
                          fontSize: "12px",
                          fontFamily: "'Inter', sans-serif",
                          fontWeight:
                            this.state.currentPage !== this.state.totalPages
                              ? 400
                              : 700,
                        }}
                        onClick={() =>
                          this.handlePageChange(this.state.totalPages)
                        }
                      >
                        {this.state.totalPages}
                      </button>
                    )}
                  </>
                )}

                <button
                  data-test-id="next-page"
                  onClick={() => this.handleNextPage()}
                  style={{
                    backgroundColor: "inherit",
                    border: "none",
                    cursor: "pointer",
                    padding: 0,
                  }}
                  disabled={this.state.currentPage === this.state.totalPages}
                >
                  <KeyboardArrowRightRoundedIcon
                    sx={
                      this.state.currentPage === this.state.totalPages
                        ? webStyle.chevronIconDisabled
                        : webStyle.chevronIconActive
                    }
                  />
                </button>
              </Box>

              <Box sx={{ marginRight: "10px" }}>
                <Typography
                  fontFamily={'"Nunito Sans", sans-serif'}
                  fontSize={"12px"}
                  color={"grey"}
                >
                  {this.state.firstItemIndex + 1}-{this.state.lastItemIndex} of{" "}
                  {this.state.consumptionsForTable.length} results
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
      //Merge Engine End DefaultContainer
      // Customizable Area Start
    );
  }
}
// Customizable Area Start
const tooltipStyles = {
  tooltip: {
    sx: {
      color: "#FFFFFF",
      fontFamily: "'Inter', sans-serif",
      fontSize: "13px",
      lineHeight: "18px",
      fontWeight: "400",
      padding: "8px 12px",
      backgroundColor: "#1E293B",
      borderRadius: "8px",
      width: "fit-content",
      maxWidth: "480px",
      "& .MuiTooltip-arrow": {
        color: "#1E293B",
      },
    },
  },
};

const webStyle = {
  reportsContainer: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "rgb(248, 250, 252)",
    padding: "0px 30px",
    minHeight: "100%",
    fontFamily: "'Inter', sans-serif",
  },
  reportsTableHeader: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
    margin: "20px 0px",
    "@media (max-width: 1200px)": {
      flexDirection: "column",
    },
  },
  searchContainer: {
    display: "flex",
    flexDirection: "row",
    width: "auto",
    gap: '8px',
    "@media (max-width: 767px)": {
      flexDirection: "column",
      margin: "10px 0px",
    },
  },
  dashboardControl: {
    display: "flex",
    flexDirection: "row",
    "@media (max-width: 1330px)": {
      justifyContent: "space-between",
    },
    "@media (max-width: 1036px)": {
      flexDirection: "column",
    },
  },
  tableWrapper: (totalPages: number) => ({
    minHeight: totalPages > 1 ? '535px' : 'unset',
    marginBottom: "30px",
    "@media (max-width: 767px)": {
      overflow: "auto",
    },
  }),
  reportsTable: {
    border: "1px solid #CBD5E1",
    borderRadius: "12px",
    margin: "0px 10px",
    "@media (max-width: 767px)": {
      minWidth: "600px",
    },
  },
  reportsTableHead: {
    display: "flex",
    flexDirection: "row",
    padding: "0px 10px",
  },
  reportsTableBody: {
    display: "flex",
    flexDirection: "row",
    padding: "0px 10px",
    borderTop: "1px solid #CBD5E1",
  },
  fieldControl: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    margin: "10px 0px",
    position: "relative",
    width: '25%',
    "@media (max-width: 1330px)": {
      width: "100%",
    },
    "@media (max-width: 767px)": {
      width: "100%",
      flexDirection: "row",
    },
    "@media (max-width: 500px)": {
      flexDirection: "column",
      width: "100%",
    },
  },
  inputLabel: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "22px",
    color: "#334155",
    marginBottom: "5px",
    alignItems: "center",
    display: "flex",
    "@media (max-width: 1330px)": {
      fontSize: "12px",
    },
    "@media (max-width: 1036px)": {
      fontSize: "14px",
    },
    "@media (max-width: 870px)": {
      width: "50%",
      flex: 1,
    },
  },
  inputElemnt: {
    height: "44px",
    padding: "10px",
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
  },
  searchIcon: {
    width: "24px",
    height: "24px",
    position: "absolute" as "absolute",
    top: 10,
    right: 16,
  },
  btnIcon: {
    width: "24px",
    height: "24px",
  },
  headerTitle: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "18px",
    textTransform: "uppercase" as const,
    color: "#64748B",
    width: "25%",
    overflow: "hidden",
    margin: "12px 4px",
    "@media (max-width: 1330px)": {
      fontSize: "12px",
    },
    "@media (max-width: 1036px)": {
      fontSize: "10px",
    },
    "@media (max-width: 870px)": {
      fontSize: "10px",
    },
    "@media (max-width: 500px)": {
      fontSize: "10px",
    },
  },
  headerTitleAction: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "18px",
    textTransform: "uppercase" as const,
    color: "#64748B",
    width: "200px",
    overflow: "hidden",
    margin: "12px 4px",
  },
  downloadButton: {
    backgroundColor: "#F8FAFC",
    color: "#1A7BA4",
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    padding: "10px",
    borderRadius: "8px",
    border: "1px solid #1A7BA4",
    display: "flex",
    flexDirection: "row" as const,
    height: "44px",
    alignItems: "center",
    margin: "10px 0 10px 16px",
    "@media (max-width: 1330px)": {
      fontSize: "14px",
    },
    "@media (max-width: 1036px)": {
      fontSize: "16px",
      width: "100%",
      margin: "10px 0 0",
    },
  },
  downloadButtonText: {
    fontSize: "16px",
    fontWeight: 700,
    fontFamily: "'Inter', sans-serif",
    marginLeft: "8px",
    color: "#1A7BA4",
    "@media (max-width: 1330px)": {
      fontSize: "13px",
    },
    "@media (max-width: 1036px)": {
      fontSize: "16px",
    },
  },
  reportItem: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    color: "#0F172A",
    width: "25%",
    overflow: "hidden",
    margin: "13px 4px",
    alignItems: "center",
    display: "flex",
    "@media (max-width: 1330px)": {
      fontSize: "14px",
    },
    "@media (max-width: 1036px)": {
      fontSize: "12px",
    },
    "@media (max-width: 870px)": {
      fontSize: "12px",
    },
    "@media (max-width: 500px)": {
      fontSize: "12px",
    },
  },
  reportItemAction: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    color: "#0F172A",
    width: "200px",
    display: "flex",
    flexDirection: "row",
    overflow: "hidden",
    margin: "13px 4px",
    alignItems: "center",
    justifyContent: "space-between",
  },
  noReportBlock: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    maxWidth: "600px",
    width: "80%",
  },
  noReportBlockTitle: {
    margin: "80px 10px",
    textAlign: "center",
  },
  noReportTitle: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: "32px",
    marginBottom: "15px",
    marginTop: "50px",
  },
  mainImage: {
    width: "163px",
    height: "154px",
  },
  typographyText: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "22px",
  },
  paidStyle: {
    textTransform: "uppercase" as const,
    backgroundColor: "#D1FAE5",
    color: "#059669",
    padding: "2px 8px",
    borderRadius: "40px",
    border: "none",
    fontFamily: "'Inter', sans-serif",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "18px",
  },
  pendingStyle: {
    textTransform: "uppercase" as const,
    backgroundColor: "#FEF3C7",
    color: "#D97706",
    padding: "2px 8px",
    borderRadius: "40px",
    border: "none",
    fontFamily: "'Inter', sans-serif",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "18px",
  },
  noInvoiceStyle: {
    textTransform: "uppercase" as const,
    backgroundColor: "#F1F5F9",
    color: "#475569",
    padding: "2px 8px",
    borderRadius: "40px",
    border: "none",
    fontFamily: "'Inter', sans-serif",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "18px",
  },
  reportTitle: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "24px",
    fontWeight: 600,
    lineHeight: "30px",
    color: "#0F172A",
    alignItems: "center",
    display: "flex",
  },
  reportCount: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fonWeight: 500,
    lineHeight: "22px",
    color: "#334155",
  },
  select: {
    width: "100%",
    fontFamily: "'Inter', sans-serif",
    height: "44px",
    fontSize: "16px",
    borderRadius: "8px",
    backgroundColor: "#FFFFFF",
    "& .MuiSelect-select": {
      fontFamily: "'Inter', sans-serif",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#CBD5E1",
    },
    "@media (max-width: 1036px)": {
      flex: 1,
      width: '100% !important',
    },
  },
  selectInHeader: {
    width: '187px',
    "@media (max-width: 1330px)": {
      width: '140px',
    },
  },
  selectProperty: {
    color: "#94A3B8",
  },
  selectOption: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    color: "#0F172A",
    padding: "8px 12px",
    margin: "0 8px 12px",
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    display: 'block',
    borderRadius: "8px",
    "&:last-of-type": {
      margin: "0 8px",
    },
    "&.Mui-selected": {
      backgroundColor: "#E8FBFF",
      color: "#1A7BA4",
      fontWeight: "600",
    },
  },
  selectOther: {
    width: "100%",
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#94A3B8",
    height: "44px",
    borderRadius: "8px",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#CBD5E1",
    },
    "& .MuiSelect-select": {
      fontFamily: "'Inter', sans-serif",
    },
    "@media (max-width: 1036px)": {
      flex: 1,
    },
  },
  selectOtherActive: {
    color: "#0F172A",
  },
  selectOtherOption: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px !important",
    color: "#0F172A",
    padding: "8px 12px",
    margin: "0 8px 12px",
    borderRadius: "8px",
    gap: "8px",
    "&:last-of-type": {
      margin: "0 8px",
    },
    "&.Mui-selected": {
      backgroundColor: "inherit",
      color: "#0F172A",
      fontWeight: "400",
    },
  },
  selectOptionStart: {},
  dashboardBody: {
    border: "1.3px solid #CBD5E1",
    borderRadius: "19px",
    padding: "20px 10px",
    margin: "10px 0px",
    backgroundColor: "#FFFFFF",
  },
  cunsumptionTitle: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "19px",
    fontWeight: 700,
    lineHeight: "29px",
    color: "#64748B",
    margin: "0px 10px",
  },
  filterBar: {
    display: "flex",
    flexDirection: "row",
    margin: "10px",
    gap: '16px',
    "@media (max-width: 1330px)": {
      flexDirection: "column",
      gap: '0px',
    },
  },
  radioWrapper: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
  },
  radioButtonElemnt: {
    height: "20px",
    width: "20px",
    padding: 0,
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
    margin: 0,
  },
  radioButton: {
    padding: 0,
    width: "20px",
    height: "20px",
    "& .MuiSvgIcon-root": {
      color: "#64748B",
      width: "20px",
      height: "20px",
    },
    "&.Mui-checked": {
      backgroundColor: "#1A7BA4",
      "& .MuiSvgIcon-root": {
        width: "20px",
        height: "20px",
      },
      "& .MuiSvgIcon-root:first-of-type": {
        color: "#1A7BA4",
      },
      "& .MuiSvgIcon-root:nth-of-type(2)": {
        color: "#FFFFFF",
      },
    },
  },
  radioButtonLabel: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    color: "#0F172A",
    alignItems: "center",
    display: "flex",
  },
  chartSelectorContainer: {
    backgroundColor: "#E2E8F0",
    height: "40px",
    width: "fit-content",
    display: "flex",
    gap: "7px",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0px 4px",
    marginBottom: "20px",
    borderRadius: "10px",
    border: "1px solid #E2E8F0",
  },
  chartContainer: {
    padding: " 0px 10px 20px 10px",
  },
  chartSelector: (active: boolean) => ({
    color: active ? "#0F172A" : "#475569",
    fontWeight: "500",
    border: "none",
    backgroundColor: active ? "#FFFFFF" : "#E2E8F0",
    display: "flex",
    flexDirection: "row" as const,
    alignItems: "center",
    borderRadius: "7px",
    padding: "5px 10px",
    height: "32px",
  }),
  chartSelectorActive: {
    color: "#0F172A",
    fontWeight: "500",
    border: "none",
    backgroundColor: "#FFFFFF",
    display: "flex",
    flexDirection: "row" as const,
    alignItems: "center",
    borderRadius: "7px",
    padding: "5px 10px",
    height: "32px",
    cursor: "pointer",
  },
  chartSelectorTypographyText: (active: boolean) => ({
    color: active ? "#0F172A" : "#475569",
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: active ? 500 : 400,
    lineHeight: "22px",
    textTransform: "math-auto" as any,
    "@media (max-width: 767px)": {
      width: "50%",
    },
  }),
  dividerContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    height: "28px",
    alignItems: "center",
    marginBottom: "12px",
  },
  dividerText: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#64748B",
    margin: "0px 12px 0px 8px",
  },
  dividerSpace: {
    borderBottom: "1px solid #CBD5E1",
    display: "flex",
    flex: "auto",
    height: "0px",
  },
  checkbox: {
    width: "0px",
    height: "0px",
    borderRadius: "0px",
    color: "#64748B",
    "& .MuiSvgIcon-root": {
      fontSize: "28px",
      width: "20px",
      height: "20px",
    },
    "&.Mui-checked": {
      color: "#1A7BA4",
    },
  },
  checkboxLabel: {
    "> span": {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      display: 'block',
      fontSize: "14px",
      lineHeight: "22px",
      fontFamily: "'Inter', sans-serif",
    },
  },
  chevronIconActive: {
    "> path": {
      fill: "#475569",
    },
  },
  chevronIconDisabled: {
    "> path": {
      fill: "#64748B",
    },
  },
};
// Customizable Area End
