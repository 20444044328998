Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.reportsAPiEndPoint = "bx_block_custom_forms/reports";
exports.reportsAPiMethod = "GET";
exports.reportsApiContentType = "application/json";

exports.invoicesAPiEndPoint = "bx_block_custom_forms/invoices";
exports.invoicesAPiMethod = "GET";
exports.invoicesApiContentType = "application/json";

exports.companyDetailAPiEndPoint = "bx_block_custom_forms/company_details";
exports.companyDetailAPiMethod = "GET";
exports.companyDetailApiContentType = "application/json";

exports.invoiceButtonText = "Invoice";
exports.reportButtonText = "Report";
exports.reportText = "Reports";
exports.reportTitleText = "Report #";
exports.propertyTitleText = "Property";
exports.generatedAtText = "Generated at";
exports.typeText = "Type";
exports.paymentStatusText = "Payment status";
exports.actionsText = "Actions";
exports.noReportTitleText = "No reports";
exports.searchPlaceholderText = "Search";
// Customizable Area End
