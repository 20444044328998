import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import moment from "moment";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface Reports {
  id: string;
  tenant: string;
  meter: string;
  consumption: string;
  date: string;
}

interface CustomerApp {
  id: number;
  company_name: string;
  services_needed: string;
  message: string;
  created_at: string;
  status: CustomerAppStatus;
}

interface ContactForm {
  id: number;
  name: string;
  email: string;
  phone_number: string;
  services_needed: string;
  message: string;
  created_at: string;
}

interface StatusModal {
  statusModal: number | null;
  x: number;
  y: number;
}

interface SnackbarProps {
  isOpen: boolean;
  type: "Success" | "Error";
  message: string;
}

export enum DateFilter {
  LAST_12_MONTHS,
  LAST_YEAR,
  TWO_YEARS_AGO,
  THREE_YEARS_AGO,
}

interface S {
  token: string;
  step: number;
  modal: StatusModal;
  itemsPerPage:number;
  showCustomerAppModal: number | null;
  showContactFormModal: number | null;
  customerAppList: CustomerApp[];
  currentCustomerAppList: CustomerApp[];
  contactFormList: ContactForm[];
  currentContactFormList: ContactForm[]
  customerAppFilter: number;

  customerAppCurrentPage:number;
  customerAppTotalPages:number;
  customerAppFirstItemIndex:number,
  customerAppLastItemIdex:number,
  searchCustomerApp:string,
  filteredCustomerAppList: CustomerApp[]

  contactFormCurrentPage:number;
  contactFormTotalPages:number;
  contactFormFirstItemIndex:number,
  contactFormLastItemIdex:number,

  customerSupportLoading:boolean,
  contactUsLoading:boolean,
  changeStatusId:number | null,
  changeStatusMsg:string | null,
  deleteCustomerSupportDialogId: number | null,
  snackbarProps: SnackbarProps,
  dateFilter:DateFilter
  // Customizable Area Start
  // Customizable Area End
}

interface SS {
  id: any;
}

export enum CustomerAppStatus {
  PROCESSING = "processing",
  PROCESSED = "processed",
}

export enum PaymentStatus {
  Paid = "Paid",
  Pending = "Pending",
  NoInvoice = "No Invoice",
}

export default class AdminSupportPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  apiGetCustomerSupportsCallId: string = "";
  apiGetContactUsCallId: string = "";
  apiChangeCustomerSupportStatusCallId: string = "";
  apiDeleteCustomerSupportCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      token: "",
      step: 1,
      modal: {
        statusModal: null,
        x: 0,
        y: 0,
      },
      customerAppCurrentPage: 1,
      customerAppTotalPages: 1,
      customerAppFirstItemIndex: 1,
      customerAppLastItemIdex: 10,
      contactFormCurrentPage: 1,
      contactFormTotalPages: 1,
      contactFormFirstItemIndex: 1,
      contactFormLastItemIdex: 10,
      showCustomerAppModal: null,
      showContactFormModal: null,
      customerAppList: [],
      customerSupportLoading: false,
      searchCustomerApp: "",
      itemsPerPage:10,
      currentCustomerAppList: [],
      currentContactFormList: [],
      contactFormList: [],
      contactUsLoading: false,
      customerAppFilter: 0,
      filteredCustomerAppList: [],
      changeStatusId: null,
      changeStatusMsg: null,
      deleteCustomerSupportDialogId: null,
      snackbarProps: {
        isOpen: false,
        type: "Success",
        message: "",
      },
      dateFilter:DateFilter.LAST_12_MONTHS
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) {
      return;
    }

    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    
    if (!apiRequestCallId || !responseJson) {
      return;
    }
    if (apiRequestCallId === this.apiGetCustomerSupportsCallId && responseJson) {
       this.setCustomerSupportList(responseJson)
    }
    if (apiRequestCallId === this.apiGetContactUsCallId && responseJson) {
        this.setContactUsList(responseJson)
    }
    if (apiRequestCallId === this.apiChangeCustomerSupportStatusCallId) {
        this.changeStatusResultHandler(responseJson);
    }
    if (apiRequestCallId === this.apiDeleteCustomerSupportCallId) {
        this.filterDeletedCustomerSupport(responseJson);
    }
    // Customizable Area End
  }

  filterDeletedCustomerSupport = (responseJson: any) => {
    if (responseJson?.message) {
      this.setState((prevState) => ({
        customerAppList: prevState.customerAppList.filter(
          (c) =>
            c.id !== prevState.deleteCustomerSupportDialogId
        ),
        deleteCustomerSupportDialogId: null,
        snackbarProps: {
          isOpen: true,
          type: "Success",
          message: "Successfully removed the customer support request.",
        },
      }));
    } else {
      this.setState({
        snackbarProps: {
          isOpen: true,
          type: "Error",
          message: `An unexpected error occurred while removing the customer support request.`,
        },
      });
    }
  };

  deleteCustomerSupport = (id: number | null) => {
    const header = {
      "Content-Type": "application/json",
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiDeleteCustomerSupportCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_contact_us/support_requests/${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "Delete"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleCloseDeleteDialog = () => {
    this.setState({ deleteCustomerSupportDialogId: null });
  };

  changeStatusResultHandler = (responseJson: any) => {
    this.setState({
      changeStatusId: null,
      changeStatusMsg: responseJson?.message
    })
  }

  setCustomerSupportList = (responseJson: any) => {
    const customerSupport: CustomerApp[] = responseJson.data.map((item: any): CustomerApp => {
      return {
        id: item.attributes.id || "",
        company_name: item.attributes.company_name || "",
        services_needed: item.attributes.services_needed || "",
        created_at: moment(item.attributes.created_at).format('MM/DD/YYYY'),
        message: item.attributes.message || "",
        status: item.attributes.status || "",
      };
    });
  
    this.setState({
      customerAppList: customerSupport,
      customerSupportLoading: false,
    });
  };

  getCustomerSupportList = async () => {
    this.setState({ customerSupportLoading: true });

    const header = {
        "Content-Type": configJSON.getSupportsApiContentType,
        token: this.state.token
    };

    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetCustomerSupportsCallId = requestMessage.messageId;

    const requestUrl = configJSON.getSupportsAPiEndPoint;

    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        requestUrl
    );
    
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.getSupportsAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  setContactUsList = (responseJson: any) => {
    const customerSupport: ContactForm[] = responseJson.data.map((item: any): ContactForm => {
      return {
        id: item.id || "",
        name: item.attributes.name || "",
        services_needed: item.attributes.services_needed || "",
        created_at: moment(item.attributes.created_at).format('MM/DD/YYYY'),
        message: item.attributes.message || "",
        email: item.attributes.email || "",
        phone_number: item.attributes.phone_number || "",
      };
    });
  
    this.setState({
      contactFormList: customerSupport,
      contactUsLoading: false,
    });
  };

  getContactUsList = async () => {
    this.setState({ contactUsLoading: true });

    const header = {
        "Content-Type": configJSON.getContactUsApiContentType,
        token: this.state.token
    };

    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetContactUsCallId = requestMessage.messageId;

    const requestUrl = configJSON.getContactUsAPiEndPoint;

    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        requestUrl
    );
    
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.getContactUsAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  searchCustomerApp = (search: string) => {
    this.setState({ searchCustomerApp: search, customerAppCurrentPage: 1 });
  };

  setStep = (step: number) => {
    this.setState({ step });
  }

  closeModals = () => {
    this.setState({ showContactFormModal: null, showCustomerAppModal: null });
  }

  openContactFormModal = (id: number) => {
    this.setState({ showContactFormModal: id });
  }

  openCustomerAppModal = (id: number) => {
    this.setState({ showCustomerAppModal: id });
  }

  changeCustomerSupportStatus = (id: number, status: string) => {
    const header = {
        "Content-Type": "application/json",
        token: this.state.token
    };

    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );

    this.setState({ changeStatusId: id })
    this.apiChangeCustomerSupportStatusCallId = requestMessage.messageId;

    const requestUrl = `bx_block_contact_us/support_requests/${id}/support_status`;

    const httpBody = {
      status: status,
    };

    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        requestUrl
    );
    
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "PATCH"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  deleteCustomeerApp = (id: number) => {
    this.setState({ deleteCustomerSupportDialogId: id });
  }

  handleNextCustomerAppPage = () => {
    if (this.state.customerAppCurrentPage < Math.ceil(this.state.customerAppList.length / this.state.itemsPerPage)) {
      this.setState({ customerAppCurrentPage: this.state.customerAppCurrentPage + 1 });
    }
  };

  handlePrevCustomerAppPage = () => {
    if (this.state.customerAppCurrentPage > 1) {
      this.setState({ customerAppCurrentPage: this.state.customerAppCurrentPage - 1 });
    }
  };

  handleCustomerAppPageChange = (pageNumber: number) => {
    this.setState({ customerAppCurrentPage: pageNumber });
  };

  changeDateFilter = (value: DateFilter) => {
    this.setState({ dateFilter: value, customerAppCurrentPage: 1 });
  };

  getStartEndDates = (dateFilter: DateFilter) => {
      let startDate;
      let endDate;
  
      switch (dateFilter) {
        case DateFilter.LAST_12_MONTHS: {
          startDate = moment().subtract(12, "months").startOf('month').format('YYYY-MM-DD');
          endDate = moment().format('YYYY-MM-DD');
  
          break;
        }
        case DateFilter.LAST_YEAR: {
          startDate = moment().subtract(1, "year").startOf("year").format('YYYY-MM-DD');
          endDate = moment().subtract(1, "year").endOf("year").format('YYYY-MM-DD');
  
          break;
        }
        case DateFilter.TWO_YEARS_AGO: {
          startDate = moment().subtract(2, "year").startOf("year").format('YYYY-MM-DD');
          endDate = moment().subtract(2, "year").endOf("year").format('YYYY-MM-DD');
  
          break;
        }
        case DateFilter.THREE_YEARS_AGO: {
          startDate = moment().subtract(3, "year").startOf("year").format('YYYY-MM-DD');
          endDate = moment().subtract(3, "year").endOf("year").format('YYYY-MM-DD');
  
          break;
        }
      }
  
      return { startDate, endDate };
    };

  updateCustomerAppPagination = () => {
    const { customerAppCurrentPage: currentPage, itemsPerPage, customerAppList, searchCustomerApp, dateFilter } = this.state;
    let indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    let filteredCustomerAppList = customerAppList;

    if (searchCustomerApp){
      filteredCustomerAppList = customerAppList.filter((c) => 
        c.company_name?.toLowerCase().includes(searchCustomerApp.toLowerCase()) ||
        c.services_needed?.toLowerCase().includes(searchCustomerApp.toLowerCase()) ||
        c.message?.toLowerCase().includes(searchCustomerApp.toLowerCase())
      )
    }

    if(dateFilter){
      const { startDate, endDate } = this.getStartEndDates(dateFilter);
      filteredCustomerAppList = filteredCustomerAppList.filter(item => {
        return moment(item.created_at).isBetween(startDate, endDate, 'day', '[]');
      })
    }
    
    const currentCustomerAppList = filteredCustomerAppList.slice(indexOfFirstItem, indexOfLastItem);
    console.log({ currentPage, itemsPerPage, indexOfFirstItem, currentCustomerAppList });
    const totalPages = Math.ceil(filteredCustomerAppList.length / itemsPerPage) || 1;

    if (currentCustomerAppList.length === 0) {
      this.handleCustomerAppPageChange(totalPages);
    }

    if (indexOfLastItem > filteredCustomerAppList.length ) {
        indexOfLastItem = filteredCustomerAppList.length;
    }

    this.setState({
      filteredCustomerAppList,
      currentCustomerAppList,
      customerAppTotalPages: totalPages,
      customerAppFirstItemIndex:indexOfFirstItem,
      customerAppLastItemIdex:indexOfLastItem,  
    });
  };

  handleNextContactFormPage = () => {
    if (this.state.contactFormCurrentPage < Math.ceil(this.state.contactFormList.length / this.state.itemsPerPage)) {
      this.setState({ contactFormCurrentPage: this.state.contactFormCurrentPage + 1 });
    }
  };

  handlePrevContactFormPage = () => {
    if (this.state.contactFormCurrentPage > 1) {
      this.setState({ contactFormCurrentPage: this.state.contactFormCurrentPage - 1 });
    }
  };

  handleContactFormPageChange = (pageNumber: number) => {
    this.setState({ contactFormCurrentPage: pageNumber });
  };

  updateContactFormPagination = () => {
    const { contactFormCurrentPage: currentPage, itemsPerPage, contactFormList } = this.state;
    let indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentContactFormList = contactFormList.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(contactFormList.length / itemsPerPage) || 1;

    if (currentContactFormList.length === 0) {
      this.handleContactFormPageChange(totalPages);
    }

    if (indexOfLastItem > contactFormList.length ) {
        indexOfLastItem = contactFormList.length;
    }

    this.setState({
      currentContactFormList,
      contactFormTotalPages: totalPages,
      contactFormFirstItemIndex: indexOfFirstItem,
      contactFormLastItemIdex: indexOfLastItem,
    });
  };
  // Customizable Area Start
  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  getToken = async () => {
    let token = await getStorageData("token");
    this.setState({ token: token });
  };

  componentDidMount = async () => {
    const userRole = await getStorageData("role");

    if (userRole !== "admin") {
      const message: Message = new Message(
        getName(MessageEnum.NavigationMessage)
      );
      message.addData(
        getName(MessageEnum.NavigationTargetMessage),
        "LogInPage"
      );
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(message);
    }
    await this.getToken();
    await this.getCustomerSupportList();
    await this.getContactUsList();
    await Promise.all([this.updateCustomerAppPagination()]);
    await Promise.all([this.updateContactFormPagination()]);
  };

  componentDidUpdate = async (prevProps: Props, prevState: S) => {
    if (    
      prevState.customerAppCurrentPage !== this.state.customerAppCurrentPage ||
      prevState.itemsPerPage !== this.state.itemsPerPage ||
      prevState.customerAppList !== this.state.customerAppList ||
      prevState.searchCustomerApp !== this.state.searchCustomerApp ||
      prevState.dateFilter !== this.state.dateFilter
    ) {
      await Promise.all([ this.updateCustomerAppPagination()]);
    }

    if (    
      prevState.contactFormCurrentPage !== this.state.contactFormCurrentPage ||
      prevState.itemsPerPage !== this.state.itemsPerPage ||
      prevState.contactFormList !== this.state.contactFormList
      ) {
      await Promise.all([ this.updateContactFormPagination()]);
    }
  }
  // Customizable Area End
}
