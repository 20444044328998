import React, { Component } from 'react';
import { Box } from '@mui/material';
import { otherPagesMobileUiBanner, otherPagesBanner } from "../../blocks/landingpage/src/assets";

export default class NotFound extends Component {

  render() {
    return (
      <>
        <Box component={"img"}
          className='otherPagesMobileUiBannerStyle'
          src={otherPagesMobileUiBanner}
          alt="Banner_Img"
        />
        <Box component={"img"} src={otherPagesBanner} alt="Banner" sx={webStyle.bannerImage} />
        <Box sx={webStyle.infoPageWrapper}>
          <Box component="div" sx={webStyle.infoPageHeader}>
            404
          </Box>
          <Box component="div" sx={webStyle.infoPageDescription}>
            Sorry, we couldn't find that page
          </Box>
        </Box>
      </>
    );
  }
}

const webStyle = {
  bannerImage: {
    minHeight: '526px',
    width: '100%',
    objectFit: 'cover',
    "@media (max-width: 1036px)": {
      minHeight: '404px',
    },
    "@media (max-width: 500px)": {
      display: "none",
    }
  },
  infoPageWrapper: {
    p: "80px 99px",
    backgroundColor: "#E4FAFF",
    "@media (max-width: 810px)": {
      p: "40px 16px"
    }
  },
  infoPageSubWrapper: {
    display: "grid",
    gap: "25px"
  },
  infoPageHeader: {
    color: "#12629A",
    fontSize: "32px",
    fontWeight: "800",
    lineHeight: "35.2px",
    fontFamily: "'Nunito Sans', sans-serif",
    mb: "20px",
    "@media (max-width: 810px)": {
      fontSize: "23px",
      fontWeight: "900",
      lineHeight: "25.3px",
    }
  },
  infoPageDescription: {
    fontSize: "18px",
    fontWeight: "400",
    lineHeight: "29.12px",
    fontFamily: "'Nunito Sans', sans-serif",
    'a': {
      color: '#12629A !important',
      '&:hover': {
        textDecoration: 'none'
      }
    }
  },
  infoPageSubHeader: {
    color: "#E5614B",
    fontSize: "26px",
    fontWeight: "700",
    lineHeight: "28.6px",
    fontFamily: "'Nunito Sans', sans-serif",
    m: "20px 0",
    "@media (max-width: 810px)": {
      fontSize: "22px",
    }
  }
}
