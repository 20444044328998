import React, {useEffect, useState } from 'react';
import { Typography, 
  Box, 
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Divider,
  TextField, 
  IconButton, 
  InputAdornment,
  Tooltip,
  InputBase,
  Radio,
  CircularProgress,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/DeleteOutlineOutlined";
import * as Yup from "yup";
import { FieldArray, Formik } from "formik";
import {
  searchIcon,
  addIcon,
  editIcon,
  deleteIcon,
  noPropertieisIcon,
  errorIcon,
  removeIcon,
  caledarIcon,
  branch1,
  branch2,
  coldIcon,
  hotIcon,
  calendarErrorIcon,
} from "./assets";
import AddIcon from '@mui/icons-material/Add';
import { styled } from '@mui/material/styles';
import KeyboardArrowLeftRoundedIcon from "@mui/icons-material/KeyboardArrowLeftRounded";
import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import CloseRoundedIcon from '@mui/icons-material/Close';
import { Meter, Tenant } from './CustomersPageController';
import moment from 'moment';
import { ButtonGroup } from 'react-native-elements';

export interface Props {
    tenantList: Tenant[];
    addMeter: (tenant: Tenant) => void;
    getTenantList: () => void;
    tenantsLoading: boolean;
    addEditTenantsDialogProps: TenantDialogProps | null;
    setAddEditTenantsDialogProps: (props: TenantDialogProps | null) => void;
    deleteTenantDialogId: number | string | null;
    setDeleteTenantDialogId: (id: number | string | null) => void;
    addEditTenant: (tenant: Tenant) => void;
    deleteTenant: (tenantId: number | string) => void;
    isTest?: boolean;
}

const itemsPerPage = 10;

export interface TenantDialogProps {
  isAdd?: boolean;
  initialTenant?: Tenant;
}

export const trimSpaces = (value: string) => value.trim().replace(/\s+/g, ' ');

  export const CustomerTenants = ({tenantList, addMeter, getTenantList, tenantsLoading, addEditTenantsDialogProps, setAddEditTenantsDialogProps, deleteTenantDialogId, setDeleteTenantDialogId, addEditTenant, isTest, deleteTenant}:Props) => {
    const [validate , setValidate ] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [getTenantListRequestFired, setTenantListRequestFired] = useState(false);
    const [searchText, setSearchText] = useState('');

    const filteredTenantsList = searchText ? tenantList.filter((tenant) => tenant.tenant_name?.toLowerCase().includes(searchText.toLowerCase())) : tenantList;
    const totalPages = Math.ceil(filteredTenantsList.length / itemsPerPage) || 1;
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const displayedTenants = filteredTenantsList.slice(indexOfFirstItem, indexOfLastItem);

    useEffect(() => {
      getTenantList();
      setTenantListRequestFired(true);
    }, []);

    useEffect(() => {
      if (displayedTenants.length === 0 && currentPage !== 1) {
        setCurrentPage(1);
      }
    }, [filteredTenantsList, currentPage]);

    const handleNextPage = () => {
      if (currentPage < Math.ceil(tenantList.length / itemsPerPage)) {
        setCurrentPage(currentPage + 1);
      }
    };

    const handlePrevPage = () => {
      if (currentPage > 1) {
        setCurrentPage(currentPage - 1);
      }
    };

    const handlePageChange = (page: number) => {
      setCurrentPage(page);
    }

    const questionSchema = () => {
      return Yup.object().shape({
        tenant_name: Yup.string().transform((value) => trimSpaces(value || '')).required("Tenant Name is required"),
        meters: Yup.array()
          .of(
            Yup.object().shape({
              meter_type: Yup.string(),
              meter_number: Yup.string().transform((value: string) => trimSpaces(value || '')).matches(/^\d+$/, 'Only numbers are allowed').required("Meter number is required"),
              last_reading: Yup.string().transform((value: string) => trimSpaces(value || '')).when('lastReadingAvailable', {
                is: true,
                then: (schema: any) => schema.required('Last reading is required').min(5, "Must be 5 digits minimum").max(9, "Must be 9 digits maximum"),
                otherwise: (schema: any) => schema.notRequired(),
              }),
              date_of_last_reading: Yup.mixed()
                .transform((value, originalValue) => {
                  if (!originalValue) return null;
              
                  if (originalValue instanceof Date && !isNaN(originalValue.getTime())) {
                    return originalValue;
                  }
              
                  const parsedDate = moment(originalValue, moment.ISO_8601, true);
                  return parsedDate.isValid() ? parsedDate.toDate() : null;
                })
                .nullable() // Cho phép giá trị null
                .test(
                  "max-date",
                  "Last reading date should be equal or earlier than today",
                  (value) => !value || moment(value).isSameOrBefore(moment(), "day")
                )
                .when("lastReadingAvailable", {
                  is: true,
                  then: (schema: any) => schema.required("Last reading date is required"),
                  otherwise: (schema: any) => schema.notRequired(),
                }),
              lastReadingAvailable: Yup.boolean(),
            })
          )
          .notRequired(),
      });
    };

    const closeAddEditDialog = () => {
      setAddEditTenantsDialogProps(null);
      setValidate(false);
    };

    if (tenantsLoading || (!getTenantListRequestFired && !isTest)) {
      return (
        <Box sx={webStyle.loaderWrapper} data-test-id="loaderWrapper">
          <CircularProgress sx={webStyle.loader} />
        </Box>
      );
    }

    return (
      <Box sx={webStyle.tenantsContainer}>
        {tenantList.length === 0 ? (
          <Box sx={webStyle.tenantsContainer}>
            <Box sx={webStyle.noTenantsBlock}>
              <Box sx={webStyle.noTenantsBlockTitle}>
                <img src={noPropertieisIcon} style={webStyle.mainImage} />
                <Typography sx={webStyle.noTenantsTitle}>No tenants</Typography>
                <Typography sx={webStyle.noTenantsSubtitle}>
                  Click on the button below to start adding your tenants
                </Typography>
                <Box sx={webStyle.noTenantsAddButtonBlock}>
                  <Button
                    sx={webStyle.addNewButton}
                    data-test-id="addButton"
                    // type="button"
                    onClick={() => setAddEditTenantsDialogProps({ isAdd: true })}
                  >
                    <Typography sx={webStyle.noTenantsButtonText}>Add tenant</Typography>
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        ) : (
          <>
          <Box sx={webStyle.propertiesTableHeader}>
        <Box sx={webStyle.searchContainer}>
          <Box sx={webStyle.fieldControl}>
            <StyledInput
              data-test-id="search"
              type={"text"}
              placeholder= "Search"
              onChange={(event) => {
                setSearchText(event.target.value);
                currentPage !== 1 && setCurrentPage(1);
              }}
              sx={webStyle.searchElement}
            />
            <img src={searchIcon} alt="" style={webStyle.searchIcon} />
          </Box>
        </Box>
       
        <Box sx={webStyle.actionContainer}>
          <Button
            data-test-id="addTenant"
            sx={webStyle.addPropertiesButton}
            onClick={() => {
              setAddEditTenantsDialogProps({ isAdd: true });
            }}
          >
            <img src={addIcon} alt="" style={webStyle.btnIcon} />
            <Typography sx={webStyle.typographyTitleText}>
              Add tenant
            </Typography>
          </Button>
        </Box>
      </Box>
      <Box>
        <Typography fontFamily={"'Inter', sans-serif"} fontSize={"16px"} color={"#334155"} fontWeight={500} marginBottom={"16px"}>{filteredTenantsList.length} results</Typography>
      </Box>
      {filteredTenantsList.length > 0 && (
        <Box sx={webStyle.tableWrapper(totalPages)}>
          <Box sx={webStyle.propertisTable}>
            <Box sx={webStyle.propertisTableHead}>
              <Box sx={webStyle.propertisTableDataHead}>
                <Typography sx={webStyle.headerTitle}>
                  Tenant name
                </Typography>
                <Typography sx={webStyle.headerTitle}>
                  # of meters
                </Typography>
              </Box>
              <Typography sx={webStyle.headerTitleAction}>
                Actions
              </Typography>
            </Box>
            {displayedTenants.map((tenant: Tenant, index: any) => (
              <Box sx={webStyle.propertisTableBody} key={tenant.id}>
                <Box
                  sx={webStyle.propertisTableDataBody}
                  // onClick={() => goToMeter(tenant.id)}
                >
                  <Box sx={webStyle.propertyItem}>{tenant.tenant_name}</Box>
                  <Box sx={webStyle.propertyItem}>
                    {tenant.meters?.length || 0}
                  </Box>
                </Box>
                <Box sx={webStyle.propertyItemAction}>
                  <Button
                    data-test-id={`addMeter.${index}`}
                    style={{flex: 2}}
                    sx={webStyle.addTenantButton}
                    onClick={() => addMeter(tenant)}
                  >
                    <img src={addIcon} alt="" style={webStyle.btnIcon} />
                    <Typography sx={webStyle.typographyText}>
                      Add meter
                    </Typography>
                  </Button>
                  <Button
                    data-test-id={`editTenant.${index}`}
                    style={{flex: 1}}
                    sx={webStyle.editButton}
                    onClick={() => setAddEditTenantsDialogProps({ initialTenant: tenant })}
                  >
                    <img src={editIcon} alt="" style={webStyle.btnIcon} />
                    <Typography sx={webStyle.typographyText}>
                    Edit
                    </Typography>
                  </Button>
                  <Button
                    data-test-id={`deleteTenant.${index}`}
                    style={{flex: 1}}
                    sx={webStyle.deleteButton}
                    onClick={() => setDeleteTenantDialogId(tenant.id ?? null)}
                    
                  >
                    <img src={deleteIcon} alt="" style={webStyle.btnIcon} />
                    <Typography sx={webStyle.typographyText}>
                    Delete
                    </Typography>
                  </Button>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      )}
      {totalPages > 1 && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "20px",
            marginBottom: "5%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexGrow: 1,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {/* Previous Button */}
            <button
              onClick={() => handlePrevPage()}
              data-test-id="previous-button"
              style={{
                backgroundColor: "inherit",
                border: "none",
                cursor: "pointer",
                padding: 0,
                marginRight: "4px",
              }}
              disabled={currentPage === 1}
            >
              <KeyboardArrowLeftRoundedIcon
                sx={
                  currentPage === 1
                    ? webStyle.chevronIconDisabled
                    : webStyle.chevronIconActive
                }
              />
            </button>

            {totalPages <= 3 ? (
              [...Array(totalPages).keys()].map((page) => (
                <button
                  key={page + 1}
                  data-test-id={"page-button"}
                  style={{
                    marginRight: "4px",
                    width:
                      currentPage === page + 1
                        ? "24px"
                        : "16px",
                    height: "24px",
                    backgroundColor:
                      currentPage === page + 1
                        ? "#DBF5FF"
                        : "inherit",
                    color:
                      currentPage === page + 1
                        ? "#1A7BA4"
                        : "#64748B",
                    border: "none",
                    textDecoration:
                      currentPage !== page + 1
                        ? "underline"
                        : "none",
                    borderRadius: "8px",
                    fontWeight:
                      currentPage !== page + 1 ? 400 : 700,
                    cursor: "pointer",
                    fontSize: "12px",
                    fontFamily: "'Inter', sans-serif",
                  }}
                  onClick={() => handlePageChange(page + 1)}
                >
                  {page + 1}
                </button>
              ))
            ) : (
              <>
                {currentPage !== 1 && (
                  <button
                    data-test-id="ellipsis"
                    style={{
                      marginRight: "4px",
                      width: "16px",
                      height: "24px",
                      backgroundColor: "inherit",
                      color: "#64748B",
                      border: "none",
                      textDecoration: "underline",
                      borderRadius: "8px",
                      cursor: "pointer",
                      fontSize: "12px",
                      fontFamily: "'Inter', sans-serif",
                    }}
                    onClick={() => handlePageChange(1)}
                  >
                    1
                  </button>
                )}

                {currentPage > 3 && (
                  <span style={{ marginRight: "4px", color: "#64748B" }}>
                    ...
                  </span>
                )}

                {currentPage > 2 && (
                  <button
                    data-test-id="middle-page"
                    style={{
                      marginRight: "4px",
                      width: "24px",
                      height: "24px",
                      backgroundColor: "inherit",
                      color: "#64748B",
                      border: "none",
                      textDecoration: "underline",
                      borderRadius: "8px",
                      cursor: "pointer",
                      fontSize: "12px",
                      fontFamily: "'Inter', sans-serif",
                    }}
                    onClick={() =>
                      handlePageChange(currentPage - 1)
                    }
                  >
                    {currentPage - 1}
                  </button>
                )}
                <button
                  data-test-id="middle-page2"
                  style={{
                    marginRight: "4px",
                    width: "24px",
                    height: "24px",
                    backgroundColor: "#DBF5FF",
                    color: "#1A7BA4",
                    border: "none",
                    borderRadius: "8px",
                    cursor: "pointer",
                    fontWeight: 700,
                    fontSize: "12px",
                    fontFamily: "'Inter', sans-serif",
                  }}
                  onClick={() =>
                    handlePageChange(currentPage)
                  }
                >
                  {currentPage}
                </button>

                {currentPage < totalPages - 1 && (
                  <button
                    data-test-id="middle-page3"
                    style={{
                      marginRight: "4px",
                      width: "16px",
                      height: "24px",
                      color: "#64748B",
                      backgroundColor: "inherit",
                      border: "none",
                      textDecoration: "underline",
                      borderRadius: "8px",
                      cursor: "pointer",
                      fontSize: "12px",
                      fontFamily: "'Inter', sans-serif",
                    }}
                    onClick={() =>
                      handlePageChange(currentPage + 1)
                    }
                  >
                    {currentPage + 1}
                  </button>
                )}

                {currentPage < totalPages - 2 && (
                  <span style={{ marginRight: "4px", color: "#64748B" }}>
                    ...
                  </span>
                )}

                {currentPage !== totalPages && (
                  <button
                    data-test-id="last-page"
                    style={{
                      marginRight: "4px",
                      width:
                        currentPage === totalPages
                          ? "24px"
                          : "16px",
                      height: "24px",
                      backgroundColor:
                        currentPage === totalPages
                          ? "#DBF5FF"
                          : "inherit",
                      color:
                        currentPage === totalPages
                          ? "#1A7BA4"
                          : "#64748B",
                      border: "none",
                      textDecoration:
                        currentPage !== totalPages
                          ? "underline"
                          : "none",
                      borderRadius: "8px",
                      cursor: "pointer",
                      fontSize: "12px",
                      fontFamily: "'Inter', sans-serif",
                      fontWeight:
                        currentPage !== totalPages
                          ? 400
                          : 700,
                    }}
                    onClick={() =>
                      handlePageChange(totalPages)
                    }
                  >
                    {totalPages}
                  </button>
                )}
              </>
            )}

            <button
              data-test-id="next-page"
              onClick={() => handleNextPage()}
              style={{
                backgroundColor: "inherit",
                border: "none",
                cursor: "pointer",
                padding: 0,
              }}
              disabled={currentPage === totalPages}
            >
              <KeyboardArrowRightRoundedIcon
                sx={
                  currentPage === totalPages
                    ? webStyle.chevronIconDisabled
                    : webStyle.chevronIconActive
                }
              />
            </button>
          </Box>

          <Box sx={{ marginRight: "10px" }}>
            <Typography
              fontFamily={'"Nunito Sans", sans-serif'}
              fontSize={"12px"}
              color={"grey"}
            >
              {indexOfFirstItem + 1}-{indexOfLastItem} of{" "}
              {filteredTenantsList.length} results
            </Typography>
          </Box>
        </Box>
      )}
          </>
        )}
      <Dialog 
        open={!!addEditTenantsDialogProps}
        onClose={closeAddEditDialog}
        maxWidth="sm" 
        fullWidth 
        PaperProps={{
          style: {
            borderRadius: '8px',
            padding: '32px',
            maxHeight: 'calc(100% - 120px)',
          },
        }}
        data-test-id="addTenantDialog"
      >
        <DialogTitle style={{ padding: '0 0 32px', position: 'relative' }} data-test-id="addTenantDialogTitle">
          <Typography variant="h6" sx={webStyle.dialogTitleText}>
            {`${addEditTenantsDialogProps?.isAdd ? 'Add' : 'Edit'} tenant`}
          </Typography>
          <IconButton
            onClick={closeAddEditDialog}
            style={{ position: 'absolute', right: '0', top: '-8px', width: '56px', height: '56px', backgroundColor: '#F8FAFC', borderRadius: '52px' }}
          >
            <CloseRoundedIcon sx={{ width: '24px', height: '24px', color: '#64748B' }} />
          </IconButton>
        </DialogTitle>
        <Divider sx={{ marginBottom: '22px' }} />
        <DialogContent sx={{ padding: 0, overflowY: 'unset' }} data-test-id="addTenantDialogContent">
        <Box sx={webStyle.addTenantsContainer}>
        <Box sx={webStyle.addTenantsBlock}>
          <Box sx={webStyle.addTenantFormContainer}>
            <Formik
              initialValues={{
                id: addEditTenantsDialogProps?.initialTenant?.id || null,
                tenant_name: addEditTenantsDialogProps?.initialTenant?.tenant_name || "",
                meters: addEditTenantsDialogProps?.initialTenant?.meters?.map((meter) => ({ ...meter, lastReadingAvailable: !!meter.date_of_last_reading })) || [
                  {
                    meter_type: "Water",
                    water_sub_type: "Cold",
                    volume: "Cu ft",
                    metric: "Standard",
                    meter_number: "",
                    lastReadingAvailable: true,
                    last_reading: "",
                    date_of_last_reading: "",
                    meter_note: "",
                  },
                ],
              }}
              validateOnChange={true}
              validationSchema={questionSchema}
              onSubmit={(tenant: Tenant & { meters: (Meter & { lastReadingAvailable: boolean })[] }) => {
                setValidate(false);
                addEditTenant({
                  ...tenant,
                  tenant_name: trimSpaces(tenant.tenant_name || ''),
                  meters: tenant.meters.map((meter) => ({ ...meter, meter_number: trimSpaces(meter.meter_number || ''), date_of_last_reading: meter.date_of_last_reading ? trimSpaces(meter.date_of_last_reading) : null, meter_note: trimSpaces(meter.meter_note || '') }))});
              }}
              data-test-id="addTenantForm"
            >
              {({ errors, values, setFieldValue, handleSubmit }) => (
                <form>
                  <Box sx={webStyle.formContainer}>
                    <Box sx={webStyle.mainFieldControl}>
                      <Typography sx={webStyle.inputLabel}>
                        Tenant name
                      </Typography>
                      <input
                        value={values.tenant_name}
                        style={{...webStyle.inputElemnt, ...(validate && errors.tenant_name ? webStyle.inputElementError : {})}}
                        data-test-id="tenant_name"
                        type="text"
                        onChange={(event) => {
                          setFieldValue("tenant_name", event.target.value);
                        }}
                      />
                      {validate && errors.tenant_name && (
                        <Tooltip
                          open={Boolean(errors.tenant_name)}
                          title={errors.tenant_name}
                          componentsProps={tooltipStyles}
                          arrow
                        >
                          <img
                            src={errorIcon}
                            alt=""
                            style={webStyle.errorIcon}
                          />
                        </Tooltip>
                      )}
                    </Box>
                    <FieldArray name="meters">
                      {(arrayHelpers: any) => (
                        <>
                          {values.meters.map((_: any, index: number) => (
                            <Box sx={webStyle.meterBlcok}>
                              <Box sx={webStyle.meterTitle}>
                                <Typography sx={webStyle.meterTitleLabel}>
                                  Meter #
                                  {index + 1}
                                </Typography>
                                <button
                                  style={webStyle.removeMeeterButton}
                                  type="button"
                                  onClick={() => {
                                    arrayHelpers.remove(index);
                                  }}
                                >
                                  <img
                                    src={deleteIcon}
                                    alt=""
                                    style={webStyle.btnIcon}
                                  />
                                </button>
                              </Box>
                              <Box sx={{...webStyle.arrayFieldControl, margin: 0}}>
                                <Typography sx={webStyle.radioButtonTitleLabel}>
                                  Meter
                                </Typography>
                                <Box sx={webStyle.radioButtonGroup}>
                                  <Box sx={webStyle.radioButtnBlock}>
                                    <Radio
                                      sx={webStyle.radioButtonElemnt}
                                      data-test-id={`meters.${index}.type`}
                                      name={`meter_type.${index}`}
                                      value={"Water"}
                                      checked={"water" === values.meters[index].meter_type?.toLowerCase()}
                                      onChange={(event) => {
                                        setFieldValue(`meters.${index}.meter_type`, event.target.value);
                                        setFieldValue(`meters.${index}.water_sub_type`, 'Cold');
                                        setFieldValue(`meters.${index}.volume`, 'Cu ft');
                                        setFieldValue(`meters.${index}.metric`, 'Standard');
                                      }}
                                    />
                                    <Typography sx={webStyle.radioButtonLabel}>
                                      Water
                                    </Typography>
                                    
                                  </Box>
                                  <Box sx={webStyle.radioButtnBlock}>
                                    <Radio
                                        sx={webStyle.radioButtonElemnt}
                                        data-test-id={`meters.${index}.type.electrical`}
                                        name={`meter_type.${index}`}
                                        value={"Electric"}
                                        checked={"electric" === values.meters[index].meter_type?.toLowerCase()}
                                        onChange={(event) => {
                                          setFieldValue(`meters.${index}.meter_type`, event.target.value);
                                          setFieldValue(`meters.${index}.water_sub_type`, undefined);
                                          setFieldValue(`meters.${index}.volume`, undefined);
                                          setFieldValue(`meters.${index}.metric`, undefined);
                                        }}
                                      />
                                      <Typography sx={webStyle.radioButtonLabel}>
                                        Electrical
                                      </Typography>
                                  </Box>
                                </Box>
                              </Box>
                             
                                {values.meters[index].meter_type === 'Water' && (
                                <>
                                  <Box sx={webStyle.arrayFieldControl}>
                                    <Typography sx={webStyle.radioButtonTitleLabel}>
                                      Type
                                    </Typography>
                                    <Box sx={webStyle.radioButtonGroup}>
                                      <Box sx={webStyle.radioButtnBlock}>
                                        <Radio
                                          sx={webStyle.radioButtonElemnt}
                                          data-test-id={`meters.${index}.waterType.cold`}
                                          id={`meters.${index}.waterType.cold`}
                                          name={`meters.${index}.water_sub_type`}
                                          value="Cold"
                                          checked={values.meters[index].water_sub_type?.toLowerCase() === "cold"}
                                          onChange={(event) => {
                                            setFieldValue(`meters.${index}.water_sub_type`, event.currentTarget.value);
                                          }}
                                        />
                                        <Typography sx={webStyle.radioButtonLabel}>
                                          Cold
                                        </Typography>
                                      </Box>
                                      <Box sx={webStyle.radioButtnBlock}>
                                        <Radio
                                          sx={webStyle.radioButtonElemnt}
                                          data-test-id={`meters.${index}.waterType.hot`}
                                          id={`meters.${index}.waterType.hot`}
                                          name={`meters.${index}.water_sub_type`}
                                          value="Hot"
                                          checked={values.meters[index].water_sub_type?.toLowerCase() === "hot"}
                                          onChange={(event) => {
                                            setFieldValue(`meters.${index}.water_sub_type`, event.currentTarget.value);
                                          }}
                                        />
                                        <Typography sx={webStyle.radioButtonLabel}>
                                          Hot
                                        </Typography>
                                      </Box>
                                    </Box>
                                  </Box>
                                  <Box sx={webStyle.arrayFieldControl}>
                                    <Typography sx={webStyle.radioButtonTitleLabel}>
                                      Volume
                                    </Typography>
                                    <Box sx={webStyle.radioButtonGroup}>
                                      <Box sx={webStyle.radioButtnBlock}>
                                        <Radio
                                          sx={webStyle.radioButtonElemnt}
                                          data-test-id={`meters.${index}.waterVolume.cubicFeet`}
                                          id={`meters.${index}.waterVolume.cubicFeet`}
                                          name={`meters.${index}.volume`}
                                          value="Cu ft"
                                          checked={values.meters[index].volume?.toLowerCase() === "cu ft" || values.meters[index].volume?.toLowerCase() === "cu_ft"}
                                          onChange={(event) => {
                                            setFieldValue(`meters.${index}.volume`, event.currentTarget.value);
                                          }}
                                        />
                                        <Typography sx={webStyle.radioButtonLabel}>
                                          Cu ft
                                        </Typography>
                                      </Box>
                                      <Box sx={webStyle.radioButtnBlock}>
                                        <Radio
                                          sx={webStyle.radioButtonElemnt}
                                          data-test-id={`meters.${index}.waterVolume.gallons`}
                                          id={`meters.${index}.volume.gallons`}
                                          name={`meters.${index}.volume`}
                                          value="Gallons"
                                          checked={values.meters[index].volume?.toLowerCase() === "gallons"}
                                          onChange={(event) => {
                                            setFieldValue(`meters.${index}.volume`, event.currentTarget.value);
                                          }}
                                        />
                                        <Typography sx={webStyle.radioButtonLabel}>
                                          Gallons
                                        </Typography>
                                      </Box>
                                    </Box>
                                  </Box>
                                  <Box sx={webStyle.arrayFieldControl}>
                                    <Typography sx={webStyle.radioButtonTitleLabel}>
                                      Metric
                                    </Typography>
                                    <Box sx={webStyle.radioButtonGroup}>
                                      <Box sx={webStyle.radioButtnBlock}>
                                        <Radio
                                          sx={webStyle.radioButtonElemnt}
                                          data-test-id={`meters.${index}.waterMetric.Standard`}
                                          id={`meters.${index}.waterMetric.Standard`}
                                          name={`meters.${index}.metric`}
                                          value="Standard"
                                          checked={values.meters[index].metric?.toLowerCase() === "standard"}
                                          onChange={(event) => {
                                            setFieldValue(`meters.${index}.metric`, event.currentTarget.value);
                                          }}
                                        />
                                        <Typography sx={webStyle.radioButtonLabel}>
                                          Standard
                                        </Typography>
                                      </Box>
                                      <Box sx={webStyle.radioButtnBlock}>
                                        <Radio
                                          sx={webStyle.radioButtonElemnt}
                                          data-test-id={`meters.${index}.waterMetric.10cuft`}
                                          id={`meters.${index}.waterMetric.10cuft`}
                                          name={`meters.${index}.metric`}
                                          value="10 cu.ft/gallons"
                                          checked={values.meters[index].metric?.toLowerCase() === "10 cu.ft/gallons" || values.meters[index].metric?.toLowerCase() === "ten_cuft_gallons"}
                                          onChange={(event) => {
                                            setFieldValue(`meters.${index}.metric`, event.currentTarget.value);
                                          }}
                                        />
                                        <Typography sx={webStyle.radioButtonLabel}>
                                          10 cu.ft/gallons
                                        </Typography>
                                      </Box>
                                      <Box sx={webStyle.radioButtnBlock}>
                                        <Radio
                                          sx={webStyle.radioButtonElemnt}
                                          data-test-id={`meters.${index}.waterMetric.100cuft`}
                                          id={`meters.${index}.metric.100cuft`}
                                          name={`meters.${index}.metric`}
                                          value="100 cu.ft/gallons"
                                          checked={values.meters[index].metric?.toLowerCase() === "100 cu.ft/gallons" || values.meters[index].metric?.toLowerCase() === "hundred_cuft_gallons"}
                                          onChange={(event) => {
                                            setFieldValue(`meters.${index}.metric`, event.currentTarget.value);
                                          }}
                                        />
                                        <Typography sx={webStyle.radioButtonLabel}>
                                          100 cu.ft/gallons
                                        </Typography>
                                      </Box>
                                    </Box>
                                  </Box>
                                </>
                              )}

                              <Box sx={{...webStyle.arrayFieldControl, margin: '16px 0 10px'}}>
                                <Typography sx={webStyle.inputLabel}>
                                 Meter number
                                </Typography>
                                <input
                                  style={{...webStyle.inputElemnt1, ...(validate && (errors.meters?.[index] as any)?.meter_number ? webStyle.inputElementError : {})}}
                                  data-test-id={`meters.${index}.meterNumner`}
                                  type="text"
                                  value={values?.meters?.[index]?.meter_number}
                                  onChange={(event) => {
                                    setFieldValue(`meters.${index}.meter_number`, event.target.value);
                                  }}
                                />
                                {validate && (errors.meters?.[index] as any)?.meter_number && (
                                  <Tooltip
                                    open={true}
                                    title={(errors.meters?.[index] as any)?.meter_number}
                                    componentsProps={tooltipStyles}
                                    arrow
                                  >
                                    <img
                                      src={errorIcon}
                                      alt=""
                                      style={webStyle.errorIcon}
                                    />
                                  </Tooltip>
                                )}
                                </Box>
                                <Box sx={webStyle.arrayFieldControl}>
                                  <Typography sx={webStyle.radioButtonTitleLabel}>
                                    Last reading available
                                  </Typography>
                                  <Box sx={webStyle.lastReadingAvailableGroup}>
                                    <Box
                                      sx={webStyle.lastReadingAvailableBlock}
                                    >
                                      <Radio
                                        checked={values?.meters?.[index]?.lastReadingAvailable}
                                        sx={webStyle.radioButtonElemnt}
                                        data-test-id={`meters.${index}.lastReadingAvailable.avaliable`}
                                        name={`meterType.${index}`}
                                        onChange={() => {
                                          setFieldValue(
                                            `meters.${index}.lastReadingAvailable`,
                                            true
                                          );
                                        }}
                                      />
                                      <Typography sx={webStyle.radioButtonLabel}>
                                        Yes
                                      </Typography>
                                    </Box>
                                    <Box
                                      sx={webStyle.lastReadingAvailableBlock}
                                    >
                                      <Radio
                                        checked={!values?.meters?.[index]?.lastReadingAvailable}
                                        sx={webStyle.radioButtonElemnt}
                                        data-test-id={`meters.${index}.lastReadingAvailable.notAvailable`}
                                        name={`meterType.${index}`}
                                        onChange={() => {
                                          setFieldValue(
                                            `meters.${index}.lastReadingAvailable`,
                                            false
                                          );
                                          setFieldValue(
                                            `meters.${index}.last_reading`,
                                            ""
                                          );
                                          setFieldValue(
                                            `meters.${index}.date_of_last_reading`,
                                            ""
                                          );
                                        }}
                                      />
                                      <Typography sx={webStyle.radioButtonLabel}>
                                        No
                                      </Typography>
                                    </Box>
                                  </Box>
                                </Box>
                                <Box sx={webStyle.arrayFieldControl}>
                                  <Typography sx={webStyle.inputLabel}>
                                    Last reading
                                  </Typography>
                                  <input
                                    style={{...webStyle.inputElemnt1, ...(validate && (errors.meters?.[index] as any)?.last_reading ? webStyle.inputElementError : {})}}
                                    data-test-id={`meters.${index}.lastReading`}
                                    type="text"
                                    value={values?.meters?.[index]?.last_reading ? values?.meters?.[index]?.last_reading : ""}
                                    onChange={(event) => {
                                      setFieldValue(`meters.${index}.last_reading`, event.target.value);
                                    }}
                                    disabled={!values?.meters?.[index]?.lastReadingAvailable}
                                  />
                                  {validate && (errors.meters?.[index] as any)?.last_reading && (
                                    <Tooltip
                                      open={true}
                                      title={(errors.meters?.[index] as any)?.last_reading}
                                      componentsProps={tooltipStyles}
                                      arrow
                                    >
                                      <img
                                        src={errorIcon}
                                        alt=""
                                        style={webStyle.errorIcon}
                                      />
                                    </Tooltip>
                                  )}
                                </Box>
                                <Box sx={webStyle.arrayFieldControl}>
                                  <Typography sx={webStyle.inputLabel}>
                                    Date of last reading
                                  </Typography>
                                  <StyledInput
                                    data-test-id={`meters.${index}.lastReadingDate`}
                                    type="date"
                                    placeholder="mm/dd/yyyy"
                                    inputProps={{ max: moment().format('YYYY-MM-DD') }}
                                    onChange={(event) => {
                                      setFieldValue(`meters.${index}.date_of_last_reading`, event.target.value);
                                    }}
                                    value={values?.meters?.[index]?.date_of_last_reading ? moment(values?.meters?.[index]?.date_of_last_reading).format('YYYY-MM-DD') : ''}
                                    style={!values.meters[index].date_of_last_reading ? { color: '#94A3B8' } : undefined}
                                    sx={{...(validate && (errors.meters?.[index] as any)?.date_of_last_reading ? webStyle.inputElementError : undefined), ...(!values?.meters?.[index]?.lastReadingAvailable ? webStyle.inputDisabled : undefined)}}
                                    disabled={!values?.meters?.[index]?.lastReadingAvailable}
                                  />
                                  <Tooltip
                                    open={validate && !!(errors.meters?.[index] as any)?.date_of_last_reading}
                                    title={(errors.meters?.[index] as any)?.date_of_last_reading}
                                    componentsProps={tooltipStyles}
                                    arrow
                                  >
                                    <img
                                      src={validate && !!(errors.meters?.[index] as any)?.date_of_last_reading ? calendarErrorIcon : caledarIcon}
                                      alt=""
                                      style={{...webStyle.calendarIcon as any, ...(!values?.meters?.[index]?.lastReadingAvailable ? webStyle.inputDisabled : undefined)}}
                                    />
                                  </Tooltip>
                                </Box>
                                <Box sx={webStyle.arrayFieldControl}>
                                  <Typography sx={webStyle.inputLabel}>
                                    Meter note (eg.: Meter location, access code)
                                  </Typography>
                                  <input
                                    style={webStyle.inputElemnt1}
                                    data-test-id={`meters.${index}.meterNote`}
                                    type="text"
                                    value={values?.meters?.[index]?.meter_note}
                                    onChange={(event) => {
                                      setFieldValue(`meters.${index}.meter_note`, event.target.value);
                                    }}
                                  />
                                </Box>
                              </Box>
                            ))}
                            <Box sx={webStyle.fieldControl1}>
                              <button
                                style={webStyle.addNewMeterButton}
                                type="button"
                                onClick={() => {
                                  arrayHelpers.push({
                                    meter_type: "Water",
                                    water_sub_type: 'Cold',
                                    volume: "Cu ft",
                                    metric: "Standard",
                                    meter_number: "",
                                    last_reading: "",
                                    date_of_last_reading: "",
                                    meter_note: '',
                                  });
                                }}
                              >
                                  <AddIcon style={{...webStyle.btnIcon,color:"#1A7BA4"}} />
                                <Typography sx={webStyle.typographyTitleText1}>
                                Add meter
                                </Typography>
                              </button>
                            </Box>
                          </>
                        )}
                      </FieldArray>
                      <Box sx={webStyle.fieldControlGroup}>
                        <Button
                          sx={webStyle.cancelButton}
                          data-test-id="cancelBtn"
                          // type="Button"
                          onClick={closeAddEditDialog}
                        >
                         Cancel
                        </Button>
                        <Button
                          sx={webStyle.submitButton}
                          data-test-id="submitBtn"
                          // type="Button"
                          onClick={() => {
                            setValidate(true);
                            if (Object.keys(errors).length == 0) {
                              handleSubmit();
                            }
                          }}
                        >
                         Save
                        </Button>
                      </Box>
                    </Box>
                  </form>
                )}
              </Formik>
            </Box>
          </Box>
        </Box>


        </DialogContent>
      </Dialog>



      <Dialog
              open={deleteTenantDialogId !== null}
              onClose={() => setDeleteTenantDialogId(null)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              data-test-id="deleteDialog"
              PaperProps={{
                style: {
                  width: "633px", 
                  padding: "40px 32px 32px 32px",
                  borderRadius: "16px", 
                  fontFamily:"'Inter', sans-serif",
                },
              }}
            >
        <DialogTitle 
          id="alert-dialog-title" 
          // style={{ fontWeight: "600", padding: '0 0 24px',fontFamily:"'Inter', sans-serif", fontSize:'24px', letterSpacing: '-1px' }}

        sx={webStyle.dialogTitle} 
        >
          Are you sure you want to delete this tenant?
        </DialogTitle>
        <DialogContent 
          sx={webStyle.dialogContent}
          // style={{ marginBottom: '32px', padding: 0 }}
        >
          <DialogContentText 
            id="alert-dialog-description"
            style={{ color: "#64748B", fontFamily:"'Inter', sans-serif",fontSize:"18px"}}

          >
            This action can’t be undone, and all the data linked to this tenant will be permanently deleted and no longer accessible.
          </DialogContentText>
          <Divider style={{ marginTop: '32px' }} />
          {/* <Divider /> */}
        </DialogContent>
        <DialogActions 
          // style={{ padding: 0 }}
          sx={webStyle.dialogAction}
        >
          <Button
            onClick={() => setDeleteTenantDialogId(null)}
            variant="outlined" 
            sx={webStyle.dialogCancelButton}
          >
            Cancel
          </Button>
          <Button
            onClick={() => deleteTenantDialogId !== null && deleteTenant(deleteTenantDialogId)}
            sx={webStyle.confirmDelete}
            startIcon={<DeleteIcon />} 
            data-test-id="deleteDialogButton"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
    );
  };

  const StyledInput = styled(InputBase)(() => ({
    height: "56px",
    padding: "10px",
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
    backgroundColor: '#FFFFFF',
    fontFamily: "'Inter', sans-serif",

    '& .MuiInputBase-input': {
      fontSize: '16px',

      '&::placeholder': {
        fontFamily: "'Inter', sans-serif",
        color: '#94A3B8',
        fontSize: '16px',
        opacity: 1, // Firefox requires this
      },
      '&:disabled': {
        backgroundColor: '#F5F5F4',
      }
    },
  }));

  const tooltipStyles = {
    tooltip: {
      sx: {
        color: '#FFFFFF',
        fontFamily: "'Inter', sans-serif",
        fontSize: '13px',
        lineHeight: '18px',
        fontWeight: '400',
        padding: '8px 12px',
        backgroundColor: '#1E293B',
        borderRadius: '8px',
        width: 'fit-content',
        maxWidth: '480px',
        '& .MuiTooltip-arrow': {
          color: '#1E293B',
        },
      }
    }
  };

  const webStyle = {
    dialogTitleText: {
      fontWeight: '700',
      fontSize: '24px',
      fontFamily:"'Inter', sans-serif",
     },
    tenantsContainer: {
      display: "flex",
      flexDirection: "column",
      backgroundColor: "rgba(248, 250, 252, 1)",
    },
    tenantTitleContainer: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      margin: "20px 0px",
      justifyContent: "space-between",
    },
    propertiesTableHeader: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      justifyContent: "space-between",
      margin: "20px 0px",
      "@media (max-width: 767px)": {
        flexDirection: "column",
      }
    },
    searchContainer: {
      width: "340px",
      display: "flex",
      flexDirection: "row",
      "@media (max-width: 767px)": {
        width: "100%",
      }
    },
    actionContainer: {
      display: "flex",
      flexDirection: "row",
      "@media (max-width: 767px)": {
        width: "100%",
      }
    },
    tenantActionContainer: {
      display: "flex",
      flexDirection: "column",
    },
    tableWrapper: (totalPages: number) => ({
      minHeight: totalPages > 1 ? '674px' : 'unset',
      marginBottom: "30px",
      "@media (max-width: 1330px)": {
        // "@media (max-width: 767px)": {
        overflow: "auto",
      },
    }),
    propertisTable: {
      border: "1px solid #CBD5E1",
      borderRadius: "12px",
      minWidth: "620px",
      "@media (max-width: 969px)": {
        minWidth: "435px",
      },
    },
    propertisTableHead: {
      display: "flex",
      flexDirection: "row",
      padding: "0px 16px",
      backgroundColor: 'rgba(248, 250, 252, 1)',
      borderRadius: '12px 12px 0 0',
    },
    propertisTableDataHead: {
      display: "flex",
      flexDirection: "row",
      width: "calc(100% - 340px)",
      "@media (max-width: 969px)": {
        width: "calc(100% - 120px)",
        // width: "120px",
      }
    },
    propertisTableBody: {
      display: "flex",
      flexDirection: "row",
      padding: "0px 16px",
      borderTop: "1px solid #CBD5E1",
      backgroundColor: '#FFFFFF',
      '&:last-of-type': {
        borderRadius: '0 0 12px 12px',
      }
    },
    propertisTableDataBody: {
      display: "flex",
      flexDirection: "row",
      width: "calc(100% - 340px)",
      padding: "0px 10px",
      "@media (max-width: 969px)": {
        width: "calc(100% - 120px)",
      }
    },
    fieldControl: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
      marginTop: "10px",
      marginBottom: "10px",
      position: "relative",
    },
    inputElemnt: {
      height: "56px",
      padding: "10px",
      borderRadius: "8px",
      border: "1px solid #CBD5E1",
      fontFamily: "'Inter', sans-serif",
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "24px",
    },
    searchElement: {
      padding: "12px 44px 12px 12px",
      height: '44px',
    },
    searchIcon: {
      width: "24px",
      height: "24px",
      position: "absolute" as "absolute",
      top: 10,
      right: 16,
    },
    btnIcon: {
      width: "24px",
      height: "24px",
    },
    backButton: {
      fontFamily: "'Inter', sans-serif",
      fontSize: "16px",
      fontWeight: 700,
      lineHeight: "24px",
      color: "#1A7BA4",
      border: "none",
      backgroundColor: "#F8FAFC",
      display: "flex",
      flexDirection: "row" as const,
      height: "44px",
      alignItems: "center",
      margin: "10px 0px",
      padding: "6px 0px",
    },
    addPropertiesButton: {
      fontFamily: "'Inter', sans-serif",
      fontSize: "16px",
      fontWeight: 700,
      lineHeight: "22px",
      color: "#FFFFFF",
      border: "none",
      borderRadius: "7px",
      backgroundColor: "#1A7BA4",
      display: "flex",
      flexDirection: "row" as const,
      height: "44px",
      alignItems: "center",
      margin: "10px 0px",
      padding: "6px 12px",
      textTransform: "none",
      "@media (max-width: 767px)": {
      // "@media (max-width: 969px)": {
        width: "100%",
      },
      "&:hover": {
      backgroundColor: "#1A7BA4",
    },
    },
    headerTitle: {
      fontFamily: "'Inter', sans-serif",
      fontSize: "12px",
      fontWeight: 700,
      lineHeight: "18px",
      textTransform: "uppercase" as const,
      color: "#64748B",
      width: "50%",
      overflow: "hidden",
      margin: "12px 0",
    },
    headerTitleAction: {
      fontFamily: "'Inter', sans-serif",
      fontSize: "12px",
      fontWeight: 700,
      lineHeight: "18px",
      textTransform: "uppercase" as const,
      color: "#64748B",
      width: "340px",
      overflow: "hidden",
      margin: "12px 0",
      "@media (max-width: 969px)": {
        width: "120px",
      }
    },
    addTenantButton: {
      backgroundColor: "#1A7BA4",
      color: "#FFFFFF",
      fontFamily: "'Inter', sans-serif",
      fontSize: "14px",
      fontWeight: 600,
      lineHeight: "22px",
      padding: "6px 10px 6px 10px",
      borderRadius: "7px",
      border: "none",
      display: "flex",
      flexDirection: "row" as const,
      height: "36px",
      alignItems: "center",
      textTransform: "none",
      "@media (max-width: 969px)": {
        minWidth: "32px",
        width: "32px",
      },
      "&:hover": {
        backgroundColor: "#1A7BA4",
      },
    },
    editButton: {
      backgroundColor: "#FFFFFF",
      color: "#1A7BA4",
      fontFamily: "'Inter', sans-serif",
      fontSize: "14px",
      fontWeight: 600,
      lineHeight: "22px",
      padding: "6px 10px 6px 10px",
      borderRadius: "7px",
      border: "1px solid #1A7BA4",
      display: "flex",
      flexDirection: "row" as const,
      height: "36px",
      alignItems: "center",
      textTransform: "none",
      "@media (max-width: 969px)": {
        minWidth: "32px",
        width: "32px",
      },
      gap: '6px'
    },
    deleteButton: {
      backgroundColor: "#FFFFFF",
      color: "#DC2626",
      fontFamily: "'Inter', sans-serif",
      fontSize: "14px",
      fontWeight: 600,
      lineHeight: "22px",
      padding: "6px 10px 6px 10px",
      borderRadius: "7px",
      border: "1px solid #DC2626",
      display: "flex",
      flexDirection: "row" as const,
      height: "36px",
      alignItems: "center",
      textTransform: "none",
      "@media (max-width: 969px)": {
        minWidth: "32px",
        width: "32px",
      },
      gap: '6px'
    },
    propertyItem: {
      fontFamily: "'Inter', sans-serif",
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "22px",
      color: "#0F172A",
      width: "50%",
      margin: "13px 0",
      alignItems: "center",
      display: "flex",
      wordBreak: 'break-word',
    },
    propertyItemAction: {
      fontFamily: "'Inter', sans-serif",
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "22px",
      color: "#0F172A",
      width: "340px",
      display: "flex",
      flexDirection: "row",
      overflow: "hidden",
      margin: "13px 0",
      alignItems: "center",
      gap: "8px",
      "@media (max-width: 969px)": {
        width: "120px",
      }
    },
    tenantTitle: {
      fontFamily: "'Inter', sans-serif",
      fontSize: "24px",
      fontWeight: 600,
      lineHeight: "29px",
      color: "#0F172A",
      wordBreak: 'break-word'
    },
    tenantSubTitle: {
      fontFamily: "'Inter', sans-serif",
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "24px",
      color: "#64748B",
      wordBreak: 'break-word',
    },
    typographyTitleText: {
      fontFamily: "'Inter', sans-serif",
      fontSize: "16px",
      fontWeight: 700,
      lineHeight: "24px",
      marginLeft: "6px",
      overflow: "hidden",
    },
    typographyText: {
      fontFamily: "'Inter', sans-serif",
      fontSize: "14px",
      fontWeight: 600,
      lineHeight: "22px",
      "@media (max-width: 969px)": {
        display: "none"
      },
    },
    addTenantsContainer: {
      display: "flex",
      flexDirection: "row",
    },
    addTenantsBlock: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      width: "100%",
    },
    addTenantFormContainer: {
      maxWidth: "600px",
      width: '100%',
    },
    formContainer: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
    },
    formTitle: {
      fontFamily: "'Inter', sans-serif",
      fontSize: "24px",
      fontWeight: 700,
      lineHeight: "32px",
      marginBottom: "10px",
      wordBreak: 'break-word',
    },
    formSubTitle: {
      fontFamily: "'Inter', sans-serif",
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "24px",
      color: "#475467",
      marginBottom: "20px",
      wordBreak: 'break-word',
    },
    fieldControl1: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
      margin: "10px",
      position: "relative",
    },
    mainFieldControl: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
      margin: "10px 0px",
      position: "relative",
    },
    arrayFieldControl: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
      margin: "10px 0px",
      position: "relative",
    },
    fieldControlGroup: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
      "@media (max-width: 767px)": {
        width: '100%',
      //   flexDirection: "column",
      }
    },
    fieldCheckboxControl: {
      display: "flex",
      flex: 1,
      flexDirection: "row",
      margin: "10px",
      position: "relative",
    },
    switchTitle: {
      alignItems: "center",
      display: "flex",
    },
    radioButtonTitleLabel: {
      fontFamily: "'Inter', sans-serif",
      fontSize: "14px",
      fontWeight: 700,
      lineHeight: "22px",
      color: "#64748B",
      margin: "2px 0px 12px",
      alignItems: "center",
      display: "flex",
    },
    inputLabel: {
      fontFamily: "'Inter', sans-serif",
      fontSize: "14px",
      fontWeight: 700,
      lineHeight: "22px",
      color: "#334155",
      marginBottom: "5px",
      alignItems: "center",
      display: "flex",
      "@media (max-width: 767px)": {
        fontSize: "14px",
        // fontWeight: 700,
        lineHeight: "20px",
      }
    },
    radioButtonLabel: {
      fontFamily: "'Inter', sans-serif",
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "24px",
      color: "#0F172A",
      alignItems: "center",
      display: "flex",
      marginLeft: '8px',
      "@media (max-width: 767px)": {
        fontSize: "14px",
        marginTop: "4px",
        // fontWeight: 700,
        // lineHeight: "20px",
      }
    },
    radioButtnBlock: {
      display: "flex",
      flexDirection: "row",
      flex: '1',
      alignItems: 'center',
    },
    numberInputElemnt: {
      height: "56px",
      padding: "10px",
      border: "none",
      "-moz-appearance": "textfield",
      "& ::-webkit-outer-spin-button": {
        "-webkit-appearance": "none",
        margin: 0,
      },
      "& ::-webkit-inner-spin-button": {
        "-webkit-appearance": "none",
        margin: 0,
      },
      width: "34%",
      textAlign: "center" as const,
    },
    inputElemnt1: {
      height: "56px",
      padding: "10px",
      borderRadius: "8px",
      border: "1px solid #CBD5E1",
      fontSize: '16px',
      '&:disabled': {
        backgroundColor: '#F5F5F4',
      },
    },
    inputElementError: {
      border: "1px solid #F87171",
      padding: '10px 52px 10px 10px'
    },
    radioButtonElemnt: {
      padding: 0,
      width: '20px',
      height: '20px',
      marginTop: "4px",
      '& .MuiSvgIcon-root': {
        color: '#64748B',
        width: '20px',
        height: '20px',
      },
      '&.Mui-checked': {
        backgroundColor: '#1A7BA4',
        '& .MuiSvgIcon-root': {
          width: '20px',
          height: '20px',
        },
        '& .MuiSvgIcon-root:first-of-type': {
          color: '#1A7BA4',
        },
        '& .MuiSvgIcon-root:nth-of-type(2)': {
          color: '#FFFFFF',
        }
      },
    },
    errorIcon: {
      width: "24px",
      height: "24px",
      position: "absolute" as "absolute",
      top: 43,
      right: 16,
    },
    numberFieldControlIcon: {
      width: "24px",
      height: "24px",
    },
    numberFieldControlButton: {
      color: "#1A7BA41A",
      border: "none",
      width: "33%",
    },
    cancelButton: {
      fontFamily: "'Inter', sans-serif",
      fontSize: "16px",
      fontWeight: 700,
      lineHeight: "24px",
      margin: "10px",
      height: "44px",
      borderRadius: "8px",
      border: "1px solid #1A7BA4",
      background: "#FFFFFF",
      color: "#1A7BA4",
      padding: "0px 20px",
      textTransform: "none",
      "@media (max-width: 767px)": {
        width: '100%',
      }
    },
    submitButton: {
      fontFamily: "'Inter', sans-serif",
      fontSize: "16px",
      fontWeight: 700,
      lineHeight: "24px",
      margin: "10px",
      height: "44px",
      borderRadius: "8px",
      border: "none",
      background: "#1A7BA4",
      color: "#FFFFFF",
      padding: "0px 20px",
      textTransform: "none",
      "@media (max-width: 767px)": {
        width: '100%',
      },
      "&:hover": {
        backgroundColor: "#1A7BA4",
      },
    },
    meterToReadBlcok: {
      border: "1px solid #CBD5E1",
      borderRadius: "8px",
      display: "flex",
      flexDirection: "row",
    },
    meterBlcok: {
      border: "1px solid #1A7BA44D",
      backgroundColor: "#1A7BA40F",
      padding: "16px",
      borderRadius: "12px",
      margin: "20px 0px",
    },
    meterTitle: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    meterTitleLabel: {
      fontFamily: "'Inter', sans-serif",
      fontSize: "18px",
      fontWeight: 700,
      lineheight: "24px",
      color: "#0F172A",
    },
    addNewMeterButton: {
      fontFamily: "'Inter', sans-serif",
      fontSize: "16px",
      fontWeight: 700,
      lineHeight: "24px",
      margin: "15px",
      height: "56px",
      borderRadius: "8px",
      border: "none",
      background: "inherit",
      color: "#1A7BA4",
      padding: "0px 20px",
      flexDirection: "row" as const,
      display: "flex",
      alignItems: "center",
      flex: 1,
      justifyContent: "center",
      "&:hover": {
        backgroundColor: "#1A7BA4",
      },
    },
    removeMeeterButton: {
      height: "36px",
      width: "46px",
      border: "none",
      backgroundColor: "inherit",
    },
    typographyTitleText1: {
      fontFamily: "'Inter', sans-serif",
      fontSize: "16px",
      fontWeight: 700,
      lineHeight: "22px",
    },
    calendarIcon: {
      width: "24px",
      height: "24px",
      position: "absolute" as "absolute",
      top: 43,
      right: 13,
      backgroundColor: '#FFFFFF',
      pointerEvents: 'none',
    },
    radioButtonBlock: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '8px',
    },
    radioButtonElement: {
      marginRight: '8px',
    },
    confirmDelete:{
       padding: "6px 12px",
       borderRadius: "5px",
       textTransform: "none",
       backgroundColor:"white",
       color:"#DC2626",
       border:"1px solid #DC2626",
       fontFamily:"'Inter', sans-serif",
       fontWeight:"600",
       height: '44px',
       marginLeft: '16px !important',
       "@media (max-width: 767px)": {
        // marginLeft: '0px !important',
        // marginRight: "6px",
        // marginTop: '10px !important',
        width: "100%"
      }
    },
   dialogCancelButton:{
       color: "#1A7BA4",
       border: "1px solid #1A7BA4",
       padding: "6px 12px",
       borderRadius: "5px",
       textTransform: "none",
       fontWeight:"700",
       fontFamily:"'Inter', sans-serif",
       fontSize: '16px',
       height: '44px',
      "@media (max-width: 767px)": {
        // marginRight: "6px",
        width: "100%"
      }
   },
   chevronIconActive: {
     "> path": {
       fill: "#475569",
     },
   },
   chevronIconDisabled: {
     "> path": {
       fill: "#64748B",
     },
   },
   radioButtonGroup: {
    display: 'flex',
    justifyContent: 'space-between',
    "@media (max-width: 767px)": {
      flexDirection: "column",
    }
   },
   loaderWrapper: {
    margin: '200px auto 0',
    width: 'fit-content',
   },
   loader: {
    color: '#1A7BA4',
    height: '60px',
    width: '60px',
   },
   noTenantsBlock: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    maxWidth: "600px",
    width: "80%",
  },
  noTenantsBlockTitle: {
    margin: "80px 10px",
    textAlign: "center",
    "@media (max-width: 500px)": {
      margin: "10px",
    }
  },
  noTenantsTitle: {
     fontFamily: "'Inter', sans-serif",
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: "32px",
    marginBottom: "15px",
    marginTop: "50px",
    "@media (max-width: 500px)": {
      fontSize: "20px",
      lineHeight: "24px",
    }
  },
  noTenantsSubtitle: {
     fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#475467",
    marginBottom: "20px",
    "@media (max-width: 500px)": {
      fontSize: "14px",
      lineHeight: "20px",
    }
  },
  mainImage: {
    width: "163px",
    height: "154px",    
  },
  addNewButton: {
     fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    margin: "15px auto",
    height: "56px",
    borderRadius: "8px",
    border: "none",
    background: "#1A7BA4",
    color: "#FFFFFF",
    padding: "0px 20px",
    flexDirection: "row" as const,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "430px",
    textTransform: "capitalize",
    "@media (max-width: 500px)": {
      fontSize: "14px",
      width: "100%"
    },
    "&:hover": {
      backgroundColor: "#1A7BA4",
    },
  },
  noTenantsButtonText: {
     fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
  },
  noTenantsAddButtonBlock: {
    display: "flex",
    flexDirection: "row",
  },
  lastReadingAvailableBlock: {
    display: "flex",
    flexDirection: "row",
    margin: "10px 0px",
    alignItems: "center",
    width: "131px",
    flex: "none",
  },
  lastReadingAvailableGroup: {
    display: "flex",
    justifyContent: "left",
  },
  inputDisabled: {
    backgroundColor: '#F5F5F4',
  },
  dialogTitle: { 
    // fontWeight: "600", 
    padding: '0 0 24px',
    // fontFamily:"'Inter', sans-serif", 
    // fontSize:'24px', 
    // letterSpacing: '-1px' ,


    fontWeight: "600",
    // padding: "10px 20px",
    fontFamily: "'Inter', sans-serif",
    fontSize: "24px",
    letterSpacing: "-1px",
    "@media (max-width: 767px)": {
      fontSize:'17px',
    }
  },
  dialogContent: { 
    // marginBottom: "32px", 
    // padding: 0,
    color: "#64748B",
    // padding: "10px 0px",
    marginBottom: '32px', 
    padding: 0,
    // paddingBottom: "16px",
    fontFamily:"'Inter', sans-serif",
    fontSize:"18px",
    "@media (max-width: 767px)": {
      fontSize:"16px",
    }
  },
  dialogAction: {
    padding: 0,
    // padding: "0px 20px",
    "@media (max-width: 767px)": {
      flexDirection: "row",
      width: "100%",
    },
  },
  };
 