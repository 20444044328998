Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.supportRequestAPiEndPoint = "bx_block_contact_us/support_requests";
exports.supportRequestAPiMethod = "POST";
exports.supportRequestApiContentType = "application/json";

exports.supportTitleText = "Support";
exports.servicesNeededText = "Services needed";
exports.messageText = "Message";
exports.sendText = "Send";

// exports.confirmPasswordPlaceholderText
// Customizable Area End
