Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.getPropertiesApiEndPoint = "bx_block_custom_forms/properties";
exports.getPropertiesApiMethod = "Get";
exports.getPropertiesApiContentType = "application/json";

exports.getPdfUrlApiEndPoint = "bx_block_custom_forms/reports";
exports.getPdfUrlApiMethod = "Get";
exports.getPdfUrlApiContentType = "application/json";

exports.getInvoicePdfUrlApiEndPoint = "bx_block_custom_forms/invoices";
exports.getInvoicePdfUrlApiMethod = "Get";
exports.getInvoicePdfUrlApiContentType = "application/json";

exports.deletePropertiesApiEndPoint = "bx_block_custom_forms/reports";
exports.deletePropertiesApiMethod = "DELETE"
exports.deletePropertiesApiContentType = "application/json";

exports.readMeterNoteApiEndPoint = "read_meter_reading_note";
exports.readMeterNoteApiMethod = "GET";
exports.readMeterNoteApiContentType = "application/json";

exports.noPropertyTitleText = "No customers";
exports.noPropertySubTitleText = "Click on the button below to start adding your properties";
exports.searchPlaceholderText = "Search";
exports.filterText = "Filter";
exports.addPropertyText = "Add property";
exports.companyNameText = "COMPANY NAME";
exports.addressText = "SERVICE ADDRESS";
exports.createdAtText = "CREATED AT";
exports.lastLoggedInText = "LAST LOGGED IN";
exports.actionsText = "ACTIONS";
exports.viewDetailsText = "View details";

exports.customerPropertiesEndPoint = "bx_block_custom_forms/properties";
exports.companyDetailsEndPoint = "bx_block_custom_forms/company_details";
exports.editCOpanyDetailsEndPoint = "bx_block_custom_forms/company_details";
exports.assignBookNumberToProperty = "bx_block_custom_forms/properties/assign_book_number_to_property";
exports.propertyTenants = (propertyId) => `bx_block_custom_forms/properties/${propertyId}/tenants`;

exports.getMeters = (propertyId, tenantId) => `bx_block_custom_forms/properties/${propertyId}/tenants/${tenantId}/meters`;
exports.createMeter = (propertyId, tenantId) => `bx_block_custom_forms/properties/${propertyId}/tenants/${tenantId}/meters`;
exports.deleteMeter = (propertyId, tenantId, meterId) => `/bx_block_custom_forms/properties/${propertyId}/tenants/${tenantId}/meters/${meterId}`;
// Customizable Area End