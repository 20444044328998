import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import {
  getStorageData,
  removeStorageData,
} from "../../../framework/src/Utilities";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface Meter {
  id: string;
  meterNumber: string;
  type: "Water" | "Electric";
  waterSubType?: "Cold" | "Hot";
  lastRiding: string;
  dateLastRiding: string;
  volume: string;
  metric: string;
}

interface TenantData {
  tenant_name: string;
  meters_attributes: Meter[];
}

interface MeterData {
  meter_number: string;
  meter_type: string;
  meter_note: string;
  water_sub_type: string;
  volume: string;
  metric: string;
  last_reading?: string | number;
  last_reading_avaliable: string;
  date_of_last_reading?: string;
}

interface S {
  token: string;
  tenantId: string;
  meterId: string;
  isEditTenant: boolean;
  isEditMeter: boolean;
  isTenant: boolean;
  isMeter: boolean;
  tenantData: TenantData;
  meterData: MeterData;
  currentProperty: any;
  currentTenant: any;
  currentMeter: any;
  propertyId: string;
  validate: boolean;
  // Customizable Area Start
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class AddTenantPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  apiAddOrUpdateTenantCallId: string = "";
  apiAddOrUpdateMetersCallId: string = "";
  apiUpdateTenantCallId: string = "";
  apiUpdateMetersCallId: string = "";
  apiShowPropertyCallId: string = "";
  apiShowTenantCallId: string = "";
  apiShowMeterCallId: string = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      token: "",
      tenantId: "",
      meterId: "",
      isEditTenant: false,
      isEditMeter: false,
      isTenant: false,
      isMeter: false,
      tenantData: {
        tenant_name: "John Doe",
        meters_attributes: [],
      },
      meterData: {
        meter_number: "M-004",
        meter_type: "Electric",
        water_sub_type: "",
        volume: "",
        metric: "",
        last_reading: 150,
        date_of_last_reading: "2024-09-26",
        last_reading_avaliable: "Yes",
        meter_note: "",
      },
      currentProperty: {},
      currentTenant: {},
      currentMeter: {},
      propertyId: "",
      validate: false,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId != null) {
        if (apiRequestCallId === this.apiAddOrUpdateTenantCallId) {
          const responceBody = responseJson?.tenant || responseJson?.data;

          if (responceBody.id) {
            this.goTenantsPage();
          } else if (responseJson && responseJson.errors) {
            const errorsMsg = responseJson.errors.map(
              (error: any) => Object.values(error)[0]
            );
            if (errorsMsg.includes("Invalid token")) {
              this.goToLogInPage();
            }
          } else {
            //Check Error Response
            this.parseApiErrorResponse(responseJson);
          }
          this.parseApiCatchErrorResponse(errorReponse);
        } else if (apiRequestCallId === this.apiAddOrUpdateMetersCallId) {
          const responceBody = responseJson?.tenant || responseJson?.data;
          if (responceBody.id) {
            this.goMetersPage();
          } else if (responseJson && responseJson.errors) {
            const errorsMsg = responseJson.errors.map(
              (error: any) => Object.values(error)[0]
            );
            if (errorsMsg.includes("Invalid token")) {
              this.goToLogInPage();
            }
          } else {
            //Check Error Response
            this.parseApiErrorResponse(responseJson);
          }
          this.parseApiCatchErrorResponse(errorReponse);
        } else if (apiRequestCallId === this.apiShowPropertyCallId) {
          if (
            responseJson &&
            responseJson.data &&
            responseJson.data.attributes
          ) {
            this.setState({ currentProperty: responseJson.data.attributes });
          } else if (responseJson && responseJson.errors) {
            const errorsMsg = responseJson.errors.map(
              (error: any) => Object.values(error)[0]
            );
            if (errorsMsg.includes("Invalid token")) {
              this.goToLogInPage();
            }
          } else {
            //Check Error Response
            this.parseApiErrorResponse(responseJson);
          }
          this.parseApiCatchErrorResponse(errorReponse);
        } else if (apiRequestCallId === this.apiShowTenantCallId) {
          if (
            responseJson &&
            responseJson.data &&
            responseJson.data.attributes
          ) {
            this.setState({ currentTenant: responseJson.data.attributes });
          } else if (responseJson && responseJson.errors) {
            const errorsMsg = responseJson.errors.map(
              (error: any) => Object.values(error)[0]
            );
            if (errorsMsg.includes("Invalid token")) {
              this.goToLogInPage();
            }
          } else {
            //Check Error Response
            this.parseApiErrorResponse(responseJson);
          }
          this.parseApiCatchErrorResponse(errorReponse);
        } else if (apiRequestCallId === this.apiShowMeterCallId) {
          if (
            responseJson &&
            responseJson.data &&
            responseJson.data.attributes
          ) {
            this.setState({ currentMeter: responseJson.data.attributes });
          } else if (responseJson && responseJson.errors) {
            const errorsMsg = responseJson.errors.map(
              (error: any) => Object.values(error)[0]
            );
            if (errorsMsg.includes("Invalid token")) {
              this.goToLogInPage();
            }
          } else {
            //Check Error Response
            this.parseApiErrorResponse(responseJson);
          }
          this.parseApiCatchErrorResponse(errorReponse);
        }
      }
    }
    // Customizable Area End
  }

  setValidate = (validate: boolean) => {
    this.setState({ validate });
  };

  async componentDidMount(): Promise<void> {
    const userRole = await getStorageData("role");

    if (userRole !== "user") {
      const message: Message = new Message(
        getName(MessageEnum.NavigationMessage)
      );
      message.addData(
        getName(MessageEnum.NavigationTargetMessage),
        "LogInPage"
      );
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(message);

      return;
    }

    await this.getToken();
    if (this.state.propertyId !== "") {
      await this.getPropertyById(this.state.propertyId);
      if (this.state.tenantId !== "") {
        await this.getTenantById(this.state.propertyId, this.state.tenantId);
        if (this.state.meterId !== "") {
          await this.getMeterById(
            this.state.propertyId,
            this.state.tenantId,
            this.state.meterId
          );
        }
      }
    }
  }

  goTenantsPage = () => {
    removeStorageData("tenantId");

    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    message.addData(getName(MessageEnum.NavigationTargetMessage), "TenantPage");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };

  goMetersPage = () => {
    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    message.addData(getName(MessageEnum.NavigationTargetMessage), "MeterPage");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };

  createpdateTenant = (tenantId?: string) => {
    const header = {
      "Content-Type": configJSON.addOrUpdateTenantApiContentType,
      token: this.state.token,
    };

    const httpBody = {
      tenant: this.state.tenantData,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiAddOrUpdateTenantCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addOrUpdateTenantApiEndPoint +
        `/${this.state.propertyId}/tenants/${tenantId || ""}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      tenantId ? "PUT" : "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  createOrUpdateMeter = (meterId?: string) => {
    const header = {
      "Content-Type": configJSON.addOrUpdateMetersApiContentType,
      token: this.state.token,
    };

    const { last_reading, date_of_last_reading, last_reading_avaliable, ...unAvalibleMeterData } = this.state.meterData;
    const meterData = last_reading_avaliable === "Yes" ? {...this.state.meterData, last_reading_available: true} : {...unAvalibleMeterData, last_reading: null, date_of_last_reading: null, last_reading_available: false};

    const httpBody = {
      meter: {
        ...meterData,
        tenant_id: this.state.tenantId,
        property_id: this.state.propertyId,
      },
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiAddOrUpdateMetersCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addOrUpdateMetersApiEndPoint +
        `/${this.state.propertyId}/tenants/${this.state.tenantId}/meters/${
          meterId || ""
        }`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      meterId ? "PUT" : "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  getPropertyById = (propertyId: string) => {
    const header = {
      "Content-Type": configJSON.showPropertyApiContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiShowPropertyCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.showPropertyApiEndPoint + "/" + propertyId
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.showPropertyApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getTenantById = (propertyId: string, tenantId: string) => {
    const header = {
      "Content-Type": configJSON.showTenantApiContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiShowTenantCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.showTenantApiEndPoint + `/${propertyId}/tenants/${tenantId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.showTenantApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getMeterById = (propertyId: string, tenantId: string, meterId: string) => {
    const header = {
      "Content-Type": configJSON.showMeterApiContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiShowMeterCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.showMeterApiEndPoint +
        `/${propertyId}/tenants/${tenantId}/meters/${meterId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.showMeterApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  setTenantData = (valuesData: any) => {
    console.log(valuesData, "valuesData");
    const meterList: any[] = [];

    valuesData.metersAttributes?.map((data: any) => {
      meterList.push({
        meter_number: String(data.meterNumber || "").replace(/\s\s+/g, ' ').trim(),
        meter_type: data.type,
    
        water_sub_type: data.waterSubType || "",
        volume: data.volume || "",
        metric: data.metric || "",
    
        last_reading: String(data.lastReading || "").replace(/\s\s+/g, ' ').trim(),
        date_of_last_reading: String(data.lastReadingDate || "").replace(/\s\s+/g, ' ').trim(),
        meter_note: String(data.meterNote || "").replace(/\s\s+/g, ' ').trim() || "",
      });
    });
    
    this.setState({
      tenantData: {
        tenant_name: String(valuesData.tenantName || "").replace(/\s\s+/g, ' ').trim(),
        meters_attributes: meterList || [],
      },
    });
  };

  setMetersData = (valuesData: any) => {
    this.setState({
      meterData: {
        meter_number: String(valuesData.meterNumber || "").replace(/\s\s+/g, ' ').trim(),
        meter_type: valuesData.type,
      
        water_sub_type: valuesData.waterSubType || "",
        volume: valuesData.volume || "",
        metric: valuesData.metric || "",
      
        last_reading_avaliable: valuesData.lastReadingAvaliable || "Yes",
        last_reading: String(valuesData.lastReading || "").replace(/\s\s+/g, ' ').trim(),
        date_of_last_reading: String(valuesData.lastReadingDate || "").replace(/\s\s+/g, ' ').trim(),
        meter_note: String(valuesData.meterNote || "").replace(/\s\s+/g, ' ').trim() || "",
      },
    });
  };

  goToLogInPage = () => {
    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    message.addData(getName(MessageEnum.NavigationTargetMessage), "LogInPage");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };

  getToken = async () => {
    let token = await getStorageData("token");
    let propertyId = await getStorageData("propertyId");
    let tenantId = await getStorageData("tenantId");
    let meterId = await getStorageData("meterId");
    let isEditTenant = await getStorageData("isEditTenant");
    let isEditMeter = await getStorageData("isEditMeter");

    this.setState({
      token,
      propertyId,
      tenantId,
      meterId,
      isEditTenant: isEditTenant === "true" ? true : false,
      isEditMeter: isEditMeter === "true" ? true : false,
      isTenant: !tenantId || (tenantId && isEditTenant === "true"),
      isMeter: tenantId && isEditTenant === "false",
    });
  };

  // Customizable Area Start
  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }
  // Customizable Area End
}
