import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  services_needed: string;
  message: string;
  validate: boolean;
  sending: boolean;
  sendResultMsg: string | null;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class SupportPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  apiSupportRequestCallId: string = "";
  resetForm: any;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state =  {
      token: "",
      services_needed: "",
      message: "",
      validate: false,
      sending: false,
      sendResultMsg: null
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) {
      return;
    }

    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    
    if (!apiRequestCallId || !responseJson) {
      return;
    }
    if (apiRequestCallId === this.apiSupportRequestCallId && responseJson) {
       this.setState({sending: false, sendResultMsg: responseJson.data ? "Request Sent Successfully" : "Request Sent Failed"});
       this.resetForm();
    }
    // Customizable Area End
  }

  setValidate = (validate: boolean) => {
    this.setState({ validate });
  };

  getToken = async () => {
    let token = await getStorageData("token");
    this.setState({ token: token });
  };

  sendSupportRequestHandler = async (resetForm: any) => {
    console.log("sendSupportRequestHandler", this.state.services_needed, this.state.message);
    const header = {
      "Content-Type": configJSON.supportRequestApiContentType,
      token: this.state.token,
    };

    this.setState({ sending: true });

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiSupportRequestCallId = requestMessage.messageId;
    this.resetForm = resetForm;

    const httpBody = {
      data: {
        services_needed: this.state.services_needed,
        message: this.state.message,
      }
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.supportRequestAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.supportRequestAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  // Customizable Area Start
  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  componentDidMount = async () => {
    const userRole = await getStorageData('role');
    await this.getToken();

    if (userRole !== 'user') {
      const message: Message = new Message(getName(MessageEnum.NavigationMessage));
      message.addData(
        getName(MessageEnum.NavigationTargetMessage),
        'LogInPage'
      );
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(message);
    }
  }
  // Customizable Area End
}
